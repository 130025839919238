import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as ActionsList from './actions';
import { map, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { createEffect } from '@ngrx/effects';
import { ToastrService } from 'ngx-toastr';
import { SwitchInvestmentsService } from 'src/app/services/switch-investments.service';

@Injectable()
export class SwitchInvestmentsEffects {
  constructor(private store: Store<any>,
    private actions$: Actions,
    public toastr: ToastrService,
    private router: Router,
    private service: SwitchInvestmentsService) {
  }

  getOptions$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.RequestOptionsAction),
    switchMap((action: any) => this.service.getOptions(action.accountId)),
    map((data: any) => {
      return ActionsList.ResponseOptionsAction({ payload: data.data });
    })
  ));

  submit$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.RequestSwitchOptionAction),
    switchMap((action: any) => this.service.submit(action.accountId, action.selectedId)),
    map((data: any) => {
      this.toastr.success(data.message);
      return ActionsList.ResponseSwitchOptionAction({ payload: data.data });
    })
  ));

}
