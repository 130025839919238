import { NotLoggedInMasterComponent } from './layout/not-loggedin/not-loggedin.master';
import { FooterComponent } from './layout/footer/footer.master';
import { MainLayoutEffects } from './layout/main/effects';
import { HttpClientModule } from '@angular/common/http';
import { EffectsModule } from '@ngrx/effects';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { SMRTRoutingModule } from './smrt-routing.module';
import { featureKey, reducers } from './store/smrt.reducers';
import { MainMasterComponent } from './layout/main/main.master';
import { MaterialModule } from 'src/app/app.material.module';
import { IfaaUiComponentsModule } from '@ifaa-components/ui-components';
import { MemberPortalSharedModule } from '../shared/shared.module';
import { TenantDashboardComponent } from './views/dashboard/dashboard.component';
import { EmploymentHistoryViewComponent } from './views/employment-history/employment-history-view.component';
import { EmployerHistoryViewEffects } from './views/employment-history/effects';
import { TenantLodgeClaimComponent } from './views/lodge-claim/lodge-claim.component';
import { LodgeClaimEffects } from './views/lodge-claim/effects';

import { CurrentEmployerViewComponent } from './components/current-employer/current-employer/current-employer-view.component';
import { EmployerBalancesComponent } from './components/employer-balances/employer-balances.component';
import { EmployerBalancesEffects } from './components/employer-balances/effects';
import { EmploymentService } from './services/employment.service';
import { CurrentEmployerEffects } from './components/current-employer/current-employer/effects';


@NgModule({
  declarations: [
    MainMasterComponent,
    FooterComponent,
    NotLoggedInMasterComponent,
    TenantDashboardComponent,
    CurrentEmployerViewComponent,
    TenantLodgeClaimComponent,
    EmploymentHistoryViewComponent,
    EmployerBalancesComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    SMRTRoutingModule,
    HttpClientModule,
    MaterialModule,
    IfaaUiComponentsModule,
    StoreModule.forFeature(featureKey, reducers),
    EffectsModule.forFeature([
      MainLayoutEffects,
      CurrentEmployerEffects,
      LodgeClaimEffects,
      EmployerHistoryViewEffects,
      EmployerBalancesEffects
    ]),
    MemberPortalSharedModule,

  ],
  exports: [
    MainMasterComponent,
    NotLoggedInMasterComponent,
    TenantDashboardComponent,
    CurrentEmployerViewComponent,
    EmploymentHistoryViewComponent,
    TenantLodgeClaimComponent,
    EmployerBalancesComponent
  ],
  providers: [
    EmploymentService
  ]
})
export class SMRTModule { }
