<div id="dashboard-container">
  <div class="page-header">
    <app-toolbar-options icon="verified_user">
      <div class="d-flex flex-column" slot="start">
        <div class="page-title">Investments</div>

      </div>
    </app-toolbar-options>
  </div>
  <div class="page-content" appanimatecss>

    <app-component-wrapper  icon="price_change" title="Manage Investments">
      <div class="d-flex flex-column" slot="start">
        <app-investment-edit></app-investment-edit>
      </div>
    </app-component-wrapper>



  </div>
</div>
