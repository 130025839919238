import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as ActionsList from './actions';
import { map, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { createEffect } from '@ngrx/effects';
import { RollInService } from 'src/app/services/rollin.service';

@Injectable()
export class ConsolidateSuperEffects {
  constructor(private store: Store<any>,
    private actions$: Actions,
    private service: RollInService) {
  }

  getData$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.RequestAction),
    switchMap((action: any) => this.service.requestedTransfers(action.accountId)),
    map((data: any) => {
      return ActionsList.ResponseAction({ payload: data.data });
    })
  ));


}
