import { consolidateSuper_List } from './selectors';
import { Component, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { ComponentBase } from 'src/app/views/component-base';
import { AppState } from '../../../../store/app.states';
import { MemberPortalSharedState } from '../../store/shared.states';
import { memberAccountDropdown_SelectedAccount } from '../member-account-dropdown/selectors';
import { RequestAction } from './actions';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-consolidate-super',
  templateUrl: './consolidate-super.component.html',
  styleUrls: ['./consolidate-super.component.scss'],
  providers: []
})
export class ConsolidateSuperComponent extends ComponentBase implements AfterViewInit {

  list$ = this.store.pipe(select(consolidateSuper_List));
  selectedAccount$ = this.store.pipe(select(memberAccountDropdown_SelectedAccount));

  constructor(public store: Store<MemberPortalSharedState>,
    public appStore: Store<AppState>,
    private router: Router) {
    super();

    this.screenWidthChange(appStore, (width: number) => { });
  }

  ngAfterViewInit(): void {
    this.sub = this.selectedAccount$
      .pipe(
        distinctUntilChanged((prev, curr) => prev?.accountId === curr?.accountId))
      .subscribe(x => {
        if (x) {
          this.dispatch(this.store, RequestAction({ accountId: x.accountId }));
        }
      });
  }

  onStartClick() {
    this.router.navigate(['rollin']);
  }
}
