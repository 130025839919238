import { MemberPortalSharedState } from './../../store/shared.states';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { featureKey } from '../../store/shared.reducers';

import { IInvestmentEditState } from './state';

export const investmentPortalState = createFeatureSelector<MemberPortalSharedState>(featureKey);

export const editinvestment = createSelector(investmentPortalState, (state) => state.investmentEditState);

export const editinvestment_Form = createSelector(
  editinvestment,
  (state: IInvestmentEditState) => state.form
);

export const editinvestment_LastModified = createSelector(
  editinvestment,
  (state: IInvestmentEditState) => state.lastModifiedTime
);

export const editinvestment_CurrentStep = createSelector(
  editinvestment,
  (state: IInvestmentEditState) => state.currentStep
);

export const editinvestment_currentInfo = createSelector(
  editinvestment,
  (state: IInvestmentEditState) => state.currentInfo
);

export const editinvestment_CurrentIsSum100Percent = createSelector(
  editinvestment,
  (state: IInvestmentEditState) => state.form.value.current.reduce((a, b) => a + b.percentage, 0) == 100
);

export const editinvestment_CurrentTotalPercent = createSelector(
  editinvestment,
  (state: IInvestmentEditState) => state.form.value.current.reduce((a, b) => a + b.percentage, 0)
);

export const editinvestment_FutureIsSum100Percent = createSelector(
  editinvestment,
  (state: IInvestmentEditState) => state.form.value.future.reduce((a, b) => a + b.percentage, 0) == 100
);

export const editinvestment_FutureTotalPercent = createSelector(
  editinvestment,
  (state: IInvestmentEditState) => state.form.value.future.reduce((a, b) => a + b.percentage, 0)
);

export const editinvestment_IsLastStep = createSelector(
  editinvestment,
  (state: IInvestmentEditState) => {
    var last = state.steps[state.steps.length-1];
    return state.currentStep == last;
  }
);
