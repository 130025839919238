export class InsuranceDetailsModel {
  hasInsurance: boolean = false;
  insuranceCoverUrl: string = '';
  fundDocuments: InsuranceDetailsFundDocumentModel[] = [];
  insurances: InsuranceItem[] = [];
}

export class InsuranceDetailsFundDocumentModel {
  title: string = '';
  icon: string = '';
  url: string = '';
}

export class InsuranceItem {
  insuranceCoverTypeName: string = '';
  waitingPeriod: string = '';
  lapseDate: Date = null;
  premium: number = 0;
  amount: number = 0;
}
