<div id="insurance-pre-questionnaire-container">

  <form [ngrxFormState]="form" *ngIf="form$ | async; let form">
    <h2>Insurance</h2>
    <div class="question d-flex flex-row description">
      <div>
        <div class="title">Interested in insurance?</div>
        <p [innerHtml]="'preQuestionniare_InterestedInInsurance'|message|async">
        </p>
      </div>
    </div>

    <div class="question d-flex flex-row description">
      <div>
        <div class="question title">Do you have a pre-existing condition or disability?</div>
        <p [innerHtml]="'preQuestionnaire_PreExistingConditionOrDisabilityQuestion'|message|async">
        </p>

        <div class="custom-control custom-radio answer gender">
          <app-entry-radio list="yesNo" [control]="form.controls.hasPreExistingConditionOrDisability"></app-entry-radio>
          <p *ngIf="form.controls.hasPreExistingConditionOrDisability.value" class="alert alert-warning" 
          [innerHtml]="'preQuestionnaire_HasPreExistingConditionOrDisability'|message|async">
          </p>
        </div>
      </div>
    </div>

    <div class="question d-flex flex-row description">
      <div>
        <div class="title">Do you regularly engage in Hazardous Pursuits?</div>
        <p [innerHtml]="'preQuestionnaire_HazardousPursuitsQuestion'|message|async">
        </p>

        <div class="custom-control custom-radio answer gender">
          <app-entry-radio list="yesNo" [control]="form.controls.engageInHazardousPursuits"></app-entry-radio>
          <p *ngIf="form.controls.engageInHazardousPursuits?.value" class="alert alert-warning"
          [innerHtml]="'preQuestionnaire_EngagesInHazardousPursuits'|message|async"></p>
        </div>
      </div>
    </div>

    <div class="question d-flex flex-row description">
      <div>
        <p class="title">Are you planning on taking leave without pay from your job?</p>
        <div class="custom-control custom-radio answer gender">
          <app-entry-radio list="yesNo" [control]="form.controls.isPlanningToTakeLeaveWithoutPay"></app-entry-radio>
          <p *ngIf="form.controls.isPlanningToTakeLeaveWithoutPay?.value" class="alert alert-warning"
          [innerHtml]="'preQuestionnaire_IsPlanningToTakeLeaveWithoutPay'|message|async"></p>
        </div>

      </div>
    </div>


    <div class="question d-flex flex-row description">
      <div>
        <p [innerHtml]="'preQuestionnaire_ReadyToProceedWarning'|message|async"></p>
      </div>
    </div>

    <div class="components-actions">
      <app-button class=""  title="GOT IT. I’M READY TO PROCEED." icon="east"
        (onClick)="onStartClick()">
      </app-button>
    </div>
  </form>
</div>