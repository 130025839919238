import { TransactionService } from './../../../../services/transaction.service';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as ActionsList from './actions';
import { map, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { createEffect } from '@ngrx/effects';

@Injectable()
export class CashTransactionsEffects {
  constructor(private store: Store<any>,
    private actions$: Actions,
    private transactionervice: TransactionService) {
  }

  getTransactionsView$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.CashTransactionsRequestAction),
    switchMap((action: any) => this.transactionervice.getMemberTransactions(action.accountId, action.page, action.filter)),
    map((data: any) => {
      return ActionsList.CashTransactionsResponseAction({ payload: data.data });
    })
  ));

  getTransactionTypesView$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.CashTransactionsTypesRequestAction),
    switchMap((action: any) => this.transactionervice.getMemberTransactionTypes(action.accountId)),
    map((data: any) => {
      return ActionsList.CashTransactionsTypesResponseAction({ payload: data.data });
    })
  ));

  getTransactionDescriptionView$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.CashTransactionsDescriptionRequestAction),
    switchMap((action: any) => this.transactionervice.getMemberTransactionDescription(action.accountId)),
    map((data: any) => {
      return ActionsList.CashTransactionsDescriptionResponseAction({ payload: data.data });
    })
  ));

}
