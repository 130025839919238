import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as ActionsList from './actions';
import { map, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { createEffect } from '@ngrx/effects';
import { DocumentsService } from 'src/app/services/documents.service';

@Injectable()
export class DocumentViewerEffects {
  constructor(private store: Store<any>,
    private actions$: Actions,
    private service: DocumentsService) {
  }

  getMemberCorrespondenceView$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.RequestAction),
    switchMap((action: any) => this.service.getDocuments(action.accountId, action.category)),
    map((data: any) => {
      return ActionsList.ResponseAction({ payload: data.data });
    })
  ));

  // getMemberCorrespondenceFileView$ = createEffect(() => this.actions$.pipe(
  //   ofType(ActionsList.FileRequestAction),
  //   switchMap((action: any) => this.service.getDocument(action.accountId, action.correspondenceSource, action.correspondenceId)),
  //   map((data: any) => {
  //     return ActionsList.FileResponseAction({ payload: data.data });
  //   })
  // ));

}
