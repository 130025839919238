import { historicalChartView_Model, historicalChartView_SelectedFilter } from './selectors';
import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { RequestAction, SelectFilterAction } from './actions';
import { ComponentBase } from '../../../../views/component-base';
import { AppState } from '../../../../store/app.states';
import { MemberPortalSharedState } from '../../store/shared.states';
import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexDataLabels,
  ApexTitleSubtitle,
  ApexStroke,
  ApexGrid,
  ApexTooltip,
  ApexFill,
  ApexYAxis,
  ApexMarkers
} from "ng-apexcharts";
import { DatePipe } from '@angular/common';
import { memberAccountDropdown_SelectedAccount } from '../member-account-dropdown/selectors';
import { map } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { HistoricalGraphItemModel } from 'src/app/model/historical-graph.model';
import { stringify } from '@angular/compiler/src/util';
import { TranslateService } from '@ngx-translate/core';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  dataLabels: ApexDataLabels;
  grid: ApexGrid;
  stroke: ApexStroke;
  title: ApexTitleSubtitle;
  tooltip: ApexTooltip;
  //   fill: ApexFill;
  //markers: ApexMarkers;
};

@Component({
  selector: 'app-historical-chart-view',
  templateUrl: './historical-chart-view.component.html',
  styleUrls: ['./historical-chart-view.component.scss']
})
export class HistoricalChartViewComponent extends ComponentBase implements OnInit, OnDestroy, AfterViewInit {
  pipe = new DatePipe('en-AU');
  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  model$ = this.store.pipe(select(historicalChartView_Model));
  selectedFilter$ = this.store.pipe(select(historicalChartView_SelectedFilter));
  selectedAccount$ = this.store.pipe(select(memberAccountDropdown_SelectedAccount));
  data: HistoricalGraphItemModel[] = [];
  customButtons = [];
  public updateOptionsData = {
    "1m": {
      xaxis: {
        min: this.subtractDate(1).getTime(),
        max: new Date().getTime()
      }
    },
    "6m": {
      xaxis: {
        min: this.subtractDate(6).getTime(),
        max: new Date().getTime()
      }
    },
    "1y": {
      xaxis: {
        min: this.subtractDate(12).getTime(),
        max: new Date().getTime()
      }
    },

    all: {
      xaxis: {
        min: undefined,
        max: undefined
      }
    }
  };


  constructor(public store: Store<MemberPortalSharedState>,
    public translate: TranslateService
  ) {
    super();
    this.translate.get('HISTORICAL_CHART_VIEW.DISPLAY_UNITS')
      .subscribe(displayUnits => {
        this.chartOptions = {
          tooltip: {
            enabled: true,
            custom: ({ series, seriesIndex, dataPointIndex, w }) => {
              return '<div class="arrow_box p-3" style="min-width: 150px">' +
                '<div class="d-block"> <div class="d-flex flex-row"><div style="width: 60px">Date:</div> ' + this.pipe.transform(this.data[dataPointIndex].x, 'dd/MM/yyyy') + '</div></div>' +
                '<div class="d-block"> <div class="d-flex flex-row"><div style="width: 60px">Amount:</div> $' + series[seriesIndex][dataPointIndex] + '</div></div>' +
                `<div class="${displayUnits ? 'd-block' : 'd-none'}"> <div class="d-flex flex-row"><div style="width: 60px">Units:</div> ` + this.data[dataPointIndex].unit + '</div></div>' +
                '</div>'
            }
          },
          series: [],

          chart: {
            height: 350,
            type: "area",
            id: 'historicalchart',
            selection: {

            },
            zoom: {
              enabled: false
            },

            events: {
              click: function (e, chart, options) {
                var tt = '';
              },
              dataPointSelection: function (e, chart, options) {
                console.log(options.dataPointIndex);
              }
            },
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            curve: "straight"
          },
          title: {
            text: "",
            align: "left"
          },
          grid: {
            row: {
              colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
              opacity: 0.1
            }
          },
          yaxis: {
            labels: {
              formatter: function (value) {
                return "$" + value;
              }
            },
          },
          xaxis: {
            type: 'datetime',
            tooltip: {
              formatter: (value) => {
                return this.pipe.transform(new Date(value).toISOString(), 'dd/MM');
              }
            }
          },
        }
      });

  }
  ngAfterViewInit() {

  }
  subtractDate(months: number): Date {
    var d = new Date();
    d.setMonth(d.getMonth() - months);
    return d;
  }
  lastSearch = {
    accountId: 0,
    from: null,
    to: null
  }
  ngOnInit() {
    super.ngOnInitBase();
    this.getCustomButtons()
    this.sub = combineLatest([this.selectedAccount$, this.selectedFilter$])
      .pipe(
        map(([account, filter]) => ({ account, filter })),
      ).subscribe(x => {
        if (x.account && x.filter) {
          if (this.chart)
            this.chart.updateOptions(this.updateOptionsData[x.filter], false, true, true);

          var nextSearch = {
            accountId: x.account.accountId,
            from: this.updateOptionsData[x.filter].xaxis.min,
            to: this.updateOptionsData[x.filter].xaxis.max
          };
          if (JSON.stringify(this.lastSearch) != JSON.stringify(nextSearch)) {
            this.lastSearch = nextSearch;
            this.dispatch(this.store, RequestAction(nextSearch));
          }
        }
      });

    this.sub = this.model$.subscribe(model => {
      if (model.data.length > 0) {
        this.data = model.data;
        if (this.chart)
          this.chart.updateSeries([{
            data: model.data
          }])
      }
    })
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

  getCustomButtons() {
    this.translate.get('HISTORICAL_CHART_VIEW.BUTTONS')
      .subscribe((list: any[]) => {
        if (list instanceof Array) {
          this.customButtons = list;
        }
      });
  }
  public updateOptions(option: any): void {
    this.store.dispatch(SelectFilterAction({ payload: option }))
  }
}

