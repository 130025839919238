import { contributionsLimit_Data } from './selectors';
import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { RequestAction } from './actions';
import { ComponentBase } from '../../../../views/component-base';
import { MemberPortalSharedState } from '../../store/shared.states';
import { memberAccountDropdown_SelectedAccount } from '../member-account-dropdown/selectors';

@Component({
  selector: 'app-contributions-limits',
  templateUrl: './contributions-limits.component.html',
  styleUrls: ['./contributions-limits.component.scss']
})
export class ContributionsLimitsComponent extends ComponentBase implements OnInit, OnDestroy {

  selectedAccount$ = this.store.pipe(select(memberAccountDropdown_SelectedAccount));
  data$ = this.store.pipe(select(contributionsLimit_Data));
  datatype: "gauge" | "table" = "gauge";
  constructor(public store: Store<MemberPortalSharedState>) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();
    this.sub = this.selectedAccount$.subscribe(x => {
      if (x) {
        this.dispatch(this.store, RequestAction({ accountId: x.accountId }));
      }
    });
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

  calculatePercentage(amount: number, cap: number) {
    if (amount == 0)
      return 180;

    if (amount > 0)
      var tt = '';
    var value = 180 - ((amount * 180) / cap);
    if (value < 0 || isNaN(value))
      return 0;
    // if (value > 180)
    //   return 0;
    return value;
  }


}
