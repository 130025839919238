import { MembersService } from 'src/app/services/members.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ActionsList from './actions';
import { map, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';

@Injectable()
export class MemberViewOverviewEffects {
  constructor(private store: Store<any>,
    private actions$: Actions,
    private router: Router,
    private service: MembersService) {
  }

  getData$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.RequestAction),
    switchMap((action: any) => this.service.getView()),
    map((data: any) => {
      return ActionsList.ResponseAction({ payload: data.data });
    })
  ));

  // isLoggedIn$ = createEffect(() => this.actions$.pipe(
  //   ofType(ActionsList.IsLoggedInAction),
  //   switchMap((action: any) => this.service.isLoggedIn()),
  //   map((data: any) => {
  //     return ActionsList.LoggedInAction({ payload: '' });
  //   })
  // ));
}
