import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../store/app.states';
import { ComponentBase } from '../component-base';
import { OnDestroy } from '@angular/core';

@Component({
  selector: 'app-personal-edit',
  templateUrl: './personal-edit.component.html',
  styleUrls: ['./personal-edit.component.scss'],
  host: {
    class: 'w-100'
  }
})
export class PersonalEditComponent extends ComponentBase implements OnInit, OnDestroy {


  constructor(public store: Store<AppState>) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

}
