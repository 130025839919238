
import { IInsuranceQuestionnaireState } from './state';
import { createReducer, on, State, Action } from '@ngrx/store';
import {  onNgrxForms, onNgrxFormsAction, SetValueAction, createFormGroupState, updateGroup, validate, wrapReducerWithFormStateUpdate, setValue} from 'ngrx-forms';
import { required, email, requiredTrue } from 'ngrx-forms/validation';
import { ShowQuestionnaireResponseAction } from './actions';
import { InsuranceRequestModel } from 'src/app/model/insurance-questionnaire.model';
import { Helper } from '@ifaa-components/ui-components';

export const formName = 'insuranceRequestForm';

export const validateInsuranceRequestForm = updateGroup<InsuranceRequestModel>({
  confirmReadInsuranceRequestInsuranceGuide: validate(requiredTrue),
});

export const state: IInsuranceQuestionnaireState = {
  form: createFormGroupState(formName, new InsuranceRequestModel()),
  ageNextBirthday:0,
  showQuestionnaire: true,
  elegibilityDate: null
};

var helper = new Helper();

const reducer = createReducer(state,
  onNgrxForms(),
  onNgrxFormsAction(SetValueAction, (state, action) => {
    return state;
  }),
  on(ShowQuestionnaireResponseAction, (state, { payload }) => {
    return {
      ...state,
      form: createFormGroupState(formName, new InsuranceRequestModel()),
      showQuestionnaire: payload.show,
      elegibilityDate: payload.offerExpiryDate,
      ageNextBirthday: payload.ageNextBirthday
    };
  })
);

export function insuranceQuestionnaireReducer(state: any | undefined, action: Action) {
  return editInsuranceRequestReducerFormState(state, action);
}

const editInsuranceRequestReducerFormState = wrapReducerWithFormStateUpdate(
  reducer,
  s => s.form,
  validateInsuranceRequestForm,
);

