import { VervModule } from './modules/verv/verve.module';
import { environment } from './../environments/environment';
import { Type } from '@angular/core';
import { TenantDashboardComponent as VerveTenantDashboardComponent } from './modules/verv/views/dashboard/dashboard.component'
import { TenantDashboardComponent as CIRTTenantDashboardComponent } from './modules/cirt/views/dashboard/dashboard.component'
import { TenantDashboardComponent as SMRTTenantDashboardComponent } from './modules/smrt/views/dashboard/dashboard.component'
import { MainMasterComponent as VervMainMasterComponent } from './modules/verv/layout/main/main.master'
import { MainMasterComponent as CIRTMainMasterComponent } from './modules/cirt/layout/main/main.master'
import { MainMasterComponent as SMRTMainMasterComponent } from './modules/smrt/layout/main/main.master'
import { NotLoggedInMasterComponent as VervNotLoggedInMasterComponent } from './modules/verv/layout/not-loggedin/not-loggedin.master';
import { NotLoggedInMasterComponent as CIRTNotLoggedInMasterComponent } from './modules/cirt/layout/not-loggedin/not-loggedin.master';
import { NotLoggedInMasterComponent as SMRTNotLoggedInMasterComponent } from './modules/smrt/layout/not-loggedin/not-loggedin.master';
import { CIRTModule } from './modules/cirt/cirt.module';
import { SMRTModule } from './modules/smrt/smrt.module';

export class GetTenantModule {
  static getModule(list: any[]) {

    if (environment.code == 'verv')
      list.push(VervModule);

    if (environment.code == 'cirt')
      list.push(CIRTModule);

    if (environment.code == 'smrt')
      list.push(SMRTModule);
  }
}


export class GetTenantNotLoggedInLayout {
  static layout(): Type<any> {

    if (environment.code == 'verv')
      return VervNotLoggedInMasterComponent;

    if (environment.code == 'cirt')
      return CIRTNotLoggedInMasterComponent;

    if (environment.code == 'smrt')
      return SMRTNotLoggedInMasterComponent;

    return null;
  }

}

export class GetTenantDashboard {
  static layout(): Type<any> {

    if (environment.code == 'verv')
      return VerveTenantDashboardComponent;

    if (environment.code == 'cirt')
      return CIRTTenantDashboardComponent;

    if (environment.code == 'smrt')
      return SMRTTenantDashboardComponent;

    return null;
  }

}

export class GetTenantMainLayout {
  static mainLayout(): Type<any> {

    if (environment.code == 'verv')
      return VervMainMasterComponent;

    if (environment.code == 'cirt')
      return CIRTMainMasterComponent;

    if (environment.code == 'smrt')
      return SMRTMainMasterComponent;

    return null;
  }

}

export function GetMainLayout(): Type<any> {

  if (environment.code == 'verv')
    return VervMainMasterComponent;

  if (environment.code == 'cirt')
    return CIRTMainMasterComponent;

  if (environment.code == 'smrt')
    return SMRTMainMasterComponent;

  return null;

  // switch (environment.code) {
  //   case 'verve':
  //     return VerveMainMasterComponent
  //   case 'verve1':
  //     return Verve1MainMasterComponent
  // }
}

// function MyComponentFactory(route: ActivatedRoute): Type<any> {

//       return  GetMainLayout();

// }
