<div id="dashboard-container">
  <div class="page-header">
    <app-toolbar-options icon="house">
      <div class="d-flex flex-column" slot="start">
        <div class="page-title">Overview</div>
        <!-- <div class="page-sub-title ">Please use the website to review your personal information and feel free to contact
          us at any time to discuss any issues you may have.</div> -->
      </div>
    </app-toolbar-options>
  </div>
  <div class="page-content" appanimatecss>


    <div class="d-flex align-items-stretch widgets-row" style="margin-bottom: 60px;">

      <app-member-view-overview class=" widget-1 w-50" [ngClass]="{'mr-3': screenWidth>992}"></app-member-view-overview>
      <app-consolidate-super class="  w-50"></app-consolidate-super>

    </div>

    <app-component-wrapper class="historical-graph-component" icon="" title="">
      <div class="d-flex flex-column" slot="start">
        <app-historical-chart-view></app-historical-chart-view>
      </div>
    </app-component-wrapper>


    <div class="w-100 academy mt-4">
      <a target="_blank" href="https://vervesuper.com.au/learn-members/">
        <img class="w-100" src="https://vervassets.blob.core.windows.net/web/dashboard-learn3.png">
      </a>
    </div>
    <!--
    <app-component-wrapper icon="analytics" title="Super">
      <div class="d-flex flex-column" slot="start">
        <app-account-underlying-view></app-account-underlying-view>
      </div>
    </app-component-wrapper> -->

    <!--
    <app-component-wrapper icon="supervisor_account" title="Beneficiaries">
      <div class="d-flex flex-column" slot="start">
        <app-beneficiaries-view></app-beneficiaries-view>
      </div>
    </app-component-wrapper> -->


  </div>
</div>
