<div id="dashboard-container">
  <div class="page-header">
    <app-toolbar-options icon="local_atm">
      <div class="d-flex flex-column" slot="start">
        <div class="page-title">Contributions</div>
      </div>
    </app-toolbar-options>
  </div>
  <div class="page-content" appanimatecss>
    <app-component-wrapper fragmentid="bpay"    icon="receipt_long" title="Make a Contribution">
      <div class="d-flex flex-column" slot="start">
        <app-bpay-details></app-bpay-details>
      </div>
    </app-component-wrapper>
  </div>
</div>
