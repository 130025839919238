<div id="personal-details-container">
  <div class="page-header">
    <app-toolbar-options icon="add_task">
      <div class="d-flex flex-column" slot="start">
        <div class="page-title">Questionnaire</div>

      </div>
    </app-toolbar-options>
  </div>
  <div class="page-content" appanimatecss>
    <div class="d-flex">

      <div class="w-100">
        <app-component-wrapper>
          <div class="d-flex flex-column" slot="start">

            <app-insurance-questionnaire-start></app-insurance-questionnaire-start>
          </div>

        </app-component-wrapper>
      </div>
    </div>

  </div>
</div>
