<app-component-wrapper class="w-50 flex-fill" compclass="flex-fill h-100" id="consolidate-your-syper-container">
  <div class="d-flex flex-column flex-fill" slot="start">
    <div id="consolidate-super-container">
      <div class="d-flex flex-column">
        <div class="header">
          <h3>Consolidate your super</h3>
          <label class="description">Do you have lost super? Do you have multiple super accounts?</label>
        </div>
        <div class="alert alert-info mt-3" *ngIf="(list$ | async).length == 0">
          You have not requested a rollover in the last 30 days
        </div>

        <div id="carouselExampleIndicators" class="carousel slide mt-3" data-ride="carousel"
          *ngIf="list$ | async; let list">
          <ol class="carousel-indicators" *ngIf="list.length > 1">
            <li data-target="#carouselExampleIndicators" *ngFor="let item of list; index as i"
              [ngClass]="{'active': i == 0}" [attr.data-slide-to]="i"></li>
          </ol>
          <div class="carousel-inner">
            <div *ngFor="let item of list; index as i" class="carousel-item transfers" [ngClass]="{'active': i == 0}">
              <div class="alert  d-flex  flex-row align-items-center ml-3 mr-3"
              [ngClass]="{'alert-warning': item.status == 'Pending', 'alert-info': item.status == 'Processed','alert-primary': item.status == 'Requested'}">
                <div class="d-flex flex-column m-2 align-items-center icon">
                  <mat-icon class="" *ngIf="item.status == 'Pending'">warning</mat-icon>
                  <mat-icon class="" *ngIf="item.status == 'Processed'">done</mat-icon>
                  {{item.status}}
                </div>
                <div>
                  <h6>{{item.transferringFund}}</h6>
                  Account number: {{item.accountNumber}}
                </div>
              </div>
            </div>


          </div>
          <a class="carousel-control-prev no-text-decoration" href="#carouselExampleIndicators" role="button"
            data-slide="prev" *ngIf="list.length > 1">
            <mat-icon>keyboard_arrow_left</mat-icon>
          </a>
          <a class="carousel-control-next no-text-decoration" href="#carouselExampleIndicators" role="button"
            data-slide="next" *ngIf="list.length > 1">
            <mat-icon>keyboard_arrow_right</mat-icon>
          </a>
        </div>


        <!-- <div class="transfers d-flex fles-row flex-fill mt-3">
          <div *ngFor="let item of list$ | async" class="alert alert-warning d-flex  flex-row align-items-center">
            <div class="d-flex flex-column m-2 align-items-center icon">
              <mat-icon class="">warning</mat-icon>
              {{item.status}}
            </div>
            <div>
              <h6>{{item.transferringFund}}</h6>
              Account number: {{item.accountNumber}}
            </div>

          </div>
        </div> -->
      </div>
    </div>
  </div>


  <div class="d-flex flex-row components-actions" slot="actions">
    <app-button icon="{{'BTN.consolidate-super.start.icon' | translate}}"  title="Start" (onClick)="onStartClick()">
    </app-button>
  </div>
</app-component-wrapper>
