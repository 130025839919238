import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuardService } from "src/app/route-guard/auth-guard.service";
import { MainMasterComponent } from "./layout/main/main.master";
import { EmploymentHistoryViewComponent } from "./views/employment-history/employment-history-view.component";
import { TenantJetcoApprovedTrainingComponent } from "./views/jetco/approved-training/approved-training.component";
import { TenantJetcoComponent } from "./views/jetco/jetco/jetco.component";
import { TenantJetcoLodgeClaimComponent } from "./views/jetco/lodge-claim/lodge-claim.component";
import { TenantLodgeClaimComponent } from "./views/lodge-claim/lodge-claim.component";

const cirtRoutes: Routes = [
  {
    path: 'jetco',
    component: MainMasterComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        outlet: 'master',
        path: '',
        component: TenantJetcoComponent
      }
    ]
  },
  {
    path: 'approved-training',
    component: MainMasterComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        outlet: 'master',
        path: '',
        component: TenantJetcoApprovedTrainingComponent
      }
    ]
  },
  {
    path: 'jetco-lodge-claim',
    component: MainMasterComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        outlet: 'master',
        path: '',
        component: TenantJetcoLodgeClaimComponent
      }
    ]
  },
  {
    path: 'lodge-claim',
    component: MainMasterComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        outlet: 'master',
        path: '',
        component: TenantLodgeClaimComponent
      }
    ]
  },
  {
    path: 'employment-history',
    component: MainMasterComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        outlet: 'master',
        path: '',
        component: EmploymentHistoryViewComponent
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(cirtRoutes)],
  exports: [RouterModule]
})
export class CIRTRoutingModule { }
