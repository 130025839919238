export class HistoricalGraphModel {
  totalAmount: number = 0;
  asAt: Date = null;
  data: HistoricalGraphItemModel[] = [];
}


export class HistoricalGraphItemModel {
  x: Date = null;
  y: number = null;
  unit: number = null;
}
