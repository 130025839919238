<div id="investment-view-container" *ngIf="form$ | async; let form">
  <div *ngIf="(currentStep$ | async) == 0" appanimatecss>
    <h2 class="title">What would you like to change?</h2>
    <div class="row mt-2">
      <div class="col-lg">
        <div *ngIf="currentInfo$ | async; let currentInfo" class="option1 card flex-row d-flex align-items-center mb-2"
          [ngClass]="{'disabled': currentInfo.hasPendingCurrentOptions}"
          (click)="onEditItemClick(1, [0,1], currentInfo.hasPendingCurrentOptions)">
          <div class="ic-container d-flex align-items-center">
            <mat-icon class="ic mb-4">currency_exchange</mat-icon>
          </div>
          <div class="flex-fill description">Where my balance is currently invested</div>

          <mat-icon class="arrow">keyboard_arrow_right</mat-icon>

        </div>
      </div>
      <div class="col-lg">
        <div class="option1 flex-row d-flex align-items-center card  mb-2" (click)="onEditItemClick(2, [0,2], false)">
          <div class="ic-container d-flex align-items-center">
            <mat-icon class="ic mb-4">monetization_on</mat-icon>
          </div>
          <div class="flex-fill description">Where my Future Contributions are invested</div>

          <mat-icon class="arrow">keyboard_arrow_right</mat-icon>

        </div>
      </div>
      <div class="col-lg">
        <div *ngIf="currentInfo$ | async; let currentInfo" class="option1 card flex-row d-flex align-items-center mb-2"
          [ngClass]="{'disabled': currentInfo.hasPendingCurrentOptions}"
          (click)="onEditItemClick(1, [0,1,2], currentInfo.hasPendingCurrentOptions)">
          <div class="ic-container d-flex align-items-center">
            <mat-icon class="ic mb-4">display_settings</mat-icon>
          </div>
          <div class="flex-fill description">Both my current and Future</div>

          <mat-icon class="arrow">keyboard_arrow_right</mat-icon>

        </div>
      </div>
    </div>


    <h2 class="">Current Options</h2>

    <div class="row">
      <div class="col col-lg-6">
        <h4>Your Balance Is Currently Invested in
          <span *ngIf="(currentInfo$ | async)?.hasPendingCurrentOptions"
            class=" badge badge-info float-right d-flex align-items-center">
            <mat-icon class="animate__delay-1s animate__animated animate__tada">hourglass_top</mat-icon>
            Pending Changes
          </span>
        </h4>
        <table class="table mr-2">
          <thead>
            <tr>
              <th scope="col">Option</th>
              <th scope="col">%</th>
              <th scope="col">$</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of (currentInfo$|async)?.current">
              <td>{{item.option}}</td>
              <td>{{item.percentage}}%</td>
              <td>{{item.amount | currency}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col col-lg-6">
        <h4>Any Future contributions or Rollovers Received Into the fund will be invested in</h4>
        <table class="table ml-2">
          <thead>
            <tr>
              <th scope="col">Option</th>
              <th scope="col">%</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of (currentInfo$|async)?.future">
              <td>{{item.option}}</td>
              <td>{{item.percentage}}%</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div *ngIf="(currentStep$ | async) == 1" appanimatecss>
    <h2 class="mb-3">Where would you like to switch your balance to?</h2>
    <p>
      Please add the percentage of your balance you would like to be invested in each option. The percentage can be up
      to
      Two decimal places and must total 100%.
    </p>
    <p>
      Once submitted you will not be able to change this request . You will not be able to submit an additional request
      until your request has been processed.
    </p>
    <p>Investment Switches will be processed when the price for the requested date has been loaded.
    </p>
    <table class="table mr-2">
      <thead>
        <tr>
          <th scope="col">Option</th>
          <th scope="col">Current (%)</th>
          <th scope="col">Current ($)</th>
          <th scope="col">%</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of form?.controls.current.controls; let i = index; trackBy: trackByFn">
          <td>{{item.value.option}}</td>
          <td>{{getCurrentPercentage(item.value.option, (currentInfo$|async)?.current)}}%</td>
          <td>{{getCurrentAmount(item.value.option, (currentInfo$|async)?.current) | currency}}</td>
          <td>
            <app-entry-number title="" [control]="item.controls.percentage" [decimalplaces]="2">
            </app-entry-number>
          </td>
        </tr>
        <tr>
          <th></th>
          <th></th>
          <th></th>
          <th>
            <div *ngIf="form.value.current.length > 0" class="alert "
              [ngClass]="{'alert-success': currentIsSum100Percent$ | async, 'alert-danger': !(currentIsSum100Percent$|async)}">
              Total: {{currentTotalPercent$ | async}}% of 100%
            </div>
          </th>
        </tr>
      </tbody>
    </table>
    <div class="d-flex flex-row">
      <div class="w-50"></div>
      <div class="w-50 d-flex flex-row-reverse">

        <app-button class=" m-1 " title="Next" icon="east" (onClick)="onEditItemClick(2,[])"
          [isDisabled]="!(currentIsSum100Percent$ | async)" *ngIf="!(isLastStep$|async)">
        </app-button>
        <app-button class=" m-1 " title="Submit" icon="east" (onClick)="onSubmitClick(form.value)"
          [isDisabled]="!(currentIsSum100Percent$ | async)" *ngIf="isLastStep$|async">
        </app-button>
        <app-button class=" m-1 " title="Back" icon="west" (onClick)="onEditItemClick(0, [])">
        </app-button>
      </div>
    </div>

  </div>

  <div *ngIf="(currentStep$ | async) == 2" appanimatecss>
    <h2 class="mb-3">Where would you like your Future Contributions Invested?
    </h2>
    <p>
      Please add the percentage of your balance you would like to be invested in each option. The percentage can be up
      to
      Two decimal places and must total 100%.
    </p>
    <p>
      This update will be immediate
    </p>

    <table class="table mr-2">
      <thead>
        <tr>
          <th scope="col">Option</th>
          <th scope="col">Current (%)</th>
          <th scope="col">%</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of form?.controls.future.controls; let i = index; trackBy: trackByFn">
          <td>{{item.value.option}}</td>
          <td>{{getCurrentPercentage(item.value.option, (currentInfo$|async)?.future)}}%</td>
          <td>
            <app-entry-number title="" [control]="item.controls.percentage" [decimalplaces]="2">
            </app-entry-number>
          </td>
        </tr>
        <tr>
          <th></th>
          <th></th>
          <th>
            <div *ngIf="form.value.future.length > 0" class="alert "
              [ngClass]="{'alert-success': futureIsSum100Percent$ | async, 'alert-danger': !(futureIsSum100Percent$|async)}">
              Total: {{futureTotalPercent$ | async}}% of 100%
            </div>
          </th>
        </tr>
      </tbody>
    </table>
    <div class="d-flex flex-row">
      <div class="w-50"></div>
      <div class="w-50 d-flex flex-row-reverse">

        <app-button class=" m-1 " title="Next" icon="east" (onClick)="onEditItemClick(0,[])"
          [isDisabled]="!(futureIsSum100Percent$ | async)" *ngIf="!(isLastStep$|async)">
        </app-button>
        <app-button class=" m-1 " title="Submit" icon="east" (onClick)="onSubmitClick(form.value)"
          [isDisabled]="!(futureIsSum100Percent$ | async)" *ngIf="isLastStep$|async">
        </app-button>
        <app-button class=" m-1 " title="Back" icon="west" (onClick)="onEditItemClick(0, [])">
        </app-button>
      </div>
    </div>

  </div>

</div>
<ng-template #myTemplate>
  <div class="p-4">
    <h3 class="mb-3">We have received your request
    </h3>
    <p [innerHTML]="submitText">
    </p>

    <app-button loading class="float-right  mb-4 mt-4" title="Return to Dashboard" icon="keyboard_return" type="submit"
      (onClick)="onGoToDashboardClick()">
    </app-button>
  </div>
</ng-template>
