<div id="member-view-container">

  <form [ngrxFormState]="form" (ngSubmit)="onSubmitClick(form.value)" *ngIf="form$ | async; let form">
    <div class="d-flex flex-row" featuretoggle="isMemberCommunicationPreferenceEditable">
      <div>
        <h5 class="title">Communication Preferences</h5>
        <div>
          <app-entry-radio title="Communication Preferences" list="communicationPreferenceList" [control]="form.controls.communicationPreference" direction="row"></app-entry-radio>
        </div>
      </div>
    </div>
    
    <app-entry title="Email" [control]="form.controls.email" icon="email" featuretoggle="isMemberEmailEditable">
    </app-entry>

    <app-entry-date title="{{ 'DATE_OF_BIRTH' | translate }}" [date]="form.controls.dateOfBirth"
      featuretoggle="isMemberDOBEditable">
    </app-entry-date>

    <app-entry title="{{ 'MOBILE' | translate }}" [control]="form.controls.mobile" icon="phone">
    </app-entry>

    <app-entry title="Home Phone" [control]="form.controls.homePhone" icon="phone">
    </app-entry>

    <div featuretoggle="isMemberTFNEditable">
      <app-entry title="Tax File Number" [control]="form.controls.taxFileNumber" icon="pin" *ngIf="!form.value.hasTFN">
      </app-entry>
    </div>

    <app-entry title="Occupation" [control]="form.controls.occupation" icon="work"
      featuretoggle="displayMemberOccupation">
    </app-entry>

    <app-entry-address-accurity  title="{{ 'POSTAL_ADDRESS' | translate }}" [address]="form.controls.postalAddress"
      [isSearchMode]="false">
    </app-entry-address-accurity>

    <entry-slide-toggle title="Residential same as postal address?" [control]="form.controls.isPostalSameAsResidential"
      iconTrue="" iconFalse="">
    </entry-slide-toggle>

    <app-entry-address-accurity title="{{ 'RESIDENTIAL_ADDRESS' | translate }}"
      [address]="form.controls.residentialAddress" [isSearchMode]="false" *ngIf="!form.value.isPostalSameAsResidential">
    </app-entry-address-accurity>

    <div featuretoggle="isMemberTFNEditable">
      <div *ngIf="!form.value.hasTFN && form.value.taxFileNumber">
        <p>
          {{(tenantConfig$|async).name}} is authorised to ask for your tax file number (TFN) in line with the
          Superannuation Industry (Supervision) Act 1993. {{(tenantConfig$|async).name}} will only use your TFN for
          lawful
          purposes. These purposes may change in the future if there are changes to the legislation.
        </p>
        <p>
          {{(tenantConfig$|async).name}} may pass your TFN to any other super fund or account to which your super is
          transferred in the future unless you request in writing that this not be done.
        </p>
        <p>
          By providing your TFN :
        </p>
        <ul>
          <li>
            {{(tenantConfig$|async).name}} will use the ATO Member Account Attribute service (MAAS) to verify your TFN,
            name and date of birth.
          </li>
          <li>
            {{(tenantConfig$|async).name}} will be able to accept all types of contributions made by or for you (some
            limits may apply).
          </li>
          <li>
            You can avoid paying tax at a higher rate than would otherwise apply on your super benefit.
          </li>
          <li>
            It will be easier for you to find your super in the future and ensure that you receive all of your super
            benefits when you retire.
          </li>
        </ul>

        <app-checkbox [control]="form.controls.tfnTermsAndCondition" class="w-100  mr-1 ml-1"
          title="Yes, I acknowledge the above">
        </app-checkbox>
      </div>
    </div>

    <div class="components-actions">
      <app-button loading class=" mr-1 ml-1" title="Save details" icon="{{'BTN.member-edit.save.icon' | translate}}"
        type="submit" [isDisabled]="form.isInvalid" (onClick)="onSubmitClick(form.value)">
      </app-button>
    </div>
  </form>
</div>
