import { NotificationService } from './../../../../services/notification.service';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as ActionsList from './actions';
import { map, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { createEffect } from '@ngrx/effects';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/services/accounts.service';

@Injectable()
export class NotificationDropdownEffects {
  constructor(private store: Store<any>,
    private actions$: Actions,
    public toastr: ToastrService,
    private router: Router,
    private service: NotificationService) {
  }

  getNotifications$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.RequestAction),
    switchMap((action: any) => this.service.getNewNotification(action.accountId)),
    map((data: any) => {
      return ActionsList.ResponseAction({ payload: data.data });
    })
  ));

}
