import { environment } from './../../../../../environments/environment';
import { Helper } from '@ifaa-components/ui-components';
import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { AddMessageAction, ChatLoadedAction, RequestAction, RequestChatClosedAction, RequestDeleteChatAction, RequestNewChatAction, ToggleChatAction } from './actions';
import { ComponentBase } from '../../../../views/component-base';
import { MemberPortalSharedState } from '../../store/shared.states';
import { memberAccountDropdown_SelectedAccount } from '../member-account-dropdown/selectors';
import { getChat_ChatLoaded, getChat_Form, getChat_IsChatOpened, getChat_LastUpdated, getChat_Model } from './selectors';
import { createFormControlState, FormControlState } from 'ngrx-forms';
import { ConversationHistoryModel, TwilioChatModel } from './state';
import { commomState_LoggedinInfo } from 'src/app/store/common/common.selectors';
import { AppState } from 'src/app/store/app.states';
declare var Twilio;

@Component({
  selector: 'app-chat-view',
  templateUrl: './chat-view.component.html',
  styleUrls: ['./chat-view.component.scss']
})
export class ChatViewComponent extends ComponentBase implements OnInit, OnDestroy {

  model$ = this.store.pipe(select(getChat_Model));
  isChatOpened$ = this.store.pipe(select(getChat_IsChatOpened));
  selectedAccount$ = this.store.pipe(select(memberAccountDropdown_SelectedAccount));
  loggedinInfo$ = this.appStore.pipe(select(commomState_LoggedinInfo));
  lastUpdated$ = this.appStore.pipe(select(getChat_LastUpdated));
  chatLoaded$ = this.appStore.pipe(select(getChat_ChatLoaded));
  form$ = this.store.pipe(select(getChat_Form));
  control: FormControlState<string> = createFormControlState<string>('customChatid', '');
  helper = new Helper();
  conversation: any = null;
  client: any = null;
  memberNumber = '';
  channelSid: string = '';
  init = false;
  newDate = new Date();
  conversationStatus = '';
  isTyping = false;
  isWaitingAgent = false;
  unreadMessages = 0;
  outOfHours = false;
  @ViewChild("chat") chat: ElementRef<any>;
  @ViewChild("messages") messages: ElementRef<any>;
  users = new Map();
  constructor(public store: Store<MemberPortalSharedState>,
    public appStore: Store<AppState>) {
    super();

  }

  async ngOnInit() {
    super.ngOnInitBase();

    // var chatLoaded = await this.helper.getValue(this.chatLoaded$);
    // if(chatLoaded)return;


    this.dispatch(this.store, ChatLoadedAction());
    this.dispatch(this.store, RequestAction());
    this.sub = this.isChatOpened$.subscribe(async x => {
      if (x) {
        this.unreadMessages = 0;
        if (this.conversation)
          await this.conversation.setAllMessagesRead();
        setTimeout(() => {
          this.messages.nativeElement.scrollTo({ left: 0, top: this.messages.nativeElement.scrollHeight, behavior: 'smooth' });
        }, 100);
      }
    })
    this.sub = this.loggedinInfo$.subscribe(x => {
      if (x) {
        this.memberNumber = environment.code + '-' + x.memberNumber;
      }
    })
    this.sub = this.lastUpdated$.subscribe(async x => {
      if (x) {
        await this.setupConversation();
      }
    });
    this.sub = this.model$.subscribe(async x => {
      if (x && x.twilioToken && !this.init) {
        this.init = true;
        this.channelSid = x.twilioChannel;

        await this.setupConversation();

      }
    });

  }

  async setupConversation() {
    var model = await this.helper.getValue(this.model$);

    this.client = new Twilio.Conversations.Client(model.twilioToken);
    this.client.on('initialized', async (z) => {

      this.conversation = await this.client.peekConversationBySid(this.channelSid);

      var unread = await this.conversation.getUnreadMessagesCount() ?? 0;

      var isChatOpened = await this.helper.getValue(this.isChatOpened$);

      if (unread > 0 && !isChatOpened) {
        this.unreadMessages = unread;
      }
      this.conversationStatus = this.conversation.state.current;
      if (this.conversation.state.current == 'active') {
        var countP = await this.conversation.getParticipants();
        this.isWaitingAgent = countP.length == 1;
      }
      this.conversation.on('updated', ({ conversation, updateReasons }) => {
        this.conversationStatus = this.conversation.state.current;
      });

      this.conversation.on('typingStarted', this.typingStarted);

      this.conversation.on('participantJoined', (data) => {
        this.isWaitingAgent = false;

      });

      this.conversation.on('typingEnded', () => {
        this.isTyping = false;
      });

      this.conversation.on('messageAdded', async (message) => this.messageAdded(message));

      setTimeout(() => {
        this.messages.nativeElement.scrollTo({ left: 0, top: this.messages.nativeElement.scrollHeight, behavior: 'smooth' });
      }, 500);
    });

    this.client.on('initFailed', ({ error }) => {
      var dd = '';

    });

  }
  async messageAdded(message: any) {
    var isChatOpened = await this.helper.getValue(this.isChatOpened$);
    if (isChatOpened) {
      await this.conversation.setAllMessagesRead();
    }
    else {
      this.unreadMessages++;
    }

    var participants = this.conversation._participants.size;
    this.isWaitingAgent = participants == 1;
    var author = '';
    var checkUser = this.users.get(message.author);
    this.outOfHours = false;
    if (!checkUser) {
      if (message.attributes?.autoreply == "true") {
        author = 'Bot';
        this.outOfHours = true;
        this.appStore.dispatch(RequestDeleteChatAction());
        this.conversationStatus = 'inactive';
      }
      else {
        var u = await this.client.getUser(message.author);
        this.users.set(message.author, u.friendlyName);
        author = u.friendlyName ?? '';
      }
    }
    else {
      author = checkUser;
    }
    var m = {
      author: message.author.toLowerCase() == this.memberNumber.toLowerCase() ? "You" : author,
      isMemberMessage: message.author.toLowerCase() == this.memberNumber.toLowerCase(),
      body: message.body,
      date: message.dateCreated.toString()
    } as ConversationHistoryModel;
    this.store.dispatch(AddMessageAction({ payload: m }));
    setTimeout(() => {
      this.messages.nativeElement.scrollTo({ left: 0, top: this.messages.nativeElement.scrollHeight, behavior: 'smooth' });
    }, 100);
  }
  typingStarted() {
    this.isTyping = true;
  }
  ngOnDestroy() {
    super.ngOnDestroyBase();
    if (this.conversation) {
      this.conversation = null;
    }
  }

  async onEntryButtonClick() {
    var isOpened = await this.helper.getValue(this.isChatOpened$);
    if (isOpened) {
      this.chat.nativeElement.classList.remove('animate__backInRight');
      this.chat.nativeElement.classList.add('animate__backOutRight');
      setTimeout(() => {
        this.store.dispatch(ToggleChatAction());
      }, 500);

    }
    else {
      this.chat.nativeElement.classList.add('animate__backInRight');
      this.chat.nativeElement.classList.remove('animate__backOutRight');
      this.store.dispatch(ToggleChatAction());

    }
  }

  onSubmitClick(text: TwilioChatModel) {
    if (text) {
      try {
        this.conversation.sendMessage(text.message);
      }
      catch (e) {
        var df = e;
      }
    }
  }

  onStartNewChatClick() {
    this.init = false;
    this.store.dispatch(RequestNewChatAction());
  }

}


