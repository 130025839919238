<app-component-wrapper class="w-50 mr-3 flex-fill" compclass="flex-fill h-100" id="member-view-overview">
  <div class="d-flex flex-column flex-fill" slot="start">
    <div id="member-view-overview-container">
      <div class="d-flex flex-column" *ngIf="data$ | async; let data">
        <div class="header">
          <h3>Welcome back!</h3>
          {{data.firstName}} {{data.lastName}}

          <div class="float-right animate__animated " *ngIf="screenWidth>=992" [ngClass]="{'animate__bounde': (tenantConfig$|async)?.overviewWelcomeBackJump}">
            <img [src]="(tenantConfig$|async)?.overviewWelcomeBackImage">
          </div>
        </div>
        <div class="content">
          <div class="d-flex flex-wrap">
            <app-readonly title="Mobile" [value]="data.mobile" class="{{itemclass}} p-1 custom"></app-readonly>
            <app-readonly title="Email Address" [value]="data.email" class="{{itemclass}} p-1 custom"></app-readonly>
          </div>
        </div>
      </div>

    </div>
  </div>
  <div class="d-flex flex-row page-content-actions" slot="actions">
    <app-button icon="{{'BTN.member-view-overview-view.profile.icon' | translate}}" class="" title="View profile" (onClick)="personalDetails()"></app-button>
    <app-button class=" mr-1 ml-1" title="Change Password" icon="{{'BTN.member-view-overview-view.changepassword.icon' | translate}}" color="{{'BTN.member-view-overview-view.changepassword.color' | translate}}" (onClick)="changePassword()"
      *ngIf="screenWidth>992">
    </app-button>
  </div>
