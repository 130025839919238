import { AppButtonToggleComponent, EntryFieldDropdownComponent, EntryFieldRadioComponent } from '@ifaa-components/ui-components';
import { Directive, ElementRef, Renderer2, Input, Host, Self, Optional } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.states';
import { commomState_AmountToTransferList, commomState_AustralianCitizenOrAustralianPermanentResident, commomState_BooleanList, commomState_ClaimTypeList, commomState_EmploymentArrangementList, commomState_GenderList, commomState_OccupationDescriptionList, commomState_PersonalContributions_BankPayments, commomState_PersonalContributions_ContributeFrequency, commomState_PersonalContributions_ElegibilityToContribute, commomState_PersonalContributions_PaymentsMethods, commomState_YesNoList, commomState_AmountTypeList, commomState_CommunicationPreferenceList, commonState_AccessYourSuperApplicationStatuses} from 'src/app/store/common/common.selectors';
import { insuranceQuestionnaireStart_StatementsApplyToYou } from '../components/insurance-questionnaire-start/selectors';

@Directive({
  selector: '[list]',
})
export class AppRadioDirective {

  constructor(
    public store: Store<AppState>,
    public renderer: Renderer2,
    public hostElement: ElementRef,
    @Host() @Self() @Optional() public radio: EntryFieldRadioComponent,
    @Host() @Self() @Optional() public select: EntryFieldDropdownComponent,
    @Host() @Self() @Optional() public buttonToggle: AppButtonToggleComponent,
    ) {

    // if(this.button)
    // this.button.isLoading = commomState_IsLoading;
  }

  ngOnChanges() {
    var dd = '';
  }
  ngAfterViewInit(): void {

  }

  @Input() set list(value: string) {
    switch (value) {
      case 'gender':
        this.setList(this.store.pipe(select(commomState_GenderList)));
        break;
      case 'amountToTransfer':
        this.setList(this.store.pipe(select(commomState_AmountToTransferList)));
        break;
      case 'boolean':
        this.setList(this.store.pipe(select(commomState_BooleanList)));
        break;
      case 'yesNo':
        this.setList(this.store.pipe(select(commomState_YesNoList)));
        break;
      case 'employmentArrangement':
        this.setList(this.store.pipe(select(commomState_EmploymentArrangementList)));
        break;
      case 'occupationDescription':
        this.setList(this.store.pipe(select(commomState_OccupationDescriptionList)));
        break;
      case 'statementsApplyToYou':
        this.setList(this.store.pipe(select(insuranceQuestionnaireStart_StatementsApplyToYou)));
        break;
      case 'elegibilityToContribute':
        this.setList(this.store.pipe(select(commomState_PersonalContributions_ElegibilityToContribute)));
        break;
      case 'contributeFrequency':
        this.setList(this.store.pipe(select(commomState_PersonalContributions_ContributeFrequency)));
        break;
      case 'bankPayments':
        this.setList(this.store.pipe(select(commomState_PersonalContributions_BankPayments)));
        break;
      case 'paymentsMethods':
        this.setList(this.store.pipe(select(commomState_PersonalContributions_PaymentsMethods)));
        break;
      case 'australianCitizenOrAustralianPermanentResident':
        this.setList(this.store.pipe(select(commomState_AustralianCitizenOrAustralianPermanentResident)));
        break;
      case 'claimTypes':
        this.setList(this.store.pipe(select(commomState_ClaimTypeList)));
        break;
      case 'amountTypes':
        this.setList(this.store.pipe(select(commomState_AmountTypeList)));
        break;
      case 'communicationPreferenceList':
          this.setList(this.store.pipe(select(commomState_CommunicationPreferenceList)));
          break;
      case 'accessYourSuperApplicationStatuses':
        this.setList(this.store.pipe(select(commonState_AccessYourSuperApplicationStatuses)))        
    }
  }

  setList(list: any) {
    if (this.radio) {
      this.radio.customList = list;
    }
    if (this.select) {
      this.select.customList = list;
    }
    if (this.buttonToggle) {
      this.buttonToggle.customList = list;
    }
  }
}
