import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ActionsList from './actions';
import { map, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { UnitPriceService } from 'src/app/services/unit-price.service';

@Injectable()
export class UnitPricesViewEffects {
  constructor(private store: Store<any>,
    private actions$: Actions,
    private service: UnitPriceService) {
  }

  getData$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.RequestAction),
    switchMap((action: any) => this.service.unitPrices(action.accountId)),
    map((data: any) => {
      return ActionsList.ResponseAction({ payload: data.data });
    })
  ));

}
