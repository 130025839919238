<div id="approved-training-container">
  <div class="page-header">
    <app-toolbar-options icon="house">
      <div class="d-flex flex-column" slot="start">
        <div class="page-title">Approved JETCO Training Courses </div>
      </div>
    </app-toolbar-options>
  </div>
  <div class="page-content" appanimatecss>
    <app-component-wrapper class="historical-graph-component" icon="" title="">
      <div class="d-flex flex-column" slot="start">
        <!-- <div class="header ">
          <h3 class="mt-2 mb-0">What is JETCO?</h3>
        </div> -->
        <p>
          Training which would be generally considered to be acceptable would be any training course that is a
          requirement for the electrical contracting Industry. This may include post trade electrical training, training
          for electrical contractor’s licences, work site safety training and licence training course. Employees working
          in the tree clearing industry may also have additional courses related to their industry available.
        </p>
        <p>
          Other industry provided modules or training relating to the electrical contracting industry may also qualify
          for reimbursement. If you are in any doubt about a specific course you wish to undertake, please contact the
          JETCO fund administrators prior to enrolling.
        </p>
        <b>Please note that:</b>
        <ul>
          <li>
            JETCO is not able to cover apprenticeship training.
          </li>
          <li>
            Only genuine, reasonable, individual and market rate training expenses can be subsidised. Other charges
            including; assessment fees, licence fees, books, credit card charges, GST, etc. cannot be reimbursed.
          </li>
          <li>
            Training courses must be nationally recognised and completed at a Registered Training Orgainisation (RTO).
          </li>
        </ul>

        <p>
          While not a complete list, the following are approved courses. If the course your intend to complete is not
          listed, please send the course details, your CIRT member ID and date of birth via email to
          <a href="mailto:enquiries@jetco.asn.au">enquiries@jetco.asn.au</a> and we will check your course and confirm your current eligibility.
           </p>

        <div class="d-flex flex-row">
          <div class="flex-fill">
            <div class="header ">
              <h3 class="mt-2 mb-0">Electrical Training</h3>
            </div>
            <ul>
              <li>Electrical Engineering Course</li>
              <li>Electrical Instrumentation Course</li>
              <li>Telecommunication training</li>
              <li>Photovoltaic installation and maintenance</li>
              <li>High voltage switching</li>
              <li>Air conditioning courses</li>
            </ul>
            <div class="header ">
              <h3 class="mt-2 mb-0">Electrical Contractors Course</h3>
            </div>

            <div class="header mt-3">
              <h3 class="mt-2 mb-0">Licencing Courses </h3>
            </div>
            <ul>
              <li>Forklift training courses
              </li>
              <li>Trucks drivers licence up to and including HR
              </li>
              <li>Crane licences up to and including C2
              </li>
            </ul>

          </div>
          <div class="flex-fill">
            <div class="header ">
              <h3 class="mt-2 mb-0">Work Site Safety
              </h3>
            </div>
            <ul>
              <li>Working and heights and EWP courses
              </li>
              <li>First aid and CPR course
              </li>
              <li>Low Voltage Rescue, Switchboard Rescue and EWP Rescue
              </li>
              <li>Hazardous Areas Training
              </li>
              <li>Confined Spaces
              </li>
              <li>S11 Site induction
              </li>
              <li>Occupational health and safety
              </li>

            </ul>
            <div class="header ">
              <h3 class="mt-2 mb-0">Vegetation Industry Training
              </h3>
            </div>
            <ul>
              <li>Pole-top Rescue
              </li>
              <li>Safe Tree Climbing Techniques
              </li>
              <li>Vegetation Management Equipment
              </li>
            </ul>
          </div>
        </div>

      </div>
    </app-component-wrapper>





  </div>
</div>
