<div id="dashboard-container">
  <div class="page-header">
    <app-toolbar-options icon="verified_user">
      <div class="d-flex flex-column" slot="start">
        <div class="page-title">Insurance</div>

      </div>
    </app-toolbar-options>
  </div>
  <div class="page-content" appanimatecss>
    <app-component-wrapper fragmentid="insurance_details" icon="admin_panel_settings" title="Your insurance cover" *ngIf="!(showQuestionnaire$ | async)">
      <div class="d-flex flex-column" slot="start">
        <app-insurance-details></app-insurance-details>
      </div>
    </app-component-wrapper>

       <app-component-wrapper fragmentid="insurance_pre_questionnaire" icon="add_task" title="" *ngIf="(showQuestionnaire$ | async) && (showPreQuestionnaire$ | async)">
      <div class="d-flex flex-column" slot="start">
        <app-insurance-pre-questionnaire></app-insurance-pre-questionnaire>
      </div>
    </app-component-wrapper>
    <app-component-wrapper fragmentid="insurance_questionnaire" icon="add_task" title="" *ngIf="(showQuestionnaire$ | async) && (showStartQuestion$ | async)">
      <div class="d-flex flex-column" slot="start">
        <app-insurance-questionnaire></app-insurance-questionnaire>
      </div>
    </app-component-wrapper>

  </div>
</div>
