import { featureKey } from './../../store/smrt.reducers';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IEmployerBalancesState } from './state';
import { MemberPortalSharedState } from 'src/app/modules/shared/store/shared.states';
import { SMRTState } from '../../store/smrt.states';

export const memberPortalState = createFeatureSelector<SMRTState>(featureKey);

export const employerBalances = createSelector(memberPortalState, (state) => state.employerBalancesState);

export const employerBalances_Data = createSelector(
  employerBalances,
  (state: IEmployerBalancesState) => state.data
);
