import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../store/app.states';
import { ComponentBase } from '../../views/component-base';
import { OnDestroy } from '@angular/core';
import { RequestAction } from '../../modules/shared/components/member-view/actions';

@Component({
  selector: 'app-assets-view',
  templateUrl: './assets-view.component.html',
  styleUrls: ['./assets-view.component.scss'],
  host: {
    class: 'w-100'
  }
})
export class AssetsViewComponent extends ComponentBase implements OnInit, OnDestroy {


  constructor(public store: Store<AppState>) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();
    this.dispatch(this.store, RequestAction());
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

}
