import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { BaseService } from './base.services';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
// import { UserModel } from '../model/user.model';
// import { ForgotPasswordModel } from '../model/forgot-password.model';
// import { ChangePasswordModel } from '../model/change-password.model';

@Injectable()
export class AccountsService extends BaseService {

  constructor(private http: HttpClient,
    public toastr: ToastrService,
    private store: Store<any>) {
    super(toastr);
  }

  getAccounts(): Observable<any> {
    return this.http.get(environment.apiUrl + `/member-portal/account/accounts`, { headers: this.headers() });
  }


  // elevatedAccess(accountId: string): Observable<any> {
  //   return this.http.get(environment.apiUrl + `/accounts/${accountId}/elevated-access-endpoint`, { headers: this.headers() });
  // }



  // get(search: string, page: number): Observable<any> {
  //   const params = new HttpParams()
  //     .set('search', search)
  //     .set('page', page.toString());
  //   return this.http.get(environment.apiUrl + '/users/search', { headers: this.headers(), params: params });
  // }

  // getById(userId: string): Observable<any> {
  //   return this.http.get(environment.apiUrl + `/users/${userId}`, { headers: this.headers() });
  // }

  // create(obj: UserModel): Observable<any> {
  //   return this.http.post<UserModel>(environment.apiUrl + `/users/`, obj, { headers: this.headers() });
  // }

  // changepassword(obj: ChangePasswordModel): Observable<any> {
  //   return this.http.post<ChangePasswordModel>(environment.apiUrl + `/users/change-password`, obj, { headers: this.headers() });
  // }

  // update(obj: UserModel): Observable<any> {
  //   return this.http.put<UserModel>(environment.apiUrl + `/users/${obj.id}`, obj, { headers: this.headers() });
  // }

  // forgotpassword(obj: ForgotPasswordModel): Observable<any> {
  //   return this.http.post<ForgotPasswordModel>(environment.apiUrl + `/users/forgot-password`, obj, { headers: this.headers() });
  // }
}
