export class RollInEditModel {
  providerId: number = 0;
  memberNumber: string = '';
  amountToTransferOption: number = 0;
  amountToTransfer: number = 0;
  confirm: RollInEditConfirmModel = new RollInEditConfirmModel();
  step: 'start' | 'confirm' | 'done' = 'start';
}

export class RollInEditConfirmModel {
  insurance: boolean = false;
  termsAndConditions: boolean = false;
}
