import { DashboardComponent } from './views/dashboard/dashboard.component';
import { CalculatorViewComponent } from './views/calculator/calculator-view.component';
import { ResetPasswordComponent } from './views/reset-password/reset-password.component';
import { OtherDocumentsViewComponent } from './views/other-documents/other-documents-view.component';
import { DocumentsService } from './services/documents.service';
import { UnitPricesComponent } from './views/unit-prices/unit-prices.component';
import { MemberStatementsViewComponent } from './views/member-statements/member-statements-view.component';
import { UnitPriceService } from './services/unit-price.service';
import { AssetsViewComponent } from './views/assets-view/assets-view.component';
import { PersonalContributionViewComponent } from './views/personal-contributions-view/personal-contributions-view.component';
import { ContributionsViewComponent } from './views/contributions-view/contributions-view.component';
import { RollInViewComponent } from './views/rollin-view/rollin-view.component';
import { SwitchInvestmentsViewComponent } from './views/switch-investments-view/switch-investments-view.component';
import { SwitchInvestmentsService } from './services/switch-investments.service';
import { InsuranceViewComponent } from './views/insurance-view/insurance-view.component';
import { HistoricalDataService } from './services/historical-data.service';
import { ShowQuestionnaireGuardService } from './route-guard/show-questionnaire-guard.service';
import { InsuranceQuestionnaireService } from './services/insurance-questionnaire.service';
import { QuestionnaireViewComponent } from './views/questionnaire/questionnaire-view.component';
import { TransactionListViewComponent } from './views/transaction-list/transaction-list.component';
import { BeneficiaryEditComponent } from './views/beneficiary-edit/beneficiary-edit.component';
import { PersonalEditComponent } from './views/personal-edit/personal-edit.component';
import { ChangePasswordComponent } from './views/change-password/change-password.component';
import { ForgotPasswordEffects } from './views/forgot-password/effects';
import { ResetPasswordEffects } from './views/reset-password/effects';
import { ForgotPasswordComponent } from './views/forgot-password/forgot-password.component';
import { PersonalDetailsComponent } from './views/personal-details/personal-details.component';
import { LoginEffects } from './views/login/effects';
import { LoginComponent } from './views/login/login.component';
import { MemberNoteTypeService } from './services/member-note-type.service';
import { CostOfCoverService } from './services/costofcover.service';
import { UnderlyingInvestmentService } from './services/underlying-investment.service';
import { WebUserService } from './services/webuser.service';
import { MemberCorrespondenceService } from './services/member-correspondence.service';
import { InvestmentService } from './services/investment.service';
import { TransactionService } from './services/transaction.service';
import { BeneficiaryService } from './services/beneficiary.service';
import { InsuranceService } from './services/insurance.service';
import { RolesService } from './services/roles.service';
import { PermissionGuardService } from './route-guard/permission-guard.service';
import { CommonEffects } from './store/common/common.effects';
import { HelperService } from './services/helper.service';
import { AccountsService } from './services/accounts.service';
import { HttpCustomInterceptor } from './services/http-interceptor';
import { BrowserModule, HammerModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StoreModule, MetaReducer } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NgrxFormsModule } from 'ngrx-forms';
import { reducers } from './store/app.reducers';
import { environment } from 'src/environments/environment';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './app.material.module';
import { ToastrModule } from 'ngx-toastr';
import { MembersService } from './services/members.service';
import { AuthGuardService } from './route-guard/auth-guard.service';
import { TimeagoModule } from 'ngx-timeago';
import { clearState } from './store/meta-reducer';
import { RollInService } from './services/rollin.service';
import Bugsnag from '@bugsnag/js'
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular'
import { IfaaUiComponentsModule } from '@ifaa-components/ui-components';
import { TransactionsComponent } from './views/transactions/transactions.component';
import { GetTenantModule } from './tenant.helper';
import { ChatService } from './services/chat.service';
import { MemberPortalSharedModule } from './modules/shared/shared.module';
import { NotificationService } from './services/notification.service';
import { BeneficiaryViewComponent } from './views/beneficiary-view/beneficiary-view.component';
import { PersonalContributionService } from './services/personal-contrubution.service';
import { ChangePasswordEffects } from './views/change-password/effects';
import { UserServiceConfig } from '@ifaa-components/ui-components/lib/services/userServiceConfig';
import { RecaptchaModule, RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { ElevatedAccessComponent } from './views/elevated-access/elevated-access.component';
import { FeatureFlagGuardService } from './route-guard/feature-flag-guard.service';

import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { BPAYDetailsService } from './services/bpay-details.service';
import { MakeContributionViewComponent } from './views/make-a-contribution-view/make-a-contribution-view.component';
import { MemberPensionService } from './services/member-pension.service';
import { PensionViewComponent } from './views/pension-view/pension-view.component';
import { PensionGuardService } from './route-guard/pension-guard.service';
import { InvestmentEditViewComponent } from './views/investment-view/investment-view.component';
import { AccessYourSuperViewComponent } from './views/access-your-super-view/access-your-super-view.component';
import { AccessYourSuperService } from './services/access-your-super.service';

export const metaReducers: MetaReducer<any>[] = [clearState];
Bugsnag.start({
  apiKey: environment.bugsnagApiKey,
  releaseStage: environment.bugsnagEnv,
  enabledReleaseStages: ['Production', 'Staging', 'Uat', 'unknown'],
  onError: function (event) {
    event.addMetadata('tenant', {
      tenant: environment.code
    })
  }
});


export function errorHandlerFactory() {
  return new BugsnagErrorHandler()
}

const myImports = [
  NgrxFormsModule,
  CommonModule,
  HammerModule,
  FormsModule,
  PdfJsViewerModule,
  RecaptchaV3Module,
  IfaaUiComponentsModule.forRoot({
    entryAppearance: 'outline',
    showEntryPlaceholder: false,
    apiUrl: environment.apiUrl,
    greenIdConfig: {
      environment: environment.greenIdEnv,
      code: environment.greenIdCode
    }
  } as UserServiceConfig),
  MemberPortalSharedModule,
  ToastrModule.forRoot({
    timeOut: 6000,
    positionClass: 'toast-bottom-right',
    preventDuplicates: true,
  }),
  MaterialModule,
  HttpClientModule,
  TimeagoModule.forRoot(),
  BrowserAnimationsModule,
  AppRoutingModule,
  TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useFactory: HttpLoaderFactory,
      deps: [HttpClient]
    }
  }),
  StoreModule.forRoot(reducers, { metaReducers }),
  StoreDevtoolsModule.instrument({
    maxAge: 20, // Retains last 25 states
    logOnly: environment.production, // Restrict extension to log-only mode
  }),
  ReactiveFormsModule,
  EffectsModule.forRoot([
    LoginEffects,
    CommonEffects,
    ForgotPasswordEffects,
    ResetPasswordEffects,
    ChangePasswordEffects
  ])
];

GetTenantModule.getModule(myImports);

@NgModule({
  declarations: [
    DashboardComponent,
    TransactionsComponent,
    PersonalDetailsComponent,
    LoginComponent,
    AppComponent,
    ForgotPasswordComponent,
    ChangePasswordComponent,
    PersonalEditComponent,
    BeneficiaryEditComponent,
    BeneficiaryViewComponent,
    TransactionListViewComponent,
    QuestionnaireViewComponent,
    InsuranceViewComponent,
    SwitchInvestmentsViewComponent,
    RollInViewComponent,
    ContributionsViewComponent,
    PersonalContributionViewComponent,
    MakeContributionViewComponent,
    AssetsViewComponent,
    UnitPricesComponent,
    MemberStatementsViewComponent,
    OtherDocumentsViewComponent,
    CalculatorViewComponent,
    ResetPasswordComponent,
    ElevatedAccessComponent,
    PensionViewComponent,
    InvestmentEditViewComponent,
    AccessYourSuperViewComponent
  ],
  entryComponents: [
  ],
  imports: myImports,
  providers: [
    MembersService,
    AuthGuardService,
    PensionGuardService,
    ShowQuestionnaireGuardService,
    FeatureFlagGuardService,
    AccountsService,
    HelperService,
    RolesService,
    PermissionGuardService,
    InsuranceService,
    BeneficiaryService,
    BPAYDetailsService,
    RollInService,
    TransactionService,
    InvestmentService,
    MemberCorrespondenceService,
    UnderlyingInvestmentService,
    WebUserService,
    CostOfCoverService,
    MemberNoteTypeService,
    HistoricalDataService,
    SwitchInvestmentsService,
    InsuranceQuestionnaireService,
    MemberPensionService,
    UnitPriceService,
    DocumentsService,
    PersonalContributionService,
    NotificationService,
    ChatService,
    AccessYourSuperService,
    { provide: HTTP_INTERCEPTORS, useClass: HttpCustomInterceptor, multi: true },
    { provide: ErrorHandler, useFactory: errorHandlerFactory },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptchaApi }
  ],
  exports: [

  ],
  bootstrap: [AppComponent]
})
export class AppModule {

}
