<div id="bpay-details-container">

  <p class="mb-3" [innerHTML]="'bpayContribution_Description'|message|async"></p>

  <p class="mb-3">
    <strong>Below are your unique BPAY details to make Personal Contributions through your bank</strong>
  </p>

  <app-no-items message="No BPAY Details found" *ngIf="!(bpayDetail$ | async)?.billerCode"></app-no-items>

  <div class="d-flex flex-row">

    <mat-card class="bpay-card d-flex flex-fill flex-column" *ngIf="(bpayDetail$ | async) as bpayDetail">
      <mat-card-header>
        <mat-card-title>BPAY Details</mat-card-title>
        <mat-card-subtitle>To make additional payments to your Superannuation account by BPAY, please use the details
          below</mat-card-subtitle>
      </mat-card-header>

      <mat-card-content>
        <div class="d-flex flex-row flex-wrap">
          <div class="d-flex flex-column">
            <img mat-card-image src="https://sharedassetstorage.blob.core.windows.net/web/bpay.png"  class="bpay-image-position pl-3 mt-1">
          </div>
          <div class="details justify-content-sm-center">
            <p class="d-flex flex-row">Biller Code: <app-copyable class="pl-2" text="{{bpayDetail.billerCode}}">
              </app-copyable>
            </p>
            <p class="d-flex flex-row">Reference: <app-copyable class="pl-2" text="{{bpayDetail.reference}}">
              </app-copyable>
            </p>
          </div>
        </div>

      </mat-card-content>
    </mat-card>




  </div>

  <div class="alert alert-info mt-3">
    <p class="p-1 info">Please keep in mind your financial institution's processing time-frames if making payment
      via
      BPAY. We
      recommend you allow at least three business days for BPAY.</p>
  </div>
</div>