import { UnitPricesComponent } from './views/unit-prices/unit-prices.component';
import { TransactionsComponent } from './views/transactions/transactions.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { LoginComponent } from './views/login/login.component';
import { GetTenantDashboard, GetTenantMainLayout, GetTenantNotLoggedInLayout } from './tenant.helper';
import { PersonalDetailsComponent } from './views/personal-details/personal-details.component';
import { AuthGuardService } from './route-guard/auth-guard.service';
import { ForgotPasswordComponent } from './views/forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './views/change-password/change-password.component';
import { PersonalEditComponent } from './views/personal-edit/personal-edit.component';
import { BeneficiaryEditComponent } from './views/beneficiary-edit/beneficiary-edit.component';
import { TransactionListViewComponent } from './views/transaction-list/transaction-list.component';
import { QuestionnaireViewComponent } from './views/questionnaire/questionnaire-view.component';
import { ShowQuestionnaireGuardService } from './route-guard/show-questionnaire-guard.service';
import { InsuranceViewComponent } from './views/insurance-view/insurance-view.component';
import { SwitchInvestmentsViewComponent } from './views/switch-investments-view/switch-investments-view.component';
import { RollInViewComponent } from './views/rollin-view/rollin-view.component';
import { ContributionsViewComponent } from './views/contributions-view/contributions-view.component';
import { PersonalContributionViewComponent } from './views/personal-contributions-view/personal-contributions-view.component';
import { AssetsViewComponent } from './views/assets-view/assets-view.component';
import { MemberStatementsViewComponent } from './views/member-statements/member-statements-view.component';
import { OtherDocumentsViewComponent } from './views/other-documents/other-documents-view.component';
import { CalculatorViewComponent } from './views/calculator/calculator-view.component';
import { ResetPasswordComponent } from './views/reset-password/reset-password.component';
import { BeneficiaryViewComponent } from './views/beneficiary-view/beneficiary-view.component';
import { ElevatedAccessComponent } from './views/elevated-access/elevated-access.component';
import { FeatureFlagGuardService } from './route-guard/feature-flag-guard.service';
import { MakeContributionViewComponent } from './views/make-a-contribution-view/make-a-contribution-view.component';
import { PensionViewComponent } from './views/pension-view/pension-view.component';
import { PensionGuardService } from './route-guard/pension-guard.service';
import { InvestmentEditComponent } from './modules/shared/components/investment-edit/investment-edit.component';
import { InvestmentEditViewComponent } from './views/investment-view/investment-view.component';
import { AccessYourSuperViewComponent } from './views/access-your-super-view/access-your-super-view.component';

var routes: Routes = [
  {
    path: 'login',
    component: GetTenantNotLoggedInLayout.layout(),
    children: [
      {
        outlet: 'master',
        path: '',
        component: LoginComponent
      }
    ]
  },
  {
    path: 'elevated-access',
    component: GetTenantNotLoggedInLayout.layout(),
    children: [
      {
        outlet: 'master',
        path: '',
        component: ElevatedAccessComponent
      }
    ]
  },
  {
    path: 'reset-password/:token',
    component: GetTenantNotLoggedInLayout.layout(),
    children: [
      {
        outlet: 'master',
        path: '',
        component: ResetPasswordComponent
      }
    ]
  },
  {
    path: 'forgot-password',
    component: GetTenantNotLoggedInLayout.layout(),
    children: [
      {
        outlet: 'master',
        path: '',
        component: ForgotPasswordComponent
      }
    ]
  },
  {
    path: 'dashboard',
    component: GetTenantMainLayout.mainLayout(),
    canActivate: [AuthGuardService],
    children: [
      {
        outlet: 'master',
        path: '',
        component: GetTenantDashboard.layout()
      }
    ]
  },
  {
    path: 'change-password',
    component: GetTenantMainLayout.mainLayout(),
    canActivate: [AuthGuardService],
    children: [
      {
        outlet: 'master',
        path: '',
        component: ChangePasswordComponent
      }
    ]
  },
  {
    path: 'transactions',
    component: GetTenantMainLayout.mainLayout(),
    canActivate: [AuthGuardService],
    children: [
      {
        outlet: 'master',
        path: '',
        component: TransactionsComponent
      }
    ]
  },
  {
    path: 'personal-details',
    component: GetTenantMainLayout.mainLayout(),
    canActivate: [AuthGuardService],
    children: [
      {
        outlet: 'master',
        path: '',
        component: PersonalDetailsComponent
      }
    ]
  },
  {
    path: 'personal-edit',
    component: GetTenantMainLayout.mainLayout(),
    canActivate: [AuthGuardService],
    children: [
      {
        outlet: 'master',
        path: '',
        component: PersonalEditComponent
      }
    ]
  },
  {
    path: 'beneficiaries-edit',
    component: GetTenantMainLayout.mainLayout(),
    canActivate: [AuthGuardService],
    children: [
      {
        outlet: 'master',
        path: '',
        component: BeneficiaryEditComponent
      }
    ]
  },
  {
    path: 'beneficiaries',
    component: GetTenantMainLayout.mainLayout(),
    canActivate: [AuthGuardService],
    children: [
      {
        outlet: 'master',
        path: '',
        component: BeneficiaryViewComponent
      }
    ]
  },
  {
    path: 'transaction-listing',
    component: GetTenantMainLayout.mainLayout(),
    canActivate: [AuthGuardService],
    children: [
      {
        outlet: 'master',
        path: '',
        component: TransactionListViewComponent
      }
    ]
  },
  {
    path: 'start-questionnaire',
    component: GetTenantMainLayout.mainLayout(),
    canActivate: [AuthGuardService, ShowQuestionnaireGuardService],
    children: [
      {
        outlet: 'master',
        path: '',
        component: QuestionnaireViewComponent
      }
    ]
  },
  {
    path: 'insurance',
    component: GetTenantMainLayout.mainLayout(),
    canActivate: [AuthGuardService],
    children: [
      {
        outlet: 'master',
        path: '',
        component: InsuranceViewComponent
      }
    ]
  },
  // {
  //   path: 'switch-investments',
  //   component: GetTenantMainLayout.mainLayout(),
  //   canActivate: [AuthGuardService],
  //   children: [
  //     {
  //       outlet: 'master',
  //       path: '',
  //       component: SwitchInvestmentsViewComponent
  //     }
  //   ]
  // },
  {
    path: 'rollin',
    component: GetTenantMainLayout.mainLayout(),
    canActivate: [AuthGuardService],
    children: [
      {
        outlet: 'master',
        path: '',
        component: RollInViewComponent
      }
    ]
  },
  {
    path: 'contributions',
    component: GetTenantMainLayout.mainLayout(),
    canActivate: [AuthGuardService],
    children: [
      {
        outlet: 'master',
        path: '',
        component: ContributionsViewComponent
      }
    ]
  },
  {
    path: 'personal-contributions',
    component: GetTenantMainLayout.mainLayout(),
    canActivate: [AuthGuardService],
    children: [
      {
        outlet: 'master',
        path: '',
        component: PersonalContributionViewComponent
      }
    ]
  },
 {
    path: 'make-a-contribution',
    component: GetTenantMainLayout.mainLayout(),
    canActivate: [AuthGuardService, FeatureFlagGuardService],
    data: { flag: 'displayAccountBPAYDetails' },
    children: [
      {
        outlet: 'master',
        path: '',
        component: MakeContributionViewComponent
      }
    ]
  },

  {
    path: 'view-assets',
    component: GetTenantMainLayout.mainLayout(),
    canActivate: [AuthGuardService],
    children: [
      {
        outlet: 'master',
        path: '',
        component: AssetsViewComponent
      }
    ]
  },
  {
    path: 'unit-prices',
    component: GetTenantMainLayout.mainLayout(),
    canActivate: [AuthGuardService],
    children: [
      {
        outlet: 'master',
        path: '',
        component: UnitPricesComponent
      }
    ]
  },
  {
    path: 'member-statements',
    component: GetTenantMainLayout.mainLayout(),
    canActivate: [AuthGuardService],
    children: [
      {
        outlet: 'master',
        path: '',
        component: MemberStatementsViewComponent
      }
    ]
  },
  {
    path: 'other-documents',
    component: GetTenantMainLayout.mainLayout(),
    canActivate: [AuthGuardService, FeatureFlagGuardService],
    data: { flag: 'displayOtherDocuments' },
    children: [
      {
        outlet: 'master',
        path: '',
        component: OtherDocumentsViewComponent
      }
    ]
  },


  {
    path: 'pension-details',
    component: GetTenantMainLayout.mainLayout(),
    canActivate: [AuthGuardService, FeatureFlagGuardService, PensionGuardService],
    data: { flag: 'isMemberPensionEnabled' },
    children: [
      {
        outlet: 'master',
        path: '',
        component: PensionViewComponent
      }
    ]
  },
  {
    path: 'calculator',
    component: GetTenantMainLayout.mainLayout(),
    canActivate: [AuthGuardService],
    children: [
      {
        outlet: 'master',
        path: '',
        component: CalculatorViewComponent
      }
    ]
  },
  {
    path: 'investment',
    component: GetTenantMainLayout.mainLayout(),
    canActivate: [AuthGuardService],
    children: [
      {
        outlet: 'master',
        path: '',
        component: InvestmentEditViewComponent
      }
    ]
  },
  {
    path: 'access-your-super',
    component: GetTenantMainLayout.mainLayout(),
    canActivate: [AuthGuardService],
    children: [
      {
        outlet: 'master',
        path: '',
        component: AccessYourSuperViewComponent
      }
    ]
  },
  {
    path: 'verve',
    loadChildren: () => import('./modules/verv/verve-routing.module').then(m => m.VerveRoutingModule)
  },
  {
    path: 'cirt',
    loadChildren: () => import('./modules/cirt/cirt-routing.module').then(m => m.CIRTRoutingModule)
  },
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full'
  },
  { path: '**', redirectTo: '/dashboard' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled',
    relativeLinkResolution: 'legacy',
    initialNavigation: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
