
import { IChatViewState, TwilioChatModel, TwilioModel } from './state';
import { createReducer, on, State, Action } from '@ngrx/store';
import {
  createFormGroupState,
  onNgrxForms, onNgrxFormsAction, SetValueAction, updateGroup, validate, wrapReducerWithFormStateUpdate
} from 'ngrx-forms';
import { AddMessageAction, ChatLoadedAction, ResponseAction, ResponseChatClosedAction, ToggleChatAction } from './actions';
import { required } from 'ngrx-forms/validation';
import { Helper } from '@ifaa-components/ui-components';

export const formName = 'chatForm';
var helper = new Helper();

export const state: IChatViewState = {
  model: new TwilioModel(),
  isChatOpened: false,
  form: createFormGroupState(formName, new TwilioChatModel()),
  lastUpdated: null,
  loaded: false
};

export const validateForm = updateGroup<TwilioChatModel>({
  // message: validate(required),
});

const reducer = createReducer(state,
  onNgrxForms(),
  onNgrxFormsAction(SetValueAction, (state, action) => {
    return state;
  }),
  on(ResponseAction, (state, { payload }) => {
    return {
      ...state,
      model: payload
    };
  }),
  on(ChatLoadedAction, (state, { }) => {
    return {
      ...state,
      loaded: true
    };
  }),
  on(ResponseChatClosedAction, (state, { payload }) => {
    var model = helper.clone(state.model);
    model.twilioToken = payload.twilioToken;

    return {
      ...state,
      model: model,
      lastUpdated: new Date()
    };
  }),
  on(AddMessageAction, (state, { payload }) => {
    var model = helper.clone(state.model);
    model.conversationHistory.push(payload)

    return {
      ...state,
      model: model,
      form: createFormGroupState(formName, new TwilioChatModel()),

    };
  }),
  on(ToggleChatAction, (state, { }) => {
    return {
      ...state,
      isChatOpened: !state.isChatOpened
    };
  })
);

const reducerFormState = wrapReducerWithFormStateUpdate(
  reducer,
  s => s.form,
  validateForm,
);

export function chatViewReducer(state: any | undefined, action: Action) {
  return reducerFormState(state, action);
}
