import { InvestmentService } from './services/investment.service';
import { Component } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from './store/app.states';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IsLoggedInRequestAction, SystemConfigurationRequestAction } from './store/common/common.actions';
import { OverlayContainer } from '@angular/cdk/overlay';
import { DomSanitizer } from '@angular/platform-browser';
import { memberAccountDropdown_SelectedAccount } from './modules/shared/components/member-account-dropdown/selectors';
import { ShowQuestionnaireRequestAction } from './modules/shared/components/insurance-questionnaire/actions';
import { commomState_LoggedinInfo, commomState_SystemConfig_Config, commomState_SystemConfig_FeatureToggle } from './store/common/common.selectors';
import { distinctUntilChanged, filter, map, pluck } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { combineLatest } from 'rxjs';

declare var gtag
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'fund-portal';

  selectedAccount$ = this.store.pipe(select(memberAccountDropdown_SelectedAccount));
  favIcon: HTMLLinkElement = document.querySelector('#appIcon');
  tenantConfig$ = this.store.pipe(select(commomState_SystemConfig_Config));
  // env$ = this.store.pipe(select(commomState_Env));
  loggedinInfo$ = this.store.pipe(select(commomState_LoggedinInfo));
  featuretoggle$ = this.store.pipe(select(commomState_SystemConfig_FeatureToggle));
  dynamicCSSUrl: string;
  resetChat = null;
  showChat = false;
  code = environment.code;

  constructor(public store: Store<AppState>,
    public translate: TranslateService,
    public sanitizer: DomSanitizer,
    private router: Router,
    private overlayContainer: OverlayContainer
  ) {
    // translate.addLangs(['en.' + environment.code]);
    // translate.setDefaultLang('en.' + environment.code);
    translate.addLangs(['en', 'en.' + environment.code]);
    translate.setDefaultLang('en');
    translate.use('en.' + environment.code);
    this.overlayContainer.getContainerElement().classList.add('dark-theme');
    this.dynamicCSSUrl = '/modules/dynamic.css';

    this.store.dispatch(IsLoggedInRequestAction());
    this.store.dispatch(SystemConfigurationRequestAction());

    combineLatest([this.featuretoggle$, this.loggedinInfo$])
      .pipe(
        map(([featureToggle, loggedInInfo]) => ({ featureToggle, loggedInInfo })))
      .subscribe(x => {
        var show = false;

        if (x && x.loggedInInfo.username && x.featureToggle?.chatEnabled == true) {
          show = true;
        }

        this.showChat = show;

      });

    this.selectedAccount$
      .pipe(
        distinctUntilChanged((prev, curr) => prev?.accountId === curr?.accountId))
      .subscribe(x => {
        if (x) {
          this.store.dispatch(ShowQuestionnaireRequestAction({ accountId: x.accountId }));
        }
      })

    this.tenantConfig$.subscribe(x => {
      if (x)
        this.favIcon.href = x.favIcon;
    });

    this.setupGoogleTagManager();
  }

  setupGoogleTagManager() {
    if (!environment.ga) return;

    const navEndEvent$ = this.router.events.pipe(
      filter(e => e instanceof NavigationEnd)
    );
    navEndEvent$.subscribe((e: NavigationEnd) => {
      gtag('config', environment.ga, { 'page_path': e.urlAfterRedirects });
    });

    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.ga;
    document.head.prepend(script);
  }

}
