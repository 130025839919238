import { SetMenuAction } from './../../../../store/common/common.actions';
import { mainLayout_Expanded } from './selectors';
import { MenuModel } from './../../../../model/menu.model';
import { AppState } from 'src/app/store/app.states';
import { Component, HostListener, Inject, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ComponentBase } from 'src/app/views/component-base';
import { commomState_IsLoading, commomState_LoggedinInfo, commomState_MenuList } from 'src/app/store/common/common.selectors';
import { SystemConfigurationRequestAction } from 'src/app/store/common/common.actions';
import { MainExpandedRequestAction } from './actions';
import { VerveState } from '../../store/verve.states';
import { MatDrawer } from '@angular/material/sidenav';
import { insuranceQuestionnaire_ShowQuestionnaire } from 'src/app/modules/shared/components/insurance-questionnaire/selectors';
import { Title } from '@angular/platform-browser';
import { memberViewOverview_Model } from 'src/app/modules/shared/components/member-view-overview/selectors';
import { RequestAction } from 'src/app/modules/shared/components/member-view/actions';

@Component({
  selector: 'login-master',
  templateUrl: './main.master.html',
  styleUrls: ['./main.master.scss']
})
export class MainMasterComponent extends ComponentBase {

  expanded: MenuModel[] = [];
  expanded$ = this.vervestore.pipe(select(mainLayout_Expanded));
  menuList$ = this.store.pipe(select(commomState_MenuList));
  loggedInUser$ = this.store.pipe(select(commomState_LoggedinInfo));
  isLoading$ = this.store.pipe(select(commomState_IsLoading));
  isQuestionnarieVisible$ = this.store.pipe(select(insuranceQuestionnaire_ShowQuestionnaire));
  user$ = this.store.pipe(select(memberViewOverview_Model));

  menuMode = "side";
  menuOpened = true;
  showMenuIconTop = true;
  menu = [
    { id: '1', name: 'Overview', link: '/dashboard', icon: 'house', children: [] } as MenuModel,
    {
      id: '2', name: 'Account', icon: 'account_box', children: [
        { id: '5-1', name: 'Update your details', link: '/personal-details', icon: 'create', children: [] } as MenuModel,
        { id: '5-4', name: 'Change your password', link: '/change-password', icon: 'security', children: [] } as MenuModel,
        // { id: '2-1', name: 'Transaction Listing', link: '/transaction-listing', icon: 'receipt_long', children: [] } as MenuModel,
        // { id: '2-2', name: 'Contribution Caps', link: '/contributions', icon: 'airplay', children: [] } as MenuModel,
        // { id: '2-3', name: 'Make a personal contribution', link: '/personal-contributions', icon: 'local_atm', children: [] } as MenuModel,
      ]
    } as MenuModel,
    {
      id: '3', name: 'Investments', icon: 'attach_money', children: [
        { id: '2-1', name: 'Transaction History', link: '/transaction-listing', icon: 'receipt_long', children: [] } as MenuModel,
        { id: '3-1', name: 'View Assets', link: '/view-assets', icon: 'file_copy', children: [] } as MenuModel,
        // { id: '3-2', name: 'Unit Prices', link: '/unit-prices', icon: 'local_offer', children: [] } as MenuModel,
        //{ id: '3-3', name: 'Switch Investments', link: '/switch-investments', icon: 'switch_left', children: [] } as MenuModel,
      ]
    } as MenuModel,
    { id: '4', name: 'Consolidate', link: '/rollin', icon: 'account_balance_wallet', children: [] } as MenuModel,
    // {
    //   id: '5', name: 'Profile', icon: 'person', children: [
    //     { id: '5-1', name: 'Update your details', link: '/personal-details', icon: 'create', children: [] } as MenuModel,
    //     { id: '5-3', name: 'Your Beneficiaries', link: '/beneficiaries-edit', icon: 'supervisor_account', children: [] } as MenuModel,
    //     { id: '5-4', name: 'Change your password', link: '/change-password', icon: 'security', children: [] } as MenuModel,
    //   ]
    // } as MenuModel,
    {
      id: '11', name: 'Contributions', icon: 'paid', children: [
        { id: '2-3', name: 'Make a personal contribution', link: '/personal-contributions', icon: 'local_atm', children: [] } as MenuModel,
        { id: '2-2', name: 'Contribution Caps', link: '/contributions', icon: 'airplay', children: [] } as MenuModel,
      ]
    } as MenuModel,
    {
      id: '6', name: 'Insurance', link: '/insurance', icon: 'verified_user', children: [
        { id: '6-1', name: 'Your insurance cover', link: '/insurance', fragment: 'insurance_details', icon: 'admin_panel_settings', children: [] } as MenuModel,
        { id: '6-2', name: 'Request Insurance', link: '/insurance', fragment: 'insurance_questionnaire', icon: 'add_task', children: [] } as MenuModel,
      ]
    } as MenuModel,
    {
      id: '12', name: 'Beneficiaries', link: '/beneficiaries', icon: 'supervisor_account', children: [
        //{ id: '5-3', name: 'Your Beneficiaries', link: '/beneficiaries-edit', icon: 'supervisor_account', children: [] } as MenuModel,
      ]
    } as MenuModel,
    {
      id: '7', name: 'Documents', icon: 'folder', children: [
        { id: '7-1', name: 'View Statements', link: '/member-statements', icon: 'text_snippet', children: [] } as MenuModel,
        { id: '7-2', name: 'Other Documents', link: '/other-documents', icon: 'article', children: [] } as MenuModel,
      ]
    } as MenuModel,
    {
      id: '9', name: 'Verve Learn', icon: 'school', link: 'https://vervesuper.com.au/learn-members/', target: '_blank', children: []

      // children: [
      //   { id: '9-1', name: '6 Step Money Guide', link: 'https://vervesuper.com.au/academy/', target: '_blank', icon: 'import_contacts', children: [] } as MenuModel,
      //   { id: '9-2', name: 'Events', link: 'https://vervesuper.com.au/events/', target: '_blank', icon: 'event_note', children: [] } as MenuModel,
      //   { id: '9-3', name: 'Support Squad', link: 'https://vervesuper.com.au/support-squad/', target: '_blank', icon: 'supervised_user_circle', children: [] } as MenuModel,
      // ]
    } as MenuModel,
    {
      id: '10', name: 'Calculator', link: '/calculator', icon: 'calculate', children: []
    } as MenuModel,


  ]

  @ViewChild("drawer") drawer: MatDrawer;

  constructor(public store: Store<AppState>,
    public vervestore: Store<VerveState>,
    public dialog: MatDialog,
    private titleService: Title,
    private router: Router) {
    super();
    this.titleService.setTitle("Verve Super – Member Portal");
  }

  ngOnInit() {
    super.ngOnInitBase();
    this.store.dispatch(RequestAction());

    this.screenWidthChange(this.store, (width: number) => {
      this.menuMode = "side";
      this.menuOpened = true;

      if (width < 1300) {
        this.menuMode = "over";
        this.menuOpened = false;
      }

    });
    this.sub = this.expanded$.subscribe(x => {
      this.expanded = x;
    });

    this.checkQuestionnarieMenuIsVisible();
    // this.setMenu(this.menu);
  }

  checkQuestionnarieMenuIsVisible() {
    this.sub = this.isQuestionnarieVisible$.subscribe(isVisible => {
      var newMenu = [...this.menu];
      if (!isVisible) {
        for (let i = 0; i < newMenu.length; i++) {
          newMenu[i] = { ...newMenu[i], children: newMenu[i].children?.filter(c => c.id != '6-2') };
        }
      }
      else {
        for (let i = 0; i < newMenu.length; i++) {
          newMenu[i] = { ...newMenu[i], children: newMenu[i].children?.filter(c => c.id != '6-1') };
        }
      }
      this.setMenu(newMenu);
    });
  }
  openedChange() {
    if (this.drawer) {
      this.menuOpened = this.drawer.opened;
    }
  }
  onSwipeToggle(event) {
    this.menuOpened = !this.menuOpened;
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

  onItemSelectedClick(item: MenuModel) {
    this.store.dispatch(MainExpandedRequestAction({ menuItem: item }))

    if (item.link?.startsWith('http')) {
      window.open(item.link, "_blank");
      return;
    }

    if (item.link) {
        this.router.navigate([item.link]);
    }
  }

  isMenuExpanded(item: MenuModel) {
    return this.expanded.filter(x => x.id == item.id).length > 0;
  }

  onSignoutClick(): void {

    window.localStorage.removeItem('token_name');
    this.router.navigate(['login']);
  }


  setMenu(menu: MenuModel[]) {

    this.store.dispatch(SetMenuAction({ data: menu }))

  }

}
