<footer id="footer-layout-container" class="mt-4 " *ngIf="tenantConfig$ | async; let tenant">
  <div class="container-fluid p-4">
    <div class="d-flex flex-row main">
      <div class="footer-left-side d-flex align-items-center">
        <div>
          <a href="https://www.vervesuper.com.au" target="_blank"><img src="https://vervassets.blob.core.windows.net/web/logo-white.png" class="logo"></a>
          <a class="no-text-decoration mt-4" href="tel:{{tenant.phoneNumber}}">{{tenant.phoneNumber}}</a>
          <a class="no-text-decoration" href="mailto:{{tenant.email}}">{{tenant.email}}</a>
          <div class="social d-flex flex-row">
            <a href="https://www.facebook.com/vervesuper" target="blank">
              <img src="https://vervassets.blob.core.windows.net/web/mp-facebook.png">
            </a>
            <a href="https://www.linkedin.com/company/vervesuper" target="blank">
              <img src="https://vervassets.blob.core.windows.net/web/mp-linkedin.png">
            </a>
            <a href="https://twitter.com/vervesuper" target="blank">
              <img src="https://vervassets.blob.core.windows.net/web/mp-twitter.png">
            </a>
            <a href="https://www.instagram.com/verve.super/" target="blank">
              <img src="https://vervassets.blob.core.windows.net/web/mp-instagram.png">
            </a>
          </div>
        </div>
      </div>
      <div class="footer-right-side">
        <div class="row mt-3">
          <p class="disclosure">We acknowledge the Gadigal, Ngunnawal, Bundjalung Arakwal, and Wurundjeri people as the
            traditional owners of the land upon which our staff work flexibly from their homes or chosen office space.
            We respect elders past and present and acknowledge that sovereignty was never ceded. </p>
          <!-- <p class="disclosure">We welcome all people who believe in Verve’s mission to join the fund.</p> -->
          <img class="flags" src="https://vervassets.blob.core.windows.net/web/flags.png">

        </div>
        <div class="row mt-3">
          <img src="https://vervassets.blob.core.windows.net/web/mp-footer-line.png" class="line">
          <div class="col-4">
            <h5>Super</h5>
            <a class="no-text-decoration" href="https://vervesuper.com.au/ethical-investing/" target="_blank">Ethical
              Investing</a>
            <a class="no-text-decoration" href="https://vervesuper.com.au/fees-and-returns/" target="_blank">Fees &
              returns</a>
            <a class="no-text-decoration" href="https://vervesuper.com.au/member-benefits/" target="_blank">Member
              benefits</a>
            <a class="no-text-decoration" href="https://vervesuper.com.au/about-us/" target="_blank">About us</a>
            <a class="no-text-decoration" href="https://vervesuper.com.au/for-women/" target="_blank">For women</a>
            <a class="no-text-decoration" href="http://vervesuper.com.au/resources" target="_blank">Resources</a>
          </div>

          <div class="col-4">
            <h5>Learn</h5>
            <a class="no-text-decoration" href="https://vervesuper.com.au/magazine/" target="_blank">Magazine</a>
            <a class="no-text-decoration" href="https://vervesuper.com.au/events/" target="_blank">Events</a>
            <a class="no-text-decoration" href="https://www.vervesuper.com.au/courses" target="_blank">Courses</a>
            <a class="no-text-decoration" href="https://vervesuper.com.au/moneyguides" target="_blank">Guides</a>
          </div>

          <div class="col-4">
            <h5>Resources</h5>
            <a class="no-text-decoration" href="https://vervesuper.com.au/faqs/" target="_blank">FAQs</a>
            <a class="no-text-decoration" href="https://vervesuper.com.au/forms-documents/" target="_blank">Documents &
              forms</a>
            <a class="no-text-decoration" href="https://vervesuper.com.au/employers/" target="_blank">Employers</a>
            <a class="no-text-decoration" href="https://vervesuper.com.au/insurance/" target="_blank">Insurance</a>
            <a class="no-text-decoration" href="https://vervesuper.com.au/documents/Verve-Super-PDS.pdf"
              target="_blank">PDS</a>
          </div>

        </div>
        <div class="row mt-3">
          <img src="https://vervassets.blob.core.windows.net/web/mp-footer-line.png" class="line">

          <div class="col-md-12">
            <p class="d-flex">
              <a class="no-text-decoration flex-fill mr-2" target="_blank" [href]="tenant.fundInformationLink">Fund
                Information</a>
              <a class="no-text-decoration flex-fill  mr-2" target="_blank" [href]="tenant.termsAndConditionsUrl">Terms
                & Conditions</a>
              <a class="no-text-decoration flex-fill" target="_blank" [href]="tenant.privacyPolicyUrl">Privacy
                Policy</a>
            </p>
          </div>

        </div>
        <div class="row mt-3">
          <img src="https://vervassets.blob.core.windows.net/web/mp-footer-line.png" class="line">

          <div class="col-md-12">
            <div class="d-flex align-items-center mb-4">
              <div class="d-flex flex-fill">
                <div class="mr-4">Fund ABN: {{tenant.fundABN}}</div>
                <div>USI: {{tenant.fundUSI}}</div>
              </div>
              <div class="d-block ">
                © 2023 VERVE SUPER
              </div>
            </div>


            <p class="disclosure1"> You should read the <a
                href="https://vervesuper.com.au/documents/Verve-Super-PDS.pdf" target="_blank">Product Disclosure
                Statement</a>, <a
                href="https://vervesuper.com.au/documents/Verve-Super-Additional-Information-Booklet-FINAL.pdf"
                target="_blank">Additional Information Booklet</a>,
              <a href="https://vervesuper.com.au/documents/Verve-Insurance-Guide.pdf" target="_blank">Insurance
                Guide</a>, <a href="https://vervesuper.com.au/documents/TMD.pdf" target="_blank">Target Market
                Determination</a> and <a href="https://vervesuper.com.au/documents/FInancial-Services-Guide.pdf" target="_parent"> Financial Services Guide</a> before making any financial
              decisions regarding Verve Super. Information provided is of a general nature only. It’s important to do
              your own research and consider things like fees, investment performance, insurance cover, your risk
              profile, and Verve’s alignment with your values when considering if Verve Super is appropriate for you.
              Consider getting in touch with a professional adviser if you need some help determining if Verve Super
              lines up with your financial needs. When considering financial returns, past performance is not indicative
              of future performance.
            </p>
          </div>

        </div>
      </div>
    </div>

  </div>
</footer>
