import { Subscription } from 'rxjs';

import { ActivatedRoute } from "@angular/router/router";
import { Action, select, Store } from '@ngrx/store';
import { commomState_ScreenWidth } from '../store/common/common.selectors';
import { AppState } from '../store/app.states';

export class ComponentBase {
  public screenWidth: number;

  public set sub(value: Subscription) {
    this.addSubscription(value);
  }
  private subscriptions: Subscription[] = [];

  constructor() {
    window.scrollTo(0, 0);
  }

  dispatch(store: Store<any>, action: Action)
  {
    setTimeout(() => store.dispatch(action), 0);
  }

  screenWidthChange(store: Store<AppState>, callback) {
    this.sub = store.pipe(select(commomState_ScreenWidth)).subscribe(x => {
      this.screenWidth = x;
      callback(x);
    });

  }
  addSubscription(subscription: Subscription) {
    this.subscriptions.push(subscription);
  }

  ngOnInitBase() {
  }

  getRouteParam(route: ActivatedRoute, name: string, callback, callbackNotFound = null) {
    const sub = route
      .params
      .subscribe(params => {
        const val = params[name];
        if (val) {
          callback(val);
        }
        else {
          if (callbackNotFound)
            callbackNotFound()
        }
      });
    this.addSubscription(sub);
  }

  ngOnDestroyBase() {
    this.subscriptions.forEach(x => {
      if (x) {
        x.unsubscribe();
      }
    });
  }

  getUrlParameterByName(name: string, url?: any) {
    if (!url) { url = window.location.href; }
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
    const results = regex.exec(url);
    if (!results) { return null; }
    if (!results[2]) { return ''; }
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }
}
