
import { IInsuranceQuestionnaireStartState } from './state';
import { createReducer, on, Action } from '@ngrx/store';
import {
  createFormGroupState, formGroupReducer,
  onNgrxForms, onNgrxFormsAction, setValue, SetValueAction, updateGroup, validate,
} from 'ngrx-forms';
import { InsuranceQuestionnaireModel, InsuranceQuestionnaireResultModel } from 'src/app/model/insurance-questionnaire.model';
import { required, requiredTrue } from 'ngrx-forms/validation';
import { ConfirmResponseAction, NextStepAction, PreviousStepAction, ResetFormAction, SubmitResponseAction, ValidateFormAction, ConfirmReadInsuranceRequestInsuranceGuideAction } from './actions';
import { Helper } from '@ifaa-components/ui-components';
import { ShowQuestionnaireResponseAction } from '../insurance-questionnaire/actions';
export const formName = 'insuranceQuestionnaireForm';
export const submittedFormName = 'insuranceQuestionnaireSubmittedForm';

var helper = new Helper();

export const state: IInsuranceQuestionnaireStartState = {
  form: createFormGroupState(formName, new InsuranceQuestionnaireModel()),
  confirmedTime: null,
  currentStep: 1,
  validated: false,
  totalSteps: 8,
  submitted: null,
};

export const validateEditMemberForm1 = updateGroup<InsuranceQuestionnaireModel>({
  gender: validate(required),
  isLegallyRecognisedAsGender: validate(required),
  legallyRecognisedGender:validate(required),
});

export const validateEditMemberForm2 = updateGroup<InsuranceQuestionnaireModel>({
  isCitizenOrPermanent: validate(required),
});

export const validateEditMemberForm3 = updateGroup<InsuranceQuestionnaireModel>({
  isPaidEmployment: validate(required),
});

export const validateEditMemberForm4 = updateGroup<InsuranceQuestionnaireModel>({
  employmentArrangement: validate(required),
});

export const validateEditMemberForm5 = updateGroup<InsuranceQuestionnaireModel>({
  isHazardousOccupation: validate(required),
});

export const validateEditMemberForm6 = updateGroup<InsuranceQuestionnaireModel>({
  occupationDescription: validate(required),
});

export const validateEditMemberForm7 = updateGroup<InsuranceQuestionnaireModel>({
  statementsApplyToYou: validate(required),
});

export const validateEditMemberForm8 = updateGroup<InsuranceQuestionnaireModel>({
  youAcknowledge: validate(required, requiredTrue),
});

function ValidateStep(state: IInsuranceQuestionnaireStartState, action) {
  let myForm = formGroupReducer(state.form, action);
  let validatedForm = null;

  if (state.currentStep == 1) {
    validatedForm = validateEditMemberForm1(myForm);
  }
  if (state.currentStep == 2) {
    validatedForm = validateEditMemberForm2(myForm);
  }
  if (state.currentStep == 3) {
    validatedForm = validateEditMemberForm3(myForm);
  }
  if (state.currentStep == 4) {
    validatedForm = validateEditMemberForm4(myForm);
  }
  if (state.currentStep == 5) {
    validatedForm = validateEditMemberForm5(myForm);
  }
  if (state.currentStep == 6) {
    validatedForm = validateEditMemberForm6(myForm);
  }
  if (state.currentStep == 7) {
    validatedForm = validateEditMemberForm7(myForm);
  }
  if (state.currentStep == 8) {
    validatedForm = validateEditMemberForm8(myForm);
  }

  if (validatedForm) {
    return {
      ...state,
      form: validatedForm,
      validated: true
    };
  }
  return state;
}

const reducer = createReducer(state,
  onNgrxForms(),
  onNgrxFormsAction(SetValueAction, (state, action) => {
    return ValidateStep(state, action);

  }),
  on(ResetFormAction, (state) => {
    return {
      ...state,
      form: createFormGroupState(formName, new InsuranceQuestionnaireModel()),
      confirmedTime: null,
      currentStep: 1,
      validated: false,
      totalSteps: 8,
      submitted: null
    };
  }),
  on(SubmitResponseAction, (state, { data }) => {
    let myForm = createFormGroupState(submittedFormName, Object.assign(new InsuranceQuestionnaireResultModel(), data));
    return {
      ...state,
      submitted: myForm,
    };
  }),
  on(ConfirmReadInsuranceRequestInsuranceGuideAction, (state, { confirmReadInsuranceRequestInsuranceGuide }) => {
    var clone = helper.clone(state.form.value) as InsuranceQuestionnaireModel;
    clone.confirmReadInsuranceRequestInsuranceGuide = confirmReadInsuranceRequestInsuranceGuide;
    return {
      ...state,
      form: setValue(clone)(state.form)
    };
  }),
  on(ShowQuestionnaireResponseAction, (state, { payload }) => {
    var clone = helper.clone(state.form.value)
    clone.ageNextBirthday = payload.ageNextBirthday;
    return {
      ...state,
      form: setValue(clone)(state.form)
    };
  }),
  on(ConfirmResponseAction, (state, { data }) => {
    var clone = helper.clone(state.submitted.value) as InsuranceQuestionnaireResultModel;
    clone.referenceNumber = data;
    return {
      ...state,
      submitted: setValue(clone)(state.submitted),
      confirmedTime: new Date()
    };
  }),
  on(ValidateFormAction, (state) => {
    var clone = helper.clone(state)
    return ValidateStep(clone, NextStepAction);

  }),
  on(NextStepAction, (state) => {
    if (state.currentStep == 2 && !state.form.value.isCitizenOrPermanent) {
      return {
        ...state,
        currentStep: -1,
        validated: false
      };
    }
    if (state.currentStep == 3 && !state.form.value.isPaidEmployment) {

      return {
        ...state,
        currentStep: 7,
        validated: false
      };
    }
    if (state.currentStep == 5 && state.form.value.isHazardousOccupation) {

      return {
        ...state,
        currentStep: -2,
        validated: false
      };
    }
    if (state.currentStep == 7 && state.form.value.statementsApplyToYou) {
      return {
        ...state,
        currentStep: -2,
        validated: false
      };
    }
    return {
      ...state,
      currentStep: state.currentStep + 1,
      validated: false
    };
  }),
  on(PreviousStepAction, (state) => {
    var clone = helper.clone(state.form.value)
    if (state.currentStep == -1) {
      return {
        ...state,
        currentStep: 2,
        validated: false
      };
    }
    if (state.currentStep == 7 && !state.form.value.isPaidEmployment) {
      return {
        ...state,
        currentStep: 3,
        validated: false
      };
    }
    if (state.currentStep == -2 && state.form.value.isHazardousOccupation) {

      return {
        ...state,
        currentStep: 5,
        validated: false
      };
    }
    if (state.currentStep == -2 && state.form.value.statementsApplyToYou) {
      return {
        ...state,
        currentStep: 7,
        validated: false
      };
    }
    if (state.submitted) {
      return {
        ...state,
        currentStep: 8,
        submitted: null,
        validated: false
      };
    }
    return {
      ...state,
      currentStep: state.currentStep - 1,
      validated: false,
      form: createFormGroupState(formName, clone)
    };
  }),

);

export function insuranceQuestionnaireStartReducer(state: any | undefined, action: Action) {
  return reducer(state, action);
}
