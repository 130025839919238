import { OkModel } from './../../../../model/ok.model';
import { createAction } from '@ngrx/store';
import { props } from '@ngrx/store';
import { MemberModel } from 'src/app/model/member.model';

export const RequestAction = createAction('[Edit Member View] request data by Id');

export const ResponseAction = createAction('[Edit Member View] response data by Id',
  props<{ payload: MemberModel }>());

export const SubmitRequestAction = createAction('[Edit Member View] save member drtails',
  props<{ payload: MemberModel }>());

export const SubmitResponseAction = createAction('[Edit Member View]  member update completed',
  props<{ payload: OkModel<string> }>());


  export const ResetAction = createAction('[Edit Member View]  reset');
