import { createReducer, on, Action } from '@ngrx/store';
import {
  createFormGroupState, onNgrxForms, updateGroup, validate, wrapReducerWithFormStateUpdate,
  onNgrxFormsAction, SetValueAction, setValue
} from 'ngrx-forms';
import { ISwitchInvestmentsState } from './state';
import { SwitchInvestmentModel } from '../../../../model/switch-investment.model';
import { ResponseOptionsAction, ResponseSwitchOptionAction } from './actions';

export const formName = 'switchInvestmentsEditForm';

export const state: ISwitchInvestmentsState = {
  form: createFormGroupState(formName, new SwitchInvestmentModel()),
  lastModifiedTime: null
};

export const validateForm = updateGroup<SwitchInvestmentModel>({

});

const reducer = createReducer(state,
  onNgrxForms(),
  onNgrxFormsAction(SetValueAction, (state, action) => {
    return state;
  }),

  on(ResponseOptionsAction, (state, { payload }) => {
    return {
      ...state,
      form: setValue(Object.assign(new SwitchInvestmentModel(), payload))(state.form),
    };
  }),
  on(ResponseSwitchOptionAction, (state, { payload }) => {
    return {
      ...state,
      lastModifiedTime: new Date()
    };
  }),

);

const reducerFormState = wrapReducerWithFormStateUpdate(
  reducer,
  s => s.form,
  validateForm,
);

export function switchInvestmentsReducer(state: any | undefined, action: Action) {
  return reducerFormState(state, action);
}
