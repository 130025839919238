// import { MemberNotesModel } from './../model/member-notes.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { BaseService } from './base.services';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';


@Injectable()
export class AccessYourSuperService extends BaseService {

  constructor(private http: HttpClient,
    public toastr: ToastrService,
    private store: Store<any>) {
    super(toastr);
  }

  getApplicationList(accountId: number): Observable<any> {
    return this.http.get(environment.apiUrl + `/member-portal/AccessYourSuper/account/${accountId}/retrieveAllApplications`, { headers: this.headers() });
  }

}
