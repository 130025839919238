import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import * as ActionsList from './actions';
import { map, switchMap } from 'rxjs/operators';
import { createEffect } from '@ngrx/effects';
import { ToastrService } from 'ngx-toastr';
import { LodgeClaimService } from '../../services/lodge-claim.service';

@Injectable()
export class LodgeClaimEffects {
  constructor(public toastr: ToastrService,
    private actions$: Actions,
    private service: LodgeClaimService  ) {
  }

  submit$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.SubmitRequestAction),
    switchMap((action: any) => this.service.submit(action.accountId, action.payload)),
    map((data: any) => {
      if (data.message)
        this.toastr.success(data.message);

      return ActionsList.SubmitResponseAction({ payload: data });
    })
  ));

  list$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.EmployerListRequestAction),
    switchMap((action: any) =>
      this.service.getEmployers(action.accountId)),
    map((data: any) => {
      return ActionsList.EmployerListResponseAction({ payload: data.data });
    })
  ));

  getData$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.MemberDataRequestAction),
    switchMap((action: any) => this.service.checkForMemberTfn(action.accountId)),
    map((data: any) => {
      return ActionsList.MemberDataResponseAction({ payload: data.data });
    })
  ));

}
