import { MemberPortalSharedState } from './../../store/shared.states';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ICashTransactionListState } from './state';
import { featureKey } from '../../store/shared.reducers';

export const memberPortalState = createFeatureSelector<MemberPortalSharedState>(featureKey);

export const cashTransactionList = createSelector(memberPortalState, (state) => state.cashTransactionListState);

export const cashTransactionList_List = createSelector(
  cashTransactionList,
  (state: ICashTransactionListState) => state.list
);

export const cashTransactionList_CurrentPage = createSelector(
  cashTransactionList,
  (state: ICashTransactionListState) => state.filter.value.currentPage
);

export const cashTransactionList_Filter = createSelector(
  cashTransactionList,
  (state: ICashTransactionListState) => state.filter
);

export const cashTransactionList_TransactionTypes = createSelector(
  cashTransactionList,
  (state: ICashTransactionListState) => state.transactionTypes
);

export const cashTransactionList_TransactionDescription = createSelector(
  cashTransactionList,
  (state: ICashTransactionListState) => state.transactionDescription
);

export const cashTransactionList_SelectedTypeName = createSelector(
  cashTransactionList,
  (state: ICashTransactionListState) => {
    var selected = state.transactionTypes.filter(x => x.key == state.filter.value.transactionType);
    if (selected.length > 0)
      return selected[0].value;
    return null;

  }

);

export const cashTransactionList_SelectedDescription = createSelector(
  cashTransactionList,
  (state: ICashTransactionListState) => {
    var selected = state.transactionDescription.filter(x => x.key == state.filter.value.transactionDescription);
    if (selected.length > 0)
      return selected[0].value;
    return null;

  }

);

export const cashTransactionList_ListTotal = createSelector(
  cashTransactionList,
  (state: ICashTransactionListState) => state.list.reduce((sum, item) => sum + item.amount, 0)
);
