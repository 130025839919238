import { VerveAcademyViewComponent } from './views/verve-academy/verve-academy-view.component';
import { NotLoggedInMasterComponent } from './layout/not-loggedin/not-loggedin.master';
import { FooterComponent } from './layout/footer/footer.master';
import { MainLayoutEffects } from './layout/main/effects';
import { HttpClientModule } from '@angular/common/http';
import { EffectsModule } from '@ngrx/effects';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { VerveRoutingModule } from './verve-routing.module';
import { featureKey, reducers } from './store/verve.reducers';
import { MainMasterComponent } from './layout/main/main.master';
import { MaterialModule } from 'src/app/app.material.module';
import { IfaaUiComponentsModule } from '@ifaa-components/ui-components';
import { MemberPortalSharedModule } from '../shared/shared.module';
import { TenantDashboardComponent } from './views/dashboard/dashboard.component';


@NgModule({
  declarations: [
    MainMasterComponent,
    FooterComponent,
    NotLoggedInMasterComponent,
    VerveAcademyViewComponent,
    TenantDashboardComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    VerveRoutingModule,
    HttpClientModule,
    MaterialModule,
    IfaaUiComponentsModule,
    StoreModule.forFeature(featureKey, reducers),
    EffectsModule.forFeature([MainLayoutEffects]),
    MemberPortalSharedModule,

  ],
  exports: [
    MainMasterComponent,
    NotLoggedInMasterComponent,
    TenantDashboardComponent
  ],
  providers: [
  ]
})
export class VervModule { }
