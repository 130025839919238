<div id="calculator-container">
  <div class="page-header">
    <app-toolbar-options icon="calculate">
      <div class="d-flex flex-column" slot="start">
        <div class="page-title">Calculator</div>

      </div>
    </app-toolbar-options>
  </div>
  <div class="page-content" appanimatecss>
    <div class="row ">
      <div class="col-12">
        <app-component-wrapper class="" icon="analytics" title="Details">
          <div class="d-flex flex-column h-100" slot="start">
            <img src="/assets/moneysmart.svg" class="moneysmart">
            <div class="description">
              <p>
                <strong>
                  Want to find out how your choices may affect your super over the long term?<br> The
                  Australian
                  Securities &amp; Investment Commission provides the following financial calculators:
                </strong>
                <br><br>
                <a class=""
                  href="https://moneysmart.gov.au/how-super-works/superannuation-calculator"
                  onclick="this.target='new'"><strong>Superannuation Calculator</strong></a> - helps to calculate how
                much super you might have when you retire and how fees may affect your final super balance.<br>
                <a class=""
                  href="https://moneysmart.gov.au/retirement-income/retirement-planner"
                  onclick="this.target='new'">
                  <strong>
                    Retirement planner
                  </strong>
                </a> - helps to calculate what income you're likely to get from super and the age pension when you
                retire; how contributions, investment options, fees and retirement age can affect your retirement
                income, and how working part-time or taking a break from work can affect your super balance.<br>
                <a class=""
                  href="https://moneysmart.gov.au/grow-your-super/super-contributions-optimiser"
                  onclick="this.target='new'"><strong>Super Contributions Optimiser</strong></a>- helps to calculate
                which type of super contributions will give your super the biggest boost.<br><br>
                <span [innerHTML]="'calculator_TrusteeDescription'|message|async">

                </span>
              </p>

            </div>
          </div>
        </app-component-wrapper>

      </div>
    </div>
    <div class="row ">
      <div class="col-sm-6">
        <app-component-wrapper class="calculator d-flex align-self-stretch w-100" icon="analytics"
          title="ASFA SuperGuru Retirement Tracker">
          <div class="d-flex flex-column h-100" slot="start">
            <div class="d-flex align-items-stretch flex-column  h-100">
              <p>Find out how much you need to spend to support the lifestyle you want in retirement.</p>
              <p>The ASFA Retirement Standard benchmarks the annual budget needed by Australians to fund either a
                comfortable
                or modest standard of living in the post-work years. It is updated quarterly to reflect inflation, and
                provides detailed budgets of what singles and couples would need to spend to support their chosen
                lifestyle.
              </p>
              <p>
                <strong>Comfortable or Modest?</strong>
              </p>
              <p>
                A <strong>comfortable </strong>retirement lifestyle enables an older, healthy retiree to be involved in
                a
                broad range of leisure and recreational activities and to have a good standard of living through the
                purchase
                of such things as: household goods, private health insurance, a reasonable car, good clothes, a range of
                electronic equipment, and domestic and occasionally international holiday travel.
              </p>
              <p>
                A <strong>modest </strong>retirement lifestyle is considered better than the Age Pension, but still only
                able
                to afford fairly basic activities.
              </p>
            </div>
            <div class="d-flex flex-row page-content-actions " slot="actions">
              <app-button class="  pr-1" title="Open calculator" icon="east"
                (onClick)="openUrl('http://www.superannuation.asn.au/ExternalFiles/rs/ASFA_RetirementStandard.html')">
              </app-button>

              <!-- <a class="btn btn-primary no-text-decoration w-100" target="_blank"
                href="http://www.superannuation.asn.au/ExternalFiles/rs/ASFA_RetirementStandard.html">Open calculator
              </a> -->
            </div>
          </div>
        </app-component-wrapper>

        <app-component-wrapper class="calculator d-flex align-self-stretch w-100" icon="analytics"
          title="Budget Planner">
          <div class="d-flex flex-column  h-100" slot="start">
            <div class="d-flex align-items-stretch  h-100  flex-column">
              <p class="estimated d-flex align-items-center">
                <mat-icon>timer</mat-icon>
                Estimated time: 20 mins
              </p>
              <p>This calculator enables you to:</p>
              <ul class="c-member-panel__list">
                <li>work out where your money is going</li>
                <li>create your own custom items & change currency symbol</li>
                <li>save your results online (you will need to 'sign up' to MoneySmart) or download your results to an
                  Excel spreadsheet
                </li>
                <li>simplify your budget by using our budget planner wizard</li>
              </ul>
              <!-- <p>
                For a quick and easy budgeting tool in community languages with audio, use our <a
                  href="https://www.moneysmart.gov.au/tools-and-resources/calculators-and-apps/simple-money-manager"
                  target="_blank">simple money manager</a>.
              </p>
              <p>
                For more information see <a
                  href="https://www.moneysmart.gov.au/tools-and-resources/calculators-and-apps/budget-planner#about"
                  target="_blank">further information</a> and <a
                  href="https://www.moneysmart.gov.au/tools-and-resources/calculators-and-apps/budget-planner#faq"
                  target="_blank">FAQs</a> below.
              </p> -->
            </div>
            <div class="d-flex flex-row page-content-actions" slot="actions">
              <app-button class="  pr-1" title="Open calculator" icon="east"
                (onClick)="openUrl('https://moneysmart.gov.au/budgeting/budget-planner#about')">
              </app-button>

              <!-- <a class="btn btn-primary no-text-decoration w-100" target="_blank"
                href="https://www.moneysmart.gov.au/tools-and-resources/calculators-and-apps/budget-planner">Open
                calculator
              </a> -->
            </div>
          </div>
        </app-component-wrapper>

        <app-component-wrapper class="calculator d-flex align-self-stretch w-100" icon="analytics"
          *ngIf="'VIEWS.Calculator.RetirementProjectionCalculator.show' | translate"
          title="Retirement Projection Calculator">
          <div class="d-flex flex-column  h-100" slot="start">
            <div class="d-flex align-items-stretch  h-100 flex-column">
              <p class="estimated d-flex align-items-center">
                <mat-icon>timer</mat-icon>
                Estimated time: 5 mins
              </p>
              <p>This calculator helps you work out:</p>
              <ul class="c-member-panel__list">
                <li>what income you are likely to have from super and the age pension when you retire</li>
                <li>how contributions, investment options, fees and retirement age affect your retirement income from
                  super
                </li>
              </ul>
              <p>
                For detailed information see <a
                  href="https://www.moneysmart.gov.au/tools-and-resources/calculators-and-apps/retirement-planner#about"
                  target="_blank">disclaimers &amp; assumptions</a> and <a
                  href="https://www.moneysmart.gov.au/tools-and-resources/calculators-and-apps/retirement-planner#faq"
                  target="_blank">FAQs</a> below.
              </p>
            </div>
            <div class="d-flex flex-row page-content-actions" slot="actions">
              <app-button class="  pr-1" title="Open calculator" icon="east"
                (onClick)="openUrl('https://www.moneysmart.gov.au/tools-and-resources/calculators-and-apps/retirement-planner')">
              </app-button>
              <!-- <a class="btn btn-primary no-text-decoration w-100" target="_blank"
                href="https://www.moneysmart.gov.au/tools-and-resources/calculators-and-apps/retirement-planner">Open
                calculator
              </a> -->
            </div>
          </div>
        </app-component-wrapper>

      </div>

      <div class="col-sm-6">
        <app-component-wrapper class="calculator d-flex align-self-stretch w-100" icon="analytics"
          title="Federal Government Co-contribution Calculator">
          <div class="d-flex flex-column  h-100" slot="start">
            <div class="d-flex align-items-stretch  h-100 flex-column">
              <p class="estimated d-flex align-items-center">
                <mat-icon>timer</mat-icon>
                Estimated time: less than 1 minute
              </p>

              <p>This calculator helps you find out:</p>
              <ul class="c-member-panel__list">
                <li>if you are eligible for a co-contribution from the Government</li>
                <li>how much you can get if you are eligible</li>
              </ul>
              <!-- <p>
                For more details see <a
                  href="https://www.moneysmart.gov.au/tools-and-resources/calculators-and-apps/super-co-contribution-calculator#about"
                  target="_blank">further information</a> and <a
                  href="https://www.moneysmart.gov.au/tools-and-resources/calculators-and-apps/super-co-contribution-calculator#faq"
                  target="_blank">FAQs</a> below.
              </p> -->
            </div>
            <div class="d-flex flex-row page-content-actions" slot="actions">
              <!-- <a class="btn btn-primary no-text-decoration w-100" target="_blank"
                href="https://www.moneysmart.gov.au/tools-and-resources/calculators-and-apps/super-co-contribution-calculator">Open
                calculator
              </a> -->
              <app-button class="  pr-1" title="Open calculator" icon="east"
                (onClick)="openUrl('https://www.ato.gov.au/calculators-and-tools/super-co-contribution-calculator')">
              </app-button>
            </div>
          </div>
        </app-component-wrapper>


        <app-component-wrapper class="calculator d-flex align-self-stretch w-100" icon="analytics"
          title="Income Tax Calculator">
          <div class="d-flex flex-column  h-100" slot="start">
            <div class="d-flex align-items-stretch  h-100 flex-column">
              <p class="estimated d-flex align-items-center">
                <mat-icon>timer</mat-icon>
                Estimated time: less than 1 minute
              </p>

              <p>Helps you work out:</p>
              <ul class="c-member-panel__list">
                <li>how much Australian income tax you should be paying</li>
                <li>what your take home salary will be when tax and the Medicare levy are removed</li>
                <li>your marginal tax rate</li>
              </ul>
              <p>
                This calculator can also be used as an Australian tax return calculator. For more information see <a
                  href="https://www.moneysmart.gov.au/tools-and-resources/calculators-and-apps/income-tax-calculator#about"
                  target="_blank">assumptions &amp; further information</a>.
              </p>
            </div>
            <div class="d-flex flex-row page-content-actions" slot="actions">
              <app-button class="  pr-1" title="Open calculator" icon="east"
                (onClick)="openUrl('https://www.moneysmart.gov.au/tools-and-resources/calculators-and-apps/income-tax-calculator')">
              </app-button>
              <!-- <a class="btn btn-primary no-text-decoration w-100" target="_blank"
                href="https://www.moneysmart.gov.au/tools-and-resources/calculators-and-apps/income-tax-calculator">Open
                calculator
              </a> -->
            </div>
          </div>
        </app-component-wrapper>


        <app-component-wrapper class="calculator d-flex align-self-stretch w-100" icon="analytics"
          *ngIf="'VIEWS.Calculator.SuperannuationCalculator.show' | translate" title="Superannuation Calculator">
          <div class="d-flex flex-column  h-100" slot="start">
            <div class="d-flex align-items-stretch  h-100 flex-column">
              <p class="estimated d-flex align-items-center">
                <mat-icon>timer</mat-icon>
                Estimated time: 5 mins
              </p>

              <p>This calculator helps you work out:</p>
              <ul class="c-member-panel__list">
                <li>how much super you'll have when you retire</li>
                <li>how fees affect your final payout</li>
              </ul>
              <p>
                For detailed information see <a
                  href="https://www.moneysmart.gov.au/tools-and-resources/calculators-and-apps/superannuation-calculator#about"
                  target="_blank">disclaimers &amp; assumptions</a> below.
              </p>
            </div>
            <div class="d-flex flex-row page-content-actions" slot="actions">
              <app-button class="  pr-1" title="Open calculator" icon="east"
                (onClick)="openUrl('https://www.moneysmart.gov.au/tools-and-resources/calculators-and-apps/superannuation-calculator')">
              </app-button>
              <!-- <a class="btn btn-primary no-text-decoration w-100" target="_blank"
                href="https://www.moneysmart.gov.au/tools-and-resources/calculators-and-apps/superannuation-calculator">Open
                calculator
              </a> -->
            </div>
          </div>
        </app-component-wrapper>

        <app-component-wrapper class="calculator d-flex align-self-stretch w-100" icon="analytics"
          *ngIf="'VIEWS.Calculator.SuperContributionOptimiser.show' | translate" title="Super Contributions Optimiser">
          <div class="d-flex flex-column  h-100" slot="start">
            <div class="d-flex align-items-stretch  h-100 flex-column">
              <p class="estimated d-flex align-items-center">
                <mat-icon>timer</mat-icon>
                Estimated time: 5 mins
              </p>

              <p>This calculator helps you work out:</p>
              <ul class="c-member-panel__list">
                <li>which type of super contribution will give your super the biggest boost</li>
                <li>how to make super contributions&nbsp;</li>
              </ul>
              <p>
                For more information see <a
                  href="https://www.moneysmart.gov.au/tools-and-resources/calculators-and-apps/super-contributions-optimiser#about"
                  target="_blank">disclaimers &amp; assumptions</a> and <a
                  href="https://www.moneysmart.gov.au/tools-and-resources/calculators-and-apps/super-contributions-optimiser#faq"
                  target="_blank">FAQs</a> below.
              </p>
            </div>
            <div class="d-flex flex-row page-content-actions" slot="actions">
              <app-button class="  pr-1" title="Open calculator" icon="east"
                (onClick)="openUrl('https://www.moneysmart.gov.au/tools-and-resources/calculators-and-apps/super-contributions-optimiser')">
              </app-button>
              <!-- <a class="btn btn-primary no-text-decoration w-100" target="_blank"
                href="https://www.moneysmart.gov.au/tools-and-resources/calculators-and-apps/super-contributions-optimiser">Open
                calculator
              </a> -->
            </div>
          </div>
        </app-component-wrapper>
      </div>
    </div>
  </div>
</div>
