import { MemberPortalSharedState } from './../../store/shared.states';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { featureKey } from '../../store/shared.reducers';

import { IMemberEditState } from './state';

export const memberPortalState = createFeatureSelector<MemberPortalSharedState>(featureKey);

export const editMember = createSelector(memberPortalState, (state) => state.memberEditState);

export const editMember_Form = createSelector(
  editMember,
  (state: IMemberEditState) => state.form
);

export const editMember_LastModified = createSelector(
  editMember,
  (state: IMemberEditState) => state.lastModifiedTime
);
