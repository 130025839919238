<div id="login-container" class="d-flex ">
  <div class="d-flex w-100">
    <div class="left-side">
      <img src="{{'fundLogo' | tenantconfig | async }}" style="height: 70px;width: auto; margin: 20px;">

    </div>
    <div class="right-side flex-fill d-flex align-items-center">
      <form [ngrxFormState]="form" (ngSubmit)="submit(form.value)" *ngIf="form$ | async; let form"
        class=" d-flex  w-100 justify-content-center" role="presentation">
        <div class="backdrop"></div>
        <div appanimatecss class="wrapper">
          <div class="p-3 ">
            <img src="{{'fundLogo' | tenantconfig | async }}" class="logo mobile">
            <div class="page-title">

            </div>
            <div class=" d-flex flex-row w-100">

              <div class="flex-fill d-flex flex-column w-100">

                <h2 class="card-title mb-1 ">Member Portal</h2>
                <h4 class="card-subtitle mb-4 ">Reset your password</h4>

                <div class="alert alert-warning" *ngIf="isUnavailable$ | async">
                  {{ isUnavailableMessage$ | async}}
                </div>

                <div class="card-body flex-fill d-flex align-items-center" *ngIf="!(isUnavailable$ | async)">

                  <div class="main-container w-100">
                    <div class="content animated fadeInUpSmall">
                      <div class="row">
                        <div class="col col-md-12">
                          <app-entry class="entry-dark-bg" [title]="'forgotPasswordScreen_UsernameTitle'|message|async" autofill="true"
                            [control]="form.controls.username" icon="account_box">
                          </app-entry>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col col-md-12">
                          <app-entry class="entry-dark-bg" title="New password" autofill="true"
                            [control]="form.controls.password" entrytype="password" icon="lock">
                          </app-entry>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
                <div class="card-bottom mt-3" *ngIf="!(isUnavailable$ | async)">
                  <app-button loading icon="login" class="w-100" type="submit" title="{{ 'SUBMIT' | translate }}"
                    [isDisabled]="form.isInvalid">
                  </app-button>
                </div>
              </div>
            </div>

          </div>
        </div>
      </form>
    </div>
  </div>



</div>
