import { InjectionToken } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';
import { beneficiariesViewReducer } from '../components/beneficiaries-view/reducer';
import { beneficiariesEditReducer } from '../components/beneficiaries-edit/reducer';
import { memberViewReducer } from '../components/member-view/reducer';
import { memberViewOverviewReducer } from '../components/member-view-overview/reducer';
import { memberEditReducer } from '../components/member-edit/reducer';
import { cashTransactionListReducer } from '../components/cash-transactions/reducer';
import { memberAccountDropdownReducer } from '../components/member-account-dropdown/reducer'
import { historicalChartViewReducer } from '../components/historical-chart/reducer'
import { insuranceQuestionnaireReducer } from '../components/insurance-questionnaire/reducer'
import { insuranceQuestionnaireStartReducer } from '../components/insurance-questionnaire-start/reducer'
import { insuranceDetailsReducer } from '../components/insurance-details/reducer'
import { rollInEditReducer } from '../components/rollin-edit/reducer'
import { switchInvestmentsReducer } from '../components/switch-investments-edit/reducer'
import { contributionsLimitsReducer } from '../components/contributions-limits/reducer'
import { superannuationProviderSearchReducer } from '../components/superannuation-provider-search/reducer'
import { personalContributionEditReducer } from '../components/personal-contribution-edit/reducer'
import { consolidateSuperReducer } from '../components/consolidate-super/reducer'
import { unitPriceViewReducer } from '../components/unit-prices-view/reducer'
import { documentViewerListReducer } from '../components/document-viewer/reducer'
import { notificationDropdownReducer } from '../components/notification-dropdown/reducer'
import { MemberPortalSharedState } from './shared.states';
import { accountUnderlyingViewReducer } from '../components/account-underlying-view/reducer';
import { bpayDetailsViewReducer } from '../components/bpay-details/reducer';
import { memberPensionViewReducer } from '../components/member-pension-view/reducer';
import { investmentEditReducer } from '../components/investment-edit/reducer';
import { accessYourSuperStartComponentReducer } from '../components/access-your-super-start/reducer';
import { insurancePreQuestionnaireReducer } from '../components/insurance-pre-questionnaire/reducer';
import { chatViewReducer } from '../components/chat/reducer';

export const featureKey = 'member-portal-shared';

export const reducers = new InjectionToken<ActionReducerMap<MemberPortalSharedState>>(featureKey, {
  factory: () => ({
    memberViewState: memberViewReducer,
    memberPensionViewState: memberPensionViewReducer,
    memberViewOverviewState: memberViewOverviewReducer,
    beneficiariesViewState: beneficiariesViewReducer,
    bpayDetailsViewState: bpayDetailsViewReducer,
    beneficiariesEditState: beneficiariesEditReducer,
    accountUnderlyingViewState: accountUnderlyingViewReducer,
    memberEditState: memberEditReducer,
    cashTransactionListState: cashTransactionListReducer,
    memberAccountDropdownState: memberAccountDropdownReducer,
    historicalChartViewState: historicalChartViewReducer,
    insuranceQuestionnaireState: insuranceQuestionnaireReducer,
    insurancePreQuestionnaireState : insurancePreQuestionnaireReducer,
    insuranceQuestionnaireStartState: insuranceQuestionnaireStartReducer,
    insuranceDetailsState: insuranceDetailsReducer,
    switchInvestmentsState: switchInvestmentsReducer,
    rollInEditState: rollInEditReducer,
    superannuationProviderSearchState: superannuationProviderSearchReducer,
    contributionsLimitsState: contributionsLimitsReducer,
    personalContributionEditState: personalContributionEditReducer,
    consolidateSuperState: consolidateSuperReducer,
    unitPriceViewState: unitPriceViewReducer,
    documentViewerListState: documentViewerListReducer,
    notificationDropdownState: notificationDropdownReducer,
    investmentEditState: investmentEditReducer,
    accessYourSuperStartComponentState: accessYourSuperStartComponentReducer,
    chatViewState: chatViewReducer
  })
});
