import { MemberModel } from './../../../../model/member.model';
import { FormGroupState } from 'ngrx-forms';

export interface IInvestmentEditState {
  form: FormGroupState<CurrentInvestmentModel>;
  lastModifiedTime: Date;
  currentStep: number;
  steps: number[];
  currentInfo: CurrentInvestmentModel;
  options: CurrentInvestmentModel;
}


export class InvestmentModel {
  memberNumber: string = '';
  firstName: string = '';
  lastName: string = '';
  secondName: string = '';
  taxFileNumber: string = '';
}

export class CurrentInvestmentModel {
  current: InvestmentItemModel[] = [];
  future: InvestmentItemModel[] = [];
  hasPendingCurrentOptions: boolean = false;
}

export class InvestmentItemModel {
  optionId: number = 0;
  option: string = '';
  percentage: number = 0;
  amount: number = 0;
}
