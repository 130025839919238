import { environment as base } from "./environment.cirt";

export const environment = {
  ...base,
  apiUrl: '#{apiUrl}#',
  production: true,
  bugsnagEnv:'#{bugsnagEnv}#',
  greenIdEnv:'prod',
  greenIdCode:'Xrv-cUK-rUQ-Ab5',
};
