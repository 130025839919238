import { KeyValueModel } from '@ifaa-components/ui-components'
import { FormGroupState } from 'ngrx-forms';
import { TransactionDetailModel } from 'src/app/model/transaction-detail.model';


export interface ICashTransactionListState {
  list: TransactionDetailModel[];
  transactionTypes: KeyValueModel[];
  transactionDescription: KeyValueModel[];
  filter: FormGroupState<CashTransactionFilter>;
}

export class CashTransactionFilter {
  from: string = '';
  to: string = '';
  transactionType: number = 0;
  transactionDescription: string = '';
  currentPage: number = 0;
}
