import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuardService } from "src/app/route-guard/auth-guard.service";
import { MainMasterComponent } from "./layout/main/main.master";
import { EmploymentHistoryViewComponent } from "./views/employment-history/employment-history-view.component";
import { TenantLodgeClaimComponent } from "./views/lodge-claim/lodge-claim.component";

const smrtRoutes: Routes = [
  {
    path: 'lodge-claim',
    component: MainMasterComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        outlet: 'master',
        path: '',
        component: TenantLodgeClaimComponent
      }
    ]
  },
  {
    path: 'employment-history',
    component: MainMasterComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        outlet: 'master',
        path: '',
        component: EmploymentHistoryViewComponent
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(smrtRoutes)],
  exports: [RouterModule]
})
export class SMRTRoutingModule { }
