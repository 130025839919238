import { FormGroupState } from 'ngrx-forms';
import { AccessYourSuperApplicationsListModel } from 'src/app/model/access-your-super-applications.model';

export interface IAccessYourSuperStartComponentState {
  model: AccessYourSuperApplicationsListModel;
  filter: FormGroupState<AccessYourSuperFilterModel>;
}

export class AccessYourSuperFilterModel {
  activeOptionButton: string = "Draft";
}