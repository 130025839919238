<footer id="footer-layout-container" class="mt-4 " *ngIf="tenantConfig$ | async; let tenant">
  <div class="container p-4">
    <div class="d-flex flex-row main">
      <div class="left-side d-flex align-items-center">
        <div style="">
          <div class="d-flex flex-column monday align-items-start">
            <div>Make</div>
            <div>mondays matter.</div>
          </div>
          <img src="https://sharedassetstorage.blob.core.windows.net/web/SMRT/smartMonday_logo_white.png"
            routerLink="/dashboard" class="logo">
          <a class="no-text-decoration mt-4" href="tel:{{tenant.phoneNumber}}">{{tenant.phoneNumber}}</a>
          <a class="no-text-decoration" href="mailto:{{tenant.email}}">{{tenant.email}}</a>
          <!-- <div class="social d-flex flex-row">
            <a>
              <img src="https://vervassets.blob.core.windows.net/web/mp-facebook.png">
            </a>
            <a>
              <img src="https://vervassets.blob.core.windows.net/web/mp-linkedin.png">
            </a>
            <a>
              <img src="https://vervassets.blob.core.windows.net/web/mp-twitter.png">
            </a>
            <a>
              <img src="https://vervassets.blob.core.windows.net/web/mp-instagram.png">
            </a>
            <a>
              <img src="https://vervassets.blob.core.windows.net/web/mp-youtube.png">
            </a>

          </div> -->
        </div>
      </div>
      <div class="flex-fill links">
        <div class="d-flex flex-row">
          <div class="d-flex flex-fill flex-column">
            <h4>Important</h4>
            <a class="">Governance</a>
            <a class="">Legal</a>
            <a class="">Privacy Policy & Cookies</a>
            <a class="">ETSL Privacy Policy</a>
          </div>
          <div class="d-flex flex-fill flex-column">
            <h4>Useful links</h4>
            <a class="">News</a>
            <a class="">smartMonday Lifecycle</a>
            <a class="">Mysuper dashboard</a>
            <a class="">Site Feedback</a>
          </div>
          <div class="d-flex flex-fill flex-column">
            <h4>About us</h4>
            <a class="">About smartMonday</a>

          </div>
        </div>
        <!-- <p>CIRT (QLD) PTY LTD ABN  49 011 050 329 Trustee of Contracting Industry Redundancy Trust (CIRT) ABN 18 414 343
          407</p>
        <div class="d-flex">
          <div class="d-flex align-item-center mr-4">
            <mat-icon class="mr-2">phone</mat-icon> {{tenant.phoneNumber}}
          </div>
          <div class="d-flex align-item-center">
            <mat-icon class="mr-2">email</mat-icon> enquiries@cirt.com.au
          </div>
        </div> -->
        <!-- <br />

        Postal Address: GPO Box 701, Brisbane QLD 4001<br />

        Street Address: Level 1, East Tower, 410 Ann Street, Brisbane QLD 4000
        <p class="mt-3">
          <a href="https://www.cirt.com.au/index.php/privacy-policy-2/">Privacy policy</a>
        </p>
        <div class="partners d-flex flex-row">
          <a>
            <img class="ml-0" src="https://sharedassetstorage.blob.core.windows.net/web/CIRT/etu.png">
          </a>
          <a>
            <img src="https://sharedassetstorage.blob.core.windows.net/web/CIRT/MEA_CMYK.png">
          </a>
          <a>
            <img src="https://sharedassetstorage.blob.core.windows.net/web/CIRT/Mates_Logo.png">
          </a>
        </div> -->
      </div>
    </div>

  </div>
  <div class="container disclaimer d-flex ">
    <div class="left-side d-flex align-items-start imgs">
      <img src="https://sharedassetstorage.blob.core.windows.net/web/SMRT/SR2021_HiRes_MySuper-Platinum_1.jpg">
      <img src="https://sharedassetstorage.blob.core.windows.net/web/SMRT/heron-quality-2018.png">
    </div>
    <div class="flex-fill text">
      <p>
        The information on this website is general in nature. Before deciding whether a particular product is right for
        your personal needs and objectives, please read and consider the relevant Product Disclosure Statement which is
        available on the website or by calling us. Contact us about the intra-fund advice services you can access
        through
        your membership. The Target Market Determinations for smartMonday products are available at
        smartmonday.com.au/Governance. smartMonday and the Trustee take no responsibility for you acting on the
        information provided. Any decision that you make is at your own risk.
      </p>
      <p>
        smartMonday is a registered trading name of smartMonday Solutions Limited ABN 48 002 288 646 AFSL 236667, the
        sponsor of the Smart Future Trust ABN 68 964 712 340 (the fund). The trustee of the fund is Equity Trustees
        Superannuation Limited ABN 50 055 641 757 AFSL 229757 RSEL 0001458. smartMonday products are part of the fund.
        The
        information on this website has been prepared by smartMonday on behalf of the trustee.
      </p>
      <p>
        smartMonday and/or the trustee are either the owner or licensed user of the copyright in all material contained
        on
        this website. Where a third party source has been relied on, smartMonday and/or the trustee have attributed
        credit
        for that information. Except to the extent necessary for personal use, you are not permitted to reproduce,
        adapt,
        upload, link, frame, broadcast, transmit, or distribute any material on this website without the permission of
        smartMonday or the trustee, or as permitted under law.
      </p>
      <p>
        While every care has been taken in preparing this website, neither smartMonday, nor the trustee, accepts
        responsibility for, and will not be bound by, any information contained in this website that is contrary to the
        Trust Deed and Rules. smartMonday and the trustee will not be liable for, or in connection with any loss or
        damage
        arising from any inaccuracies, errors or omissions in information made available through this website.
      </p>
      <p>
        © 2022 smartMonday Solutions Limited - Financial Services Guide
      </p>
    </div>
  </div>
</footer>
