import { createReducer, on, Action } from '@ngrx/store';
import {
  createFormGroupState, onNgrxForms, updateGroup, validate, wrapReducerWithFormStateUpdate,
  onNgrxFormsAction, SetValueAction, setValue
} from 'ngrx-forms';
import { required, email, greaterThan, requiredTrue } from 'ngrx-forms/validation';
import { IRollInEditState } from './state';
import { RollInEditModel } from 'src/app/model/rollin-edit.model';
import { SelectedProviderAction, SubmitResponseAction, ResetAction, ConfirmBackAction, ContinueAction } from './actions';
import { Helper } from '@ifaa-components/ui-components';

export const formName = 'rollinEditForm';
var helper = new Helper();

export const state: IRollInEditState = {
  form: createFormGroupState(formName, new RollInEditModel()),
  lastModifiedTime: null
};

export const validateEditMemberForm = updateGroup<RollInEditModel>({
  memberNumber: validate(required),
  amountToTransferOption: validate(required),
  providerId: validate(required, greaterThan(0)),
});

const reducer = createReducer(state,
  onNgrxForms(),
  onNgrxFormsAction(SetValueAction, (state, action) => {
    // if (action.controlId.endsWith('amountToTransfer')) {
    //   var clone = helper.clone(state.form.value);
    //   clone.amountToTransfer = parseFloat(action.value.toString());
    //   return {
    //     ...state,
    //     form: setValue(clone)(state.form),
    //   };
    // }
    return state;
  }),

  on(ResetAction, (state) => {
    return {
      ...state,
      form: createFormGroupState(formName, new RollInEditModel()),
      lastModifiedTime: null
    };
  }),
  on(ConfirmBackAction, (state) => {
    var clone = helper.clone(state.form.value);
    clone.step = 'start';

    return {
      ...state,
      form: createFormGroupState(formName, clone),
    };
  }),

  on(SelectedProviderAction, (state, { payload }) => {
    var clone = helper.clone(state.form.value);
    clone.providerId = payload;

    return {
      ...state,
      form: setValue(clone)(state.form),
    };
  }),
  on(ContinueAction, (state) => {
    var clone = helper.clone(state.form.value);
    clone.step = 'confirm';

    return {
      ...state,
      form: createFormGroupState(formName, clone),
    };
  }),
  on(SubmitResponseAction, (state) => {
    var clone = helper.clone(state.form.value);
    clone.step = 'done';

    return {
      ...state,
      form: createFormGroupState(formName, clone),
    };
  }),

);

const reducerFormState = wrapReducerWithFormStateUpdate(
  reducer,
  s => s.form,
  validateEditMemberForm,
);

export function rollInEditReducer(state: any | undefined, action: Action) {
  return reducerFormState(state, action);
}
