<div id="member-view-container">

  <div *ngIf="data$ | async; let data" class="d-flex flex-wrap">
   
    <app-readonly title="Communication Preferences" [value]="data.communicationPreferenceDescription"
      featuretoggle="isMemberCommunicationPreferenceEditable" class="{{itemclass}} p-1 flex-fill"></app-readonly>
    <app-readonly *ngIf="(loggedIn$ | async); let loggedin" title="Member Number" [value]="loggedin.memberNumber"
      class="{{itemclass}} p-1 flex-fill"></app-readonly>
    <app-readonly title="First Name" [value]="data.firstName" class="{{itemclass}} p-1 flex-fill"></app-readonly>
    <app-readonly title="Middle Name" [value]="data.secondName" class="{{itemclass}} p-1 flex-fill"></app-readonly>
    <app-readonly title="Last Name" [value]="data.lastName" class="{{itemclass}} p-1 flex-fill"></app-readonly>
    <app-readonly title="Tax File Number" [value]="data.taxFileNumber" featuretoggle="displayMemberTFN"
      class="{{itemclass}} p-1 flex-fill"></app-readonly>
    <app-readonly title="Email Address" [value]="data.email" class="{{itemclass}} p-1 flex-fill"></app-readonly>
    <app-readonly class="w-100" title="Postal Address" [value]="helper.formatAddress(data.postalAddress)"
      class="{{itemclass}} p-1 flex-fill">
    </app-readonly>
    <app-readonly class="w-100" title="Residential Address" [value]="helper.formatAddress(data.residentialAddress)"
      class="{{itemclass}} p-1 flex-fill">
    </app-readonly>
    <app-readonly title="Mobile" [value]="data.mobile" class="{{itemclass}} p-1 flex-fill"></app-readonly>
    <app-readonly title="Home Phone" [value]="data.homePhone" class="{{itemclass}} p-1 flex-fill"></app-readonly>
    <app-readonly title="Occupation" [value]="data.occupation" featuretoggle="displayMemberOccupation" class="{{itemclass}} p-1 flex-fill w-100"></app-readonly>

  </div>
</div>
