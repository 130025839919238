import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { BaseService } from './base.services';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
// import { UserModel } from '../model/user.model';
// import { ForgotPasswordModel } from '../model/forgot-password.model';
// import { ChangePasswordModel } from '../model/change-password.model';

@Injectable()
export class UnderlyingInvestmentService extends BaseService {

  constructor(private http: HttpClient,
    public toastr: ToastrService,
    private store: Store<any>) {
    super(toastr);
  }

  getUnderlyingInvestment(accountId: number): Observable<any> {
    // const params = new HttpParams()
    //   .set('accountId', accountId.toString());
    return this.http.get(environment.apiUrl + `/member-portal/account-underlying/account/${accountId}/underlyinginvestment`, { headers: this.headers() });
  }

}
