<div id="dashboard-container">
    <div class="page-header">
      <app-toolbar-options icon="verified_user">
        <div class="d-flex flex-column" slot="start">
          <div class="page-title">Access Your Super</div>
  
        </div>
      </app-toolbar-options>
    </div>
    <div class="page-content" appanimatecss>
  
      <app-component-wrapper  icon="savings" title="Your benefit withdrawal requests">
        <div class="d-flex flex-column" slot="start">
            <app-access-your-super-start></app-access-your-super-start>
        </div>
      </app-component-wrapper>
  
  
  
    </div>
  </div>
  