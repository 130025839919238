<div id="main-layout-container" class="d-flex flex-column min-h-100 smrt-theme" screen-width>

  <!-- <mat-drawer-container class="flex-fill d-flex" autosize>
    <mat-drawer #drawer class="sidenav" [mode]="menuMode" [opened]="menuOpened" [openedChange]="openedChange()">
      <nav class="navbar-primary" (swipeleft)="onSwipeToggle($event)">
        <img src="{{'fundLogo' | tenantconfig | async }}" routerLink="/dashboard" class="logo">
        <div class="welcome">
          <div class="welcome-text">Welcome,</div>
          <h1>{{(user$ | async).firstName}}</h1>
        </div>

        <ul class="navbar-primary-menu">
          <li>

            <div *ngFor="let item of menuList$ | async">

              <a class="btn menu-item d-flex align-items-center" [class.active]="isMenuExpanded(item)"
                *ngIf="checkFeatureToggle(item.featureFlag)" [routerLink]="item.link"
                [attr.target]="item.target ? item.target : null" (click)="onItemSelectedClick(item)"
                data-toggle="collapse" aria-expanded="false">
                <mat-icon *ngIf="item.icon">{{item.icon}}</mat-icon><span class="nav-label"
                  style="white-space: pre;">{{item.name}}</span>
                <mat-icon *ngIf="item.children.length > 0" class="item-arrow">expand_more</mat-icon>
              </a>
              <div class="collapse sub-menu" [class.show]="isMenuExpanded(item)" id="sub-menu-{{item.id}}"
                *ngIf="item.children.length > 0">
                <ul class="" style="background-color: #fafafa;">
                  <li *ngFor="let child of item.children">
                    <a *ngIf="child.link?.startsWith('/') && checkFeatureToggle(child.featureFlag)" class="btn menu-item sub-menu-item d-flex align-items-center"
                      [routerLink]="child.link" [fragment]="child.fragment">
                      <mat-icon *ngIf="child.icon">{{child.icon}}</mat-icon>
                      <div class="nav-label">{{child.name}}</div>
                    </a>
                    <a *ngIf="child.link?.startsWith('http') && checkFeatureToggle(child.featureFlag)"
                      class="btn menu-item sub-menu-item d-flex align-items-center" [href]="child.link"
                      [attr.target]="child.target ? child.target : null">
                      <mat-icon *ngIf="child.icon">{{child.icon}}</mat-icon>
                      <div class="nav-label">{{child.name}}</div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </nav>

    </mat-drawer>
    <mat-drawer-content class="d-flex flex-fill full overflow-x-hidden flex-column main"> -->
  <nav class="top navbar navbar-expand-lg " [ngClass]="{'animate__animated animate__fadeInDown': !(animated$ | async)}">
    <mat-progress-bar class="animate__animated animate__bounceIn" mode="indeterminate" *ngIf="isLoading$ | async">
    </mat-progress-bar>
    <div class="container">
      <div class="navbar-brand logo flex-fill">
        <!-- <button class="btn btn-link toggle-menu no-text-decoration" (click)="drawer.toggle()" *ngIf="screenWidth<=992">
          <mat-icon>
            menu_open
          </mat-icon>
        </button> -->
        <img src="https://sharedassetstorage.blob.core.windows.net/web/SMRT/smartMonday_logo_white.png" class="logo"
          style="height: 50px;width: auto;">
      </div>


      <div class="{{screenWidth>=992?'form-inline float-right':'d-flex flex-row w-100'}} ">
        <!-- <app-notification-dropdown></app-notification-dropdown> -->
        <app-member-account-dropdown class="flex-fill"></app-member-account-dropdown>
        <app-user-top-dropdown></app-user-top-dropdown>
      </div>
    </div>


  </nav>
  <nav class="menu navbar navbar-expand-lg" [ngClass]="{'animate__animated animate__fadeInDown': !(animated$ | async)}">
    <div class="container" *ngIf="screenWidth>=992">
      <ng-container *ngTemplateOutlet="templateMenu">
      </ng-container>
    </div>
  </nav>

  <div class="main-content flex-fill d-flex flex-column w-100">

    <div class="navbar-global-wrapper">
      <nav class="navbar navbar-inverse navbar-global navbar-fixed-top">
        <div class="container">
          <div class="navbar-header d-flex flex-row w-100">


          </div>

        </div>
      </nav>
    </div>
    <main class="container d-flex flex-fill" (swiperight)="onSwipeToggle($event)">
      <app-scroll-up></app-scroll-up>

      <router-outlet name="master"></router-outlet>

    </main>
    <app-footer></app-footer>
    <nav class="navbar-secondary toolbar-bottom d-flex align-items-center flex-row" *ngIf="screenWidth<992"
      appanimatecss="animate__fadeInUp animate__delay-1s">
      <div class="d-flex flex-fill option flex-column align-items-center" (click)="openMenu()">
        <mat-icon>menu_open</mat-icon>
        <label>Menu</label>
      </div>
      <div class="d-flex flex-fill option flex-column align-items-center" routerLink="/">
        <mat-icon>house</mat-icon>
        <label>Overview</label>
      </div>
      <div class="d-flex flex-fill option flex-column align-items-center" routerLink="/personal-details">
        <mat-icon>person</mat-icon>
        <label>Profile</label>
      </div>
    </nav>
  </div>

  <ng-template let-bottomSheetRef="bottomSheetRef" #templateBottomSheet>
    <ng-container *ngTemplateOutlet="templateMenu">
    </ng-container>
  </ng-template>

  <ng-template  #templateMenu>
    <div *ngFor="let item of menuList$ | async" class="menu-items">

      <a class="btn d-flex align-items-center" [class.active]="isMenuExpanded(item)"
        *ngIf="checkFeatureToggle(item.featureFlag) && isVisible(item)" [routerLink]="item.link" routerLinkActive="active-link"
        [attr.target]="item.target ? item.target : null" (click)="onItemSelectedClick(item)"
        [matMenuTriggerFor]="item.children.length > 0?menu:placeholder" aria-expanded="false">
        <mat-icon *ngIf="item.icon">{{item.icon}}</mat-icon><span class="nav-label"
          style="white-space: pre;">{{item.name}}</span>
        <mat-icon *ngIf="item.children.length > 0" class="item-arrow">expand_more</mat-icon>
      </a>
      <mat-menu #placeholder="matMenu" class="placeholder"></mat-menu>
      <mat-menu #menu="matMenu" >
        <ul class="pl-4 matmenu-list list-unstyled"  appanimatecss="animate__fadeInDown">
          <li *ngFor="let child of item.children" style="background-color: #fafafa;">
            <a *ngIf="child.link?.startsWith('/') && checkFeatureToggle(child.featureFlag)"
              class="btn no-text-decoration menu-item sub-menu-item d-flex align-items-center"
              [routerLink]="child.link" [fragment]="child.fragment">
              <mat-icon class="position-relative" *ngIf="child.icon">{{child.icon}}</mat-icon>
              <div class="nav-label">{{child.name}}</div>
            </a>
            <a *ngIf="child.link?.startsWith('http') && checkFeatureToggle(child.featureFlag)"
              class="btn no-text-decoration menu-item sub-menu-item d-flex align-items-center" [href]="child.link"
              [attr.target]="child.target ? child.target : null">
              <mat-icon class="position-relative" *ngIf="child.icon">{{child.icon}}</mat-icon>
              <div class="nav-label">{{child.name}}</div>
            </a>
          </li>
        </ul>


      </mat-menu>


    </div>
  </ng-template>

  <!--
    </mat-drawer-content>
  </mat-drawer-container> -->

</div>
