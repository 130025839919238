import { InputScrollDirective, InputAddressScrollDirective, InputDateScrollDirective } from './directives/input-scroll.directive';
import { UnitPricesViewComponent } from './components/unit-prices-view/unit-prices-view.component';
import { MemberDocumentViewerViewComponent } from './components/document-viewer/document-viewer-view.component';
import { UnitPricesViewEffects } from './components/unit-prices-view/effects';
import { DocumentViewerEffects } from './components/document-viewer/effects';
import { NotificationDropdownEffects } from './components/notification-dropdown/effects';
import { ConsolidateSuperEffects } from './components/consolidate-super/effects';
import { ScreenWidthDirective } from './directives/screen-width.directive';
import { PersonalContributionEditEffects } from './components/personal-contribution-edit/effects';
import { ContributionsLimitsEffects } from './components/contributions-limits/effects';
import { ContributionsLimitsComponent } from './components/contributions-limits/contributions-limits.component';
import { SuperannuationProviderSearchComponent } from './components/superannuation-provider-search/superannuation-provider-search.component';
import { PersonalContributionEditComponent } from './components/personal-contribution-edit/personal-contribution-edit.component';
import { RollInEditEffects } from './components/rollin-edit/effects';
import { RollinEditComponent } from './components/rollin-edit/rollin-edit.component';
import { MessagePipe } from './pipes/message';
import { MemberViewOverviewEffects } from './components/member-view-overview/effects';
import { SwitchInvestmentsEffects } from './components/switch-investments-edit/effects';
import { SwitchInvestmentsEditComponent } from './components/switch-investments-edit/switch-investments-edit.component';
import { InsuranceDetailsEffects } from './components/insurance-details/effects';
import { InsuranceDetailsComponent } from './components/insurance-details/insurance-details.component';
import { HistoricalChartViewEffects } from './components/historical-chart/effects';
import { HistoricalChartViewComponent } from './components/historical-chart/historical-chart-view.component';
import { AppRadioDirective } from './directives/app-radio.directive';
import { InsuranceQuestionnaireStartComponent } from './components/insurance-questionnaire-start/insurance-questionnaire-start.component';
import { InsuranceQuestionnaireEffects } from './components/insurance-questionnaire/effects';
import { InsuranceQuestionnaireStartEffects } from './components/insurance-questionnaire-start/effects';
import { InsuranceQuestionnaireComponent } from './components/insurance-questionnaire/insurance-questionnaire.component';
import { MemberAccountDropdownEffects } from './components/member-account-dropdown/effects';
import { CashTransactionsComponent } from './components/cash-transactions/cash-transactions.component';
import { CashTransactionsEffects } from './components/cash-transactions/effects';
import { EditMemberEffects } from './components/member-edit/effects';
import { ConsolidateSuperComponent } from './components/consolidate-super/consolidate-super.component';
import { MemberViewOverviewComponent } from './components/member-view-overview/member-view-overview.component';
import { MemberAccountDropdownComponent } from './components/member-account-dropdown/member-account-dropdown.component';
import { UserTopDropdownComponent } from './components/user-top-dropdown/user-top-dropdown.component';
import { AppButtonDirective } from './directives/appbutton.directive';
import { MaterialModule } from './../../app.material.module';
import { BeneficiariesViewEffects } from './components/beneficiaries-view/effects';
import { SuperannuationProviderSearchEffects } from './components/superannuation-provider-search/effects';
import { BeneficiariesEditEffects } from './components/beneficiaries-edit/effects';
import { AccountUnderlyingViewEffects } from './components/account-underlying-view/effects';
import { MemberEffects } from './components/member-view/effects';
import { MemberViewComponent } from './components/member-view/member-view.component';
import { MemberPensionViewComponent } from './components/member-pension-view/member-pension-view.component';
import { MemberEditComponent } from './components/member-edit/member-edit.component';
import { AccountUnderlyingViewComponent } from './components/account-underlying-view/account-underlying-view.component';
import { BeneficiariesViewComponent } from './components/beneficiaries-view/beneficiaries-view.component';
import { BeneficiariesEditComponent } from './components/beneficiaries-edit/beneficiaries-edit.component';
import { AppComponentWrapperComponent } from './components/app-component-wrapper/app-component-wrapper.component';
import { HttpClientModule } from '@angular/common/http';
import { EffectsModule } from '@ngrx/effects';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { IfaaUiComponentsModule } from '@ifaa-components/ui-components';
import { TenantConfigPipe } from './pipes/tenantconfig';
import { AnimateCssDirective } from './directives/animatecss.directive';
import { FadeInConcatDirective } from './directives/fadein-concat.directive';
import { FeatureToggleDirective } from './directives/feature-toggle.directive';
import { MessageDirective } from './directives/message.directive';
import { PermissionDirective } from './directives/permission.directive';
import { NgrxFormsModule } from 'ngrx-forms';
import { featureKey, reducers } from './store/shared.reducers';
import { MemberPortalSharedRoutingModule } from './shared-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NotificationDropdownComponent } from './components/notification-dropdown/notification-dropdown.component';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { BPAYDetailsComponent } from './components/bpay-details/bpay-details.component';
import { BPAYDetailsEffects } from './components/bpay-details/effects';
import { MemberPensionEffects } from './components/member-pension-view/effects';
import { InvestmentEditComponent } from './components/investment-edit/investment-edit.component';
import { EditInvestmentEffects } from './components/investment-edit/effects';
import { AccessYourSuperStartComponent } from './components/access-your-super-start/access-your-super-start.component';
import { AccessYourSuperStartComponentEffects } from './components/access-your-super-start/effects';
import { InsurancePreQuestionnaireComponent } from './components/insurance-pre-questionnaire/insurance-pre-questionnaire.component';
import { ChatViewEffects } from './components/chat/effects';
import { ChatViewComponent } from './components/chat/chat-view.component';

@NgModule({
  declarations: [
    TenantConfigPipe,
    MessagePipe,
    AnimateCssDirective,
    FadeInConcatDirective,
    FeatureToggleDirective,
    AppButtonDirective,
    AppRadioDirective,
    MessageDirective,
    ScreenWidthDirective,
    PermissionDirective,
    InputScrollDirective,
    InputAddressScrollDirective,
    InputDateScrollDirective,
    AppComponentWrapperComponent,
    BeneficiariesViewComponent,
    BPAYDetailsComponent,
    AccountUnderlyingViewComponent,
    MemberViewComponent,
    InvestmentEditComponent,
    UserTopDropdownComponent,
    MemberAccountDropdownComponent,
    MemberViewOverviewComponent,
    ConsolidateSuperComponent,
    MemberEditComponent,
    BeneficiariesEditComponent,
    CashTransactionsComponent,
    HistoricalChartViewComponent,
    InsuranceQuestionnaireComponent,
    InsuranceQuestionnaireStartComponent,
    InsurancePreQuestionnaireComponent,
    InsuranceDetailsComponent,
    SwitchInvestmentsEditComponent,
    RollinEditComponent,
    ContributionsLimitsComponent,
    SuperannuationProviderSearchComponent,
    PersonalContributionEditComponent,
    UnitPricesViewComponent,
    MemberDocumentViewerViewComponent,
    NotificationDropdownComponent,
    MemberPensionViewComponent,
    ChatViewComponent,
    AccessYourSuperStartComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    NgApexchartsModule,
    HttpClientModule,
    PdfJsViewerModule,
    IfaaUiComponentsModule,
    MemberPortalSharedRoutingModule,
    MaterialModule,
    NgrxFormsModule,
    StoreModule.forFeature(featureKey, reducers),
    EffectsModule.forFeature([
      MemberEffects,
      MemberViewOverviewEffects,
      AccountUnderlyingViewEffects,
      BeneficiariesViewEffects,
      BPAYDetailsEffects,
      EditMemberEffects,
      EditInvestmentEffects,
      BeneficiariesEditEffects,
      CashTransactionsEffects,
      MemberAccountDropdownEffects,
      HistoricalChartViewEffects,
      InsuranceQuestionnaireEffects,
      InsuranceQuestionnaireStartEffects,
      InsuranceDetailsEffects,
      SwitchInvestmentsEffects,
      RollInEditEffects,
      ContributionsLimitsEffects,
      SuperannuationProviderSearchEffects,
      PersonalContributionEditEffects,
      ConsolidateSuperEffects,
      UnitPricesViewEffects,
      DocumentViewerEffects,
      NotificationDropdownEffects,
      MemberPensionEffects,
      ChatViewEffects,
      AccessYourSuperStartComponentEffects
    ]),
    TranslateModule.forChild(),
  ],
  exports: [
    TenantConfigPipe,
    MessagePipe,
    AnimateCssDirective,
    FadeInConcatDirective,
    FeatureToggleDirective,
    AppButtonDirective,
    AppRadioDirective,
    MessageDirective,
    PermissionDirective,
    ScreenWidthDirective,
    InputScrollDirective,
    InputAddressScrollDirective,
    InputDateScrollDirective,
    AppComponentWrapperComponent,
    BeneficiariesViewComponent,
    BPAYDetailsComponent,
    AccountUnderlyingViewComponent,
    MemberViewComponent,
    InvestmentEditComponent,
    UserTopDropdownComponent,
    MemberAccountDropdownComponent,
    MemberViewOverviewComponent,
    ConsolidateSuperComponent,
    MemberEditComponent,
    BeneficiariesEditComponent,
    CashTransactionsComponent,
    HistoricalChartViewComponent,
    InsuranceQuestionnaireComponent,
    InsuranceQuestionnaireStartComponent,
    InsurancePreQuestionnaireComponent,
    InsuranceDetailsComponent,
    SwitchInvestmentsEditComponent,
    RollinEditComponent,
    ContributionsLimitsComponent,
    PersonalContributionEditComponent,
    UnitPricesViewComponent,
    MemberDocumentViewerViewComponent,
    NotificationDropdownComponent,
    MemberPensionViewComponent,
    ChatViewComponent,
    AccessYourSuperStartComponent
  ],
  providers: [
  ],
  entryComponents: [
  ]
})
export class MemberPortalSharedModule { }
