import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as ActionsList from './actions';
import { map, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { createEffect } from '@ngrx/effects';
import { ToastrService } from 'ngx-toastr';
import { InvestmentService } from 'src/app/services/investment.service';

@Injectable()
export class EditInvestmentEffects {
  constructor(private store: Store<any>,
    private actions$: Actions,
    public toastr: ToastrService,
    private router: Router,
    private service: InvestmentService) {
  }

  getCurrentById$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.RequestCurrentDataAction),
    switchMap((action: any) => this.service.getCurrentInvestmentSwitch(action.account)),
    map((data: any) => {
      return ActionsList.ResponseCurrentDataAction({ payload: data.data });
    })
  ));

  getOptions$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.RequestOptionsDataAction),
    switchMap((action: any) => this.service.getInvestmentOptionsSwitch(action.account)),
    map((data: any) => {
      return ActionsList.ResponseOptionsDataAction({ payload: data.data });
    })
  ));

  submit$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.SubmitRequestAction),
    switchMap((action: any) => this.service.submitInvestment(action.accountId,  action.obj)),
    map((data: any) => {
      this.toastr.success(data.message);
      return ActionsList.SubmitResponseAction({ payload: data });
    })
  ));
}
