import { MemberModel } from './../../../../model/member.model';
import { editMember_Form, editMember_LastModified } from './selectors';
import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { RequestAction, ResetAction, SubmitRequestAction } from './actions';
import { ComponentBase } from '../../../../views/component-base';
import { AppState } from '../../../../store/app.states';
import { MemberPortalSharedState } from '../../store/shared.states';
import { Router } from '@angular/router';
import { commomState_SystemConfig_Config, commomState_SystemConfig_FeatureToggle } from 'src/app/store/common/common.selectors';
import { SetErrorsAction, ClearAsyncErrorAction, SetValueAction } from 'ngrx-forms';
import { CommunicationPreference } from 'src/app/enums/communication-preference.enum';

@Component({
  selector: 'app-member-edit',
  templateUrl: './member-edit.component.html',
  styleUrls: ['./member-edit.component.scss']
})
export class MemberEditComponent extends ComponentBase implements OnInit, OnDestroy {

  form$ = this.store.pipe(select(editMember_Form));
  lastModified$ = this.store.pipe(select(editMember_LastModified));
  tenantConfig$ = this.appstore.pipe(select(commomState_SystemConfig_Config));
  featuretoggle$ = this.appstore.pipe(select(commomState_SystemConfig_FeatureToggle));
  communicationPreference = null;
  constructor(public store: Store<MemberPortalSharedState>,
    public appstore: Store<AppState>,
    private router: Router) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();
    this.dispatch(this.store, RequestAction());
    this.sub = this.lastModified$.subscribe(x => {
      if (x) {
        this.router.navigate(['personal-details']);
      }
    });

    this.sub = this.form$.subscribe(form => {
      if (this.communicationPreference != form.value.communicationPreference) {
        this.communicationPreference = form.value.communicationPreference;
        this.store.dispatch(new SetValueAction(form.controls.postalAddress.controls.isRequired.id, form.value.communicationPreference == CommunicationPreference.byPost));
      }

    });
  }

  ngOnDestroy() {
    this.store.dispatch(ResetAction());
    super.ngOnDestroyBase();
  }

  onSubmitClick(data: MemberModel) {
    this.dispatch(this.store, SubmitRequestAction({ payload: data }));

  }

}
