import { ComponentBase } from './../../../../views/component-base';
import {
  cashTransactionList_List, cashTransactionList_Filter, cashTransactionList_TransactionTypes,
  cashTransactionList_ListTotal, cashTransactionList_SelectedTypeName, cashTransactionList_TransactionDescription, cashTransactionList_SelectedDescription
} from './selectors';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Store, Action } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { select } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import {
  CashTransactionsRequestAction, CashTransactionIncrementPageAction,
  CashTransactionsTypesRequestAction,
  ResetFormAction,
  CashTransactionsSetFromToDateAction,
  CashTransactionsDescriptionRequestAction
} from './actions';
import { CashTransactionFilter } from './state';
import { SetValueAction, FormGroupControls } from 'ngrx-forms';
import { MemberPortalSharedState } from '../../store/shared.states';
import { Helper } from '@ifaa-components/ui-components';
import { memberAccountDropdown_SelectedAccount } from '../member-account-dropdown/selectors';
import { UIComponentState } from '@ifaa-components/ui-components/lib/store/app.states';
import { MatDrawer, MatDrawerContent } from '@angular/material/sidenav';
import { AppState } from 'src/app/store/app.states';

@Component({
  selector: 'app-cash-transactions',
  templateUrl: './cash-transactions.component.html',
  styleUrls: ['./cash-transactions.component.scss']
})
export class CashTransactionsComponent extends ComponentBase implements OnInit, OnDestroy {
  @ViewChild("drawer") drawer: MatDrawer;

  transactionTypes$ = this.store.pipe(select(cashTransactionList_TransactionTypes));
  transactionDescription$ = this.store.pipe(select(cashTransactionList_TransactionDescription));
  list$ = this.store.pipe(select(cashTransactionList_List));
  listTotal$ = this.store.pipe(select(cashTransactionList_ListTotal));
  selectedAccount$ = this.store.pipe(select(memberAccountDropdown_SelectedAccount));
  filter$ = this.store.pipe(select(cashTransactionList_Filter));
  selectedType$ = this.store.pipe(select(cashTransactionList_SelectedTypeName));
  selectedDescription$ = this.store.pipe(select(cashTransactionList_SelectedDescription));
  @ViewChild("drawercontent") drawercontent: MatDrawerContent;
  accountId: number;
  query: CashTransactionFilter;
  isFilterOpen = true;
  loading = false;
  filterMode = 'over';
  helper: Helper = new Helper();
  constructor(public store: Store<MemberPortalSharedState>,
    public uistore: Store<UIComponentState>,
    public appstore: Store<AppState>,
    public dialog: MatDialog) {
    super();
  }


  ngOnInit() {
    super.ngOnInitBase();
    this.dispatch(this.store, ResetFormAction());
    this.screenWidthChange(this.appstore, (width: number) => {

      this.isFilterOpen = false;

      if (width < 992) {
        this.isFilterOpen = false;
      }
    });

    this.sub = this.filter$.subscribe(async x => {
      if (!this.accountId) return;

      var filter = await this.formatQuery(x.value);
      if (this.isQueryDifferent(x.value) && x.value.currentPage >= 0) {
        this.dispatch(this.store, CashTransactionsTypesRequestAction({ accountId: this.accountId }));
        this.dispatch(this.store, CashTransactionsDescriptionRequestAction({ accountId: this.accountId }));
        this.dispatch(this.store, CashTransactionsRequestAction({ accountId: this.accountId, page: x.value.currentPage, filter: filter }));
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
        }, 500);
      }
    });

    this.sub = this.selectedAccount$.subscribe(x => {
      var val = x?.accountId;
      if (val) {
        this.accountId = val;
        this.dispatch(this.store, ResetFormAction());
      }
    });
  }

  openedChange() {
    if (this.drawer) {
      this.isFilterOpen = this.drawer.opened;
    }
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

  selectTransactionType(key: string) {
    this.dispatch(this.store, new SetValueAction('cashtransactionsListFilter.transactionType', key));
  }

  selectTransactionDescription(key: string) {
    this.dispatch(this.store, new SetValueAction('cashtransactionsListFilter.transactionDescription', key));
  }

  isQueryDifferent(filter: CashTransactionFilter): boolean {
    if (JSON.stringify(this.query) !== JSON.stringify(filter)) {
      this.query = filter;
      return true;
    }
    return false;
  }
  async formatQuery(value: CashTransactionFilter): Promise<string> {
    var list = [];
    if (value.from) {
      list.push("from:" + value.from);
    }
    if (value.to) {
      list.push("to:" + value.to);
    }
    if (value.transactionType) {
      list.push("transactionType:" + value.transactionType);
    }
    if (value.transactionDescription) {
      var descKeyVal = await this.helper.getValue(this.transactionDescription$);
      var selected = descKeyVal.filter(x => x.key == value.transactionDescription)[0];

      list.push("description:" + selected.value);
    }
    return list.join(';');
  }

  // onLoadMoreTransactions() {
  //   this.dispatch(this.store, CashTransactionIncrementPageAction());
  // }

  currentFinancialYear(filter: FormGroupControls<CashTransactionFilter>) {
    this.drawercontent.getElementRef().nativeElement.scrollTop = 0;
    var dates = this.helper.getFinancialYear(new Date());
    this.dispatch(this.store, CashTransactionsSetFromToDateAction({ from: dates.from.toISOString(), to: dates.to.toISOString() }));
  }

  previousFinancialYear(filter: FormGroupControls<CashTransactionFilter>) {
    this.drawercontent.getElementRef().nativeElement.scrollTop = 0;
    var today = new Date();
    var dates = this.helper.getFinancialYear(new Date(today.getFullYear() - 1, today.getMonth(), today.getDay()));

    this.dispatch(this.store, CashTransactionsSetFromToDateAction({ from: dates.from.toISOString(), to: dates.to.toISOString() }));
  }
  customFinancialYear(filter: FormGroupControls<CashTransactionFilter>) {
    this.drawercontent.getElementRef().nativeElement.scrollTop = 0;
    this.dispatch(this.store, CashTransactionsSetFromToDateAction({ from: '', to: '' }));
  }

  toggleFilter() {
    this.isFilterOpen = !this.isFilterOpen;
  }

}
