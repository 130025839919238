import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../store/app.states';
import { ComponentBase } from '../../views/component-base';
import { OnDestroy } from '@angular/core';
import { RequestAction } from '../../modules/shared/components/member-view/actions';
import { insuranceQuestionnaire_ShowQuestionnaire } from 'src/app/modules/shared/components/insurance-questionnaire/selectors';
import { insuranceDetails_Model } from 'src/app/modules/shared/components/insurance-details/selectors';
import { insurancePreQuestionnaire_ShowStartQuestionnaire, insurancePreQuestionnaire_ShowPreQuestionnaire } from 'src/app/modules/shared/components/insurance-pre-questionnaire/selectors';
import { ResetPreQuestionnaireRequestAction } from 'src/app/modules/shared/components/insurance-pre-questionnaire/actions';
import { memberAccountDropdown_SelectedAccount } from 'src/app/modules/shared/components/member-account-dropdown/selectors';
import { ShowQuestionnaireRequestAction } from 'src/app/modules/shared/components/insurance-questionnaire/actions';

@Component({
  selector: 'app-insurance-view',
  templateUrl: './insurance-view.component.html',
  styleUrls: ['./insurance-view.component.scss'],
  host: {
    class: 'w-100'
  }
})
export class InsuranceViewComponent extends ComponentBase implements OnInit, OnDestroy {

  selectedAccount$ = this.store.pipe(select(memberAccountDropdown_SelectedAccount));
  showQuestionnaire$ = this.store.pipe(select(insuranceQuestionnaire_ShowQuestionnaire));
  showStartQuestion$ = this.store.pipe(select(insurancePreQuestionnaire_ShowStartQuestionnaire));
  showPreQuestionnaire$ = this.store.pipe(select(insurancePreQuestionnaire_ShowPreQuestionnaire));
  model$ = this.store.pipe(select(insuranceDetails_Model));


  constructor(public store: Store<AppState>) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();
    
      this.sub = this.selectedAccount$.subscribe(x => {
      if (x) {
       this.dispatch(this.store, ShowQuestionnaireRequestAction({ accountId: x.accountId }));
       this.dispatch(this.store, ResetPreQuestionnaireRequestAction());
      }
    })

    this.dispatch(this.store, RequestAction());
    
    
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

}
