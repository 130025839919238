import { beneficiariesView_CanEdit, getBeneficiariesView_List } from './selectors';
import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { RequestAction } from './actions';
import { ComponentBase } from '../../../../views/component-base';
import { AppState } from '../../../../store/app.states';
import { MemberPortalSharedState } from '../../store/shared.states';
import { MatDialog } from '@angular/material/dialog';
import { CancelEditDialog } from '@ifaa-components/ui-components';
import { Router } from '@angular/router';
import { memberAccountDropdown_SelectedAccount } from '../member-account-dropdown/selectors';

@Component({
  selector: 'app-beneficiaries-view',
  templateUrl: './beneficiaries-view.component.html',
  styleUrls: ['./beneficiaries-view.component.scss']
})
export class BeneficiariesViewComponent extends ComponentBase implements OnInit, OnDestroy {

  selectedAccount$ = this.store.pipe(select(memberAccountDropdown_SelectedAccount));
  list$ = this.store.pipe(select(getBeneficiariesView_List));
  canEdit$ = this.store.pipe(select(beneficiariesView_CanEdit));

  constructor(public store: Store<MemberPortalSharedState>,
    private router: Router) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();
    this.sub = this.selectedAccount$.subscribe(x => {
      if (x) {
        this.dispatch(this.store, RequestAction({ accountId: x.accountId }));
      }
    })
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }



  onEditClick() {
    this.router.navigate(['beneficiaries-edit']);

  }
}
