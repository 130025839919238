<div id="personal-details-container">
  <div class="page-header">
    <app-toolbar-options icon="supervisor_account">
      <div class="d-flex flex-column" slot="start">
        <div class="page-title">Your Beneficiaries</div>

      </div>
    </app-toolbar-options>
  </div>
  <div class="page-content" appanimatecss>

    <app-component-wrapper icon="supervisor_account" title="Edit Beneficiaries">
      <div class="d-flex flex-column" slot="start">
        <app-beneficiaries-view></app-beneficiaries-view>
      </div>

    </app-component-wrapper>
  </div>

</div>
