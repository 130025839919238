// import { commomState_LoggedinInfo } from './../store/common/common.selectors';
import { AppState } from './../store/app.states';
import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { of, Observable } from 'rxjs';
import { commomState_SystemConfig_FeatureToggle } from '../store/common/common.selectors';

@Injectable()
export class FeatureFlagGuardService implements CanActivate {

  featuretoggle$ = this.store.pipe(select(commomState_SystemConfig_FeatureToggle));

  constructor(public store: Store<AppState>,
    private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return new Observable<boolean>(obs => {
      let featureflag = route.data.flag as string;
      this.featuretoggle$.subscribe(flag => {
        if (flag?.hasOwnProperty(featureflag) == true && flag[featureflag]) {
          obs.next(true);
        }

        obs.next(false);
      })
    });
  }
}
