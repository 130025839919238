<div id="beneficiaries-view-container">

  <p class="mb-3" [innerHTML]="'beneficiaries_Description'|message|async"></p>

  <app-no-items message="No beneficiary found" *ngIf="(list$ |async)?.length === 0"></app-no-items>
  <div class="table-wrapper">
    <table class="table table-striped" *ngIf="(list$ |async)?.length > 0">
      <thead>
        <tr>
          <th scope="col">Name</th>
          <th scope="col">Type</th>
          <th scope="col">Percent</th>
          <th scope="col">Relationship to You</th>
          <th scope="col">Expiration Date</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of list$ | async">
          <th>{{item.name}}</th>
          <th>{{item.beneficiaryTypeName}}</th>
          <th>{{item.percentage + '%'}}</th>
          <th>{{item.relationship}}</th>
          <th>{{item.expirationDate }}</th>
        </tr>

      </tbody>
    </table>
  </div>

  <div class="components-actions" *ngIf="canEdit$ | async">
    <app-button loading class="floar-right" title="Edit Beneficiary" icon="{{'BTN.beneficiary-view.edit.icon' | translate}}" (onClick)="onEditClick()" >
    </app-button>
  </div>

</div>
