import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuardService } from "src/app/route-guard/auth-guard.service";
import { MainMasterComponent } from "./layout/main/main.master";
import { VerveAcademyViewComponent } from "./views/verve-academy/verve-academy-view.component";

const verveRoutes: Routes = [
  // { path: "", component: ShipListComponent },
  {
    path: 'academy',
    component: MainMasterComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        outlet: 'master',
        path: '',
        component: VerveAcademyViewComponent
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(verveRoutes)],
  exports: [RouterModule]
})
export class VerveRoutingModule { }
