<div id="beneficiaries-edit-container">

  <div *ngIf="form$ | async; let form">
    <p [innerHTML]="'beneficiaries_Description'|message|async"></p>


    <table class="table table-striped mt-3">
      <thead>
        <tr>
          <th class="views-field" scope="col">Name</th>
          <th class="views-field" scope="col">Type</th>
          <th class="views-field" scope="col">Percent</th>
          <th scope="col">Relationship to You</th>
          <th class="views-field" scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of form.value; let i = index; trackBy: trackByFn">
          <td>
            <span>Name</span>
            <app-entry title="" [control]="form.controls[i].controls.name">
            </app-entry>
          </td>
          <td>
            <span>Type</span>
            <label>{{form.controls[i].value.beneficiaryTypeName}}</label>
          </td>
          <td>
            <span>Percent</span>
            <app-entry-number title="" [control]="form.controls[i].controls.percentage" [decimalplaces]="2">
            </app-entry-number>
          </td>
          <td>
            <span>Relationship to You</span>
            <app-entry title="" [control]="form.controls[i].controls.relationship">
            </app-entry>
          </td>

          <td class="actions">
            <app-button *ngIf="form.controls[i].isEnabled" loading class="w-100" title="Delete" icon="close"
              color="danger" (onClick)="onDeleteClick(i)">
            </app-button>
          </td>

        </tr>
        <tr>
          <th>
            <div *ngIf="(maxBeneficiaries$ | async) > 0 && form.value.length > 0" class="alert alert-success">
              <span class="d-block" >
                Beneficiaries: {{form.value.length}} out of {{maxBeneficiaries$ | async}}
              </span>
            </div>
          </th>
          <th></th>
          <th>
            <div *ngIf="form.value.length > 0" class="alert "
              [ngClass]="{'alert-success': isSum100Percentage$ | async, 'alert-danger': !(isSum100Percentage$|async)}">
              Total: {{totalPercentage$ | async}}% of 100%
            </div>
          </th>
          <th></th>
          <th></th>
        </tr>
      </tbody>
    </table>

    <div class="components-actions d-flex">
      <app-button loading class="" title="Save" icon="{{'BTN.beneficiary-edit.save.icon' | translate}}" (onClick)="onSubmitClick(form.value)" class="pl-1"
        [isDisabled]="form.isInvalid || !form.isDirty || (!(isSum100Percentage$ | async) && form.value.length > 0)">
      </app-button>
      <app-button loading class="  pr-1" title="Add Beneficiary" icon="add" [isDisabled]="!(canEdit$|async) || !(canAdd$ | async)"
        (onClick)="onAddClick()">
      </app-button>

      <!-- invalid: {{form.isInvalid}}<br>
      dirty:{{form.isDirty}}<br>
      sum:{{(isSum100Percentage$ | async)}}<br> -->
    </div>
  </div>

</div>
