import { AccountsService } from 'src/app/services/accounts.service';
import { HelperService } from './../../services/helper.service';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { createEffect } from '@ngrx/effects';
import { IsLoggedInRequestAction, IsLoggedInResponseAction, SystemConfigurationRequestAction, SystemConfigurationResponseAction } from './common.actions';
import { WebUserService } from 'src/app/services/webuser.service';

@Injectable()
export class CommonEffects {
  constructor(private store: Store<any>,
    private actions$: Actions,
    private userService: WebUserService,
    private service: HelperService) {
  }

  getSystemConfig$ = createEffect(() => this.actions$.pipe(
    ofType(SystemConfigurationRequestAction),
    switchMap((action: any) => this.service.systemConfiguration()),
    map((data: any) => {
      return SystemConfigurationResponseAction({ payload: data });
    })
  ));

  @Effect({ dispatch: false })
  isLoggedIn$ = createEffect(() => this.actions$.pipe(
    ofType(IsLoggedInRequestAction),
    switchMap((action: any) => this.userService.isLoggedIn()),
    map((data: any) => {
      return IsLoggedInResponseAction({ data: data.data });
    })
  ));

}
