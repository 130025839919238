import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import * as ActionsList from './actions';
import { map, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { createEffect } from '@ngrx/effects';
import { JetcoService } from '../../../services/jetco.service';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class JetcoLodgeClaimEffects {
  constructor(private store: Store<any>,
    public toastr: ToastrService,
    private actions$: Actions,
    private service: JetcoService
  ) {
  }

  submit$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.SubmitRequestAction),
    switchMap((action: any) => this.service.submit(action.accountId, action.payload)),
    map((data: any) => {
      this.toastr.success(data.message);
      return ActionsList.SubmitResponseAction({ payload: data });
    })
  ));


  claimAmount$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.JetcoClaimAmountRequestAction),
    switchMap((action: any) => this.service.jetcoClaimAmount(action.start, action.end)),
    map((data: any) => {
      return ActionsList.JetcoClaimAmountResponseAction({ payload: data.data });
    })
  ));

}
