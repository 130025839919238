import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { ComponentBase } from '../../../../views/component-base';
import { commomState_ElevatedAccess, commomState_LoggedinInfo } from 'src/app/store/common/common.selectors';
import { AppState } from 'src/app/store/app.states';
import { LogoutRequestAction } from 'src/app/store/common/common.actions';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-top-dropdown',
  templateUrl: './user-top-dropdown.component.html',
  styleUrls: ['./user-top-dropdown.component.scss']
})
export class UserTopDropdownComponent extends ComponentBase implements OnInit, OnDestroy {

  loggedInUser$ = this.store.pipe(select(commomState_LoggedinInfo));
  elevatedAccess$ = this.store.pipe(select(commomState_ElevatedAccess));

  constructor(
    public store: Store<AppState>,
    private router: Router) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();
    this.screenWidthChange(this.store, (width: number) => {
    });
    // this.store.dispatch(RequestAction());
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

  sigout() {
    this.store.dispatch(LogoutRequestAction());
    window.localStorage.removeItem('token_name');
    this.router.navigate(['login']);
  }
}

