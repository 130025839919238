import { AccountModel } from './../../../../model/account.model';
import { Subscription } from 'rxjs';
import { SetMenuAction } from './../../../../store/common/common.actions';
import { mainLayout_Animated, mainLayout_Expanded } from './selectors';
import { MenuModel } from './../../../../model/menu.model';
import { AppState } from 'src/app/store/app.states';
import { Component, HostListener, Inject, TemplateRef, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ComponentBase } from 'src/app/views/component-base';
import { commomState_IsLoading, commomState_LoggedinInfo, commomState_MenuList, commomState_SystemConfig_FeatureToggle } from 'src/app/store/common/common.selectors';
import { SystemConfigurationRequestAction } from 'src/app/store/common/common.actions';
import { MainExpandedRequestAction, MainSetAnimatedPageAction } from './actions';
import { SMRTState } from '../../store/smrt.states';
import { MatDrawer } from '@angular/material/sidenav';
import { insuranceQuestionnaire_ShowQuestionnaire } from 'src/app/modules/shared/components/insurance-questionnaire/selectors';
import { Title } from '@angular/platform-browser';
import { memberViewOverview_Model } from 'src/app/modules/shared/components/member-view-overview/selectors';
import { RequestAction } from 'src/app/modules/shared/components/member-view/actions';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { filter } from 'rxjs/operators';
import { memberAccountDropdown_SelectedAccount } from 'src/app/modules/shared/components/member-account-dropdown/selectors';

@Component({
  selector: 'login-master',
  templateUrl: './main.master.html',
  styleUrls: ['./main.master.scss']
})
export class MainMasterComponent extends ComponentBase {

  expanded: MenuModel[] = [];
  featuretoggle$ = this.store.pipe(select(commomState_SystemConfig_FeatureToggle));
  animated$ = this.store.pipe(select(mainLayout_Animated));
  expanded$ = this.smrtstore.pipe(select(mainLayout_Expanded));
  menuList$ = this.store.pipe(select(commomState_MenuList));
  loggedInUser$ = this.store.pipe(select(commomState_LoggedinInfo));
  isLoading$ = this.store.pipe(select(commomState_IsLoading));
  isQuestionnarieVisible$ = this.store.pipe(select(insuranceQuestionnaire_ShowQuestionnaire));
  user$ = this.store.pipe(select(memberViewOverview_Model));
  selectedAccount$ = this.store.pipe(select(memberAccountDropdown_SelectedAccount));

  featureToggle: any = null;
  menuMode = "side";
  menuOpened = true;
  showMenuIconTop = true;
  selectedAccount: AccountModel = null;
  @ViewChild('templateBottomSheet') TemplateBottomSheet: TemplateRef<any>;
  menu = [
    { id: '1', name: 'Overview', link: '/dashboard', icon: 'house', children: [] } as MenuModel,
    { id: '54', name: 'Pension', link: '/pension-details', icon: 'savings', children: [], featureFlag: 'isMemberPensionEnabled' } as MenuModel,

    {
      id: '2', name: 'Account', icon: 'account_box', children: [
        { id: '5-1', name: 'Update your details', link: '/personal-details', icon: 'create', children: [] } as MenuModel,
        { id: '5-4', name: 'Change your password', link: '/change-password', icon: 'lock', children: [] } as MenuModel,
        { id: '5-5', name: 'Manage Investments', link: '/investment', icon: 'price_change', children: [] } as MenuModel,
      ]
    } as MenuModel,
    // {
    //   id: '3', name: 'Investments', icon: 'attach_money', children: [
    //     { id: '3-1', name: 'View Assets', link: '/view-assets', icon: 'file_copy', children: [] } as MenuModel,
    //   ]
    // } as MenuModel,
    // { id: '4', name: 'Consolidate', link: '/rollin', icon: 'account_balance_wallet', children: [] } as MenuModel,
    { id: '11', name: 'Transaction History', link: '/transaction-listing', icon: 'receipt_long', children: [] } as MenuModel,

    {
      id: '15', name: 'Contributions', icon: 'paid', featureFlag: 'displayAccountBPAYDetails', children: [
        //{ id: '2-1', name: 'Transaction History', link: '/transaction-listing', icon: 'receipt_long', children: [] } as MenuModel,
        // { id: '2-3', name: 'Make a personal contribution', link: '/personal-contributions', icon: 'local_atm', children: [] } as MenuModel,
        // { id: '2-2', name: 'Contribution Caps', link: '/contributions', icon: 'airplay', children: [] } as MenuModel,
        { id: '15-1', name: 'Make a Contribution', link: '/make-a-contribution', icon: 'receipt_long', children: [], featureFlag: 'displayAccountBPAYDetails' } as MenuModel,
      ]
    } as MenuModel,
    // {
    //   id: '6', name: 'Insurance', link: '/insurance', icon: 'verified_user', children: [
    //     { id: '6-1', name: 'Your insurance cover', link: '/insurance', fragment: 'insurance_details', icon: 'admin_panel_settings', children: [] } as MenuModel,
    //     { id: '6-2', name: 'Request Insurance', link: '/insurance', fragment: 'insurance_questionnaire', icon: 'add_task', children: [] } as MenuModel,
    //   ]
    // } as MenuModel,
    {
      id: '12', name: 'Beneficiaries', link: '/beneficiaries', icon: 'supervisor_account', children: [
      ]
    } as MenuModel,
    {
      id: '7', name: 'Documents', icon: 'folder', children: [
        { id: '7-1', name: 'View Statements', link: '/member-statements', icon: 'text_snippet', children: [] } as MenuModel,
        { id: '7-2', name: 'Other Documents', link: '/other-documents', icon: 'article', children: [], featureFlag: 'displayOtherDocuments' } as MenuModel,
      ]
    } as MenuModel,
    { id: '54', name: 'Pension', link: '/pension-details', icon: 'savings', children: [], featureFlag: 'isMemberPensionEnabled' } as MenuModel,
    { id: '16', name: 'Access Your Super', link: '/access-your-super', icon: 'savings', children: [],  } as MenuModel,

    // {
    //   id: '10', name: 'Member Services', icon: 'portrait', link: 'https://www.cirt.com.au', target: '_blank', children: []
    // } as MenuModel,


  ]

  @ViewChild("drawer") drawer: MatDrawer;

  constructor(public store: Store<AppState>,
    public smrtstore: Store<SMRTState>,
    public dialog: MatDialog,
    private titleService: Title,
    private bottomSheet: MatBottomSheet,
    private router: Router) {
    super();
    this.titleService.setTitle("Smart Monday – Member Portal");
  }

  ngOnInit() {
    super.ngOnInitBase();
    this.store.dispatch(RequestAction());

    this.sub = this.selectedAccount$.subscribe(x => {
      if (x)
        this.selectedAccount = x;
    });

    this.screenWidthChange(this.store, (width: number) => {
      this.menuMode = "side";
      this.menuOpened = true;

      if (width < 1300) {
        this.menuMode = "over";
        this.menuOpened = false;
      }
      this.sub = this.router.events
        .pipe(filter(event => event instanceof RoutesRecognized))
        .subscribe((event) => {
          this.bottomSheet.dismiss(this.TemplateBottomSheet);
        });


    });
    setTimeout(() => {
      this.store.dispatch(MainSetAnimatedPageAction());

    }, 1000);


    this.sub = this.expanded$.subscribe(x => {
      this.expanded = x;
    });

    this.checkQuestionnarieMenuIsVisible();
    // this.setMenu(this.menu);
  }

  isVisible(item: MenuModel) {
    if (item.id == '54' && (!this.selectedAccount || this.selectedAccount?.accountTypeId == 1)) {
      return false;
    }

    if (item.id == '15' && (!this.selectedAccount || this.selectedAccount?.accountTypeId == 2)) {
      return false;
    }

    return true;
  }
  openMenu() {
    this.bottomSheet.open(this.TemplateBottomSheet, {
      panelClass: 'menu-items'
    });
  }
  checkFeatureToggle(key: string) {
    if (!key) return true;
    if (!this.featureToggle) return false;
    return this.featureToggle[key];
  }
  checkQuestionnarieMenuIsVisible() {
    this.sub = this.featuretoggle$.subscribe(x => {
      if (!x) return;
      this.featureToggle = x;
      // var dd = x['displayOtherDocuments'];
    });

    this.sub = this.isQuestionnarieVisible$.subscribe(isVisible => {
      var newMenu = [...this.menu];
      if (!isVisible) {
        for (let i = 0; i < newMenu.length; i++) {
          newMenu[i] = { ...newMenu[i], children: newMenu[i].children?.filter(c => c.id != '6-2') };
        }
      }
      else {
        for (let i = 0; i < newMenu.length; i++) {
          newMenu[i] = { ...newMenu[i], children: newMenu[i].children?.filter(c => c.id != '6-1') };
        }
      }
      this.setMenu(newMenu);
    });
  }
  openedChange() {
    if (this.drawer) {
      this.menuOpened = this.drawer.opened;
    }
  }
  onSwipeToggle(event) {
    this.menuOpened = !this.menuOpened;
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

  onItemSelectedClick(item: MenuModel) {
    this.store.dispatch(MainExpandedRequestAction({ menuItem: item }))

    if (item.link?.startsWith('http')) {
      window.open(item.link, "_blank");
    }
    else {
      if (item.link)
        this.router.navigate([item.link]);

    }
  }

  isMenuExpanded(item: MenuModel) {
    return this.expanded.filter(x => x.id == item.id).length > 0;
  }

  onSignoutClick(): void {

    window.localStorage.removeItem('token_name');
    this.router.navigate(['login']);
  }


  setMenu(menu: MenuModel[]) {

    this.store.dispatch(SetMenuAction({ data: menu }))

  }

}
