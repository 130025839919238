<div id="member-account-dropdown-container" class="dropdown">
  <button class="btn btn-light  d-flex align-items-center" type="button" id="dropdownMenuButton" data-toggle="dropdown"
    aria-haspopup="true" aria-expanded="false" [ngClass]="{'dropdown-toggle': (list$ | async).length > 1}">
    <!-- <mat-icon class="mr-3">how_to_reg</mat-icon> -->
    <div class="mr-2">
      <label class="selected-info member align-items-start justify-content-start d-flex">
        <div>Member <span *ngIf="screenWidth>992">number</span>:</div> {{(loggedIn$ | async)?.memberNumber}}
      </label>
      <label class="selected-info account align-items-start justify-content-start d-flex">
        <div>Account <span *ngIf="screenWidth>992">number</span>:</div> {{(selected$ | async)?.accountNumber}}
      </label>
    </div>
  </button>
  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton" *ngIf="(list$ | async).length > 1">
    <li *ngFor="let item of list$ | async">
      <a class="dropdown-item d-flex align-items-center p-2" (click)="onSelecteAccountClick(item)">
        <mat-icon class="mr-3">chevron_right</mat-icon>
        <div>
          <label>Member number: {{(loggedIn$ | async)?.memberNumber}}</label>
          <label>Account number: {{item.accountNumber}}</label>
        </div>

      </a>
    </li>
  </ul>
</div>
