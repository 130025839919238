import { createSelector } from '@ngrx/store';
import { AppState } from '../../store/app.states';
import { IForgotPasswordState } from './state';

export const getForgotPassword = (state: AppState) => state.forgotPasswordState;

export const getForgotPassword_Form = createSelector(
  getForgotPassword,
  (state: IForgotPasswordState) => state.form
);
