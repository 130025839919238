import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as ActionsList from './actions';
import { map, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { createEffect } from '@ngrx/effects';
import { RollInService } from 'src/app/services/rollin.service';

@Injectable()
export class RollInEditEffects {
  constructor(private store: Store<any>,
    private actions$: Actions,
    private service: RollInService) {
  }

  submit$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.SubmitRequestAction),
    switchMap((action: any) => this.service.submit(action.accountId, action.payload)),
    map((data: any) => {
      this.service.showOk(data.message);
      return ActionsList.SubmitResponseAction();
    })
  ));
}
