import {
  editinvestment_currentInfo, editinvestment_CurrentIsSum100Percent, editinvestment_CurrentStep,
  editinvestment_CurrentTotalPercent, editinvestment_Form, editinvestment_FutureIsSum100Percent, editinvestment_FutureTotalPercent,
  editinvestment_IsLastStep, editinvestment_LastModified
} from './selectors';
import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { GoToStepAction, RequestAction, RequestCurrentDataAction, RequestOptionsDataAction, ResetAction, SetStepsAction, SubmitRequestAction } from './actions';
import { ComponentBase } from '../../../../views/component-base';
import { AppState } from '../../../../store/app.states';
import { MemberPortalSharedState } from '../../store/shared.states';
import { Router } from '@angular/router';
import { commomState_SystemConfig_Config, commomState_SystemConfig_FeatureToggle } from 'src/app/store/common/common.selectors';
import { CurrentInvestmentModel, InvestmentItemModel, InvestmentModel } from './state';
import { memberAccountDropdown_SelectedAccount } from '../member-account-dropdown/selectors';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialog, Helper } from '@ifaa-components/ui-components';
import { MatBottomSheet } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-investment-edit',
  templateUrl: './investment-edit.component.html',
  styleUrls: ['./investment-edit.component.scss']
})
export class InvestmentEditComponent extends ComponentBase implements OnInit, OnDestroy {

  form$ = this.store.pipe(select(editinvestment_Form));
  lastModified$ = this.store.pipe(select(editinvestment_LastModified));
  tenantConfig$ = this.appstore.pipe(select(commomState_SystemConfig_Config));
  featuretoggle$ = this.appstore.pipe(select(commomState_SystemConfig_FeatureToggle));
  currentStep$ = this.appstore.pipe(select(editinvestment_CurrentStep));
  selectedAccount$ = this.store.pipe(select(memberAccountDropdown_SelectedAccount));
  currentInfo$ = this.store.pipe(select(editinvestment_currentInfo));
  currentIsSum100Percent$ = this.store.pipe(select(editinvestment_CurrentIsSum100Percent));
  currentTotalPercent$ = this.store.pipe(select(editinvestment_CurrentTotalPercent));
  isLastStep$ = this.store.pipe(select(editinvestment_IsLastStep));
  futureIsSum100Percent$ = this.store.pipe(select(editinvestment_FutureIsSum100Percent));
  futureTotalPercent$ = this.store.pipe(select(editinvestment_FutureTotalPercent));
  accountId = 0;
  communicationPreference = null;
  submitText = '';
  helper = new Helper();
  steps: number[] = [];
  @ViewChild('myTemplate') customTemplate: TemplateRef<any>;

  constructor(public store: Store<MemberPortalSharedState>,
    public appstore: Store<AppState>,
    private bottomSheet: MatBottomSheet,
    private router: Router) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();
    this.dispatch(this.store, RequestAction());

    this.sub = this.lastModified$.subscribe(async x => {
      if (x) {
        if (this.steps.length == 2) {
          var cs = await this.helper.getValue(this.currentStep$);
          if (cs == 1) {
            this.submitText = `<p>We have received your request to update your Current Investment Balance.</p>
            <p>This will be processed when the unit price for today is advised (Approx 2 business days).</p>
            <p>You will be notified when the Investment Switch has been processed.</p>`;
          }
          if (cs == 2) {
            this.submitText = `<p>We have received your request to update your Future contribution and Rollover investments.</p>
            <p>This will be actioned immediately.</p>`;
          }
        }
        if (this.steps.length == 3) {
          this.submitText = `<p>We have received your request to update your Current Investment Balance and your Future Contributions.</p>
          <p>The change to the Future Contributions will be effective immediately. </p>
          <p>The change to your investment balance (Switch) will be processed when the unit price for today is advised (Approx. 2 business days).</p>
          <p>You will be notified when the Investment Switch has been processed.</p>`;
        }
        this.bottomSheet.open(this.customTemplate, {
          disableClose: true
        });
      }
    });

    this.sub = this.selectedAccount$.subscribe(x => {
      if (x) {
        this.accountId = x.accountId;
        this.dispatch(this.store, RequestCurrentDataAction({ account: x.accountId }));
        this.dispatch(this.store, RequestOptionsDataAction({ account: x.accountId }));
      }
    });
  }

  onGoToDashboardClick() {
    this.bottomSheet.dismiss();
    this.router.navigate(['/']);
  }

  getCurrentPercentage(option: string, current: InvestmentItemModel[]) {
    var s = current.filter(x => x.option == option);
    if (s.length > 0)
      return s[0].percentage;
    return 0;

  }
  getCurrentAmount(option: string, current: InvestmentItemModel[]) {
    var s = current.filter(x => x.option == option);
    if (s.length > 0)
      return s[0].amount;
    return 0;

  }
  trackByFn(index, item) {
    return index;
  }

  ngOnDestroy() {
    this.store.dispatch(ResetAction());
    super.ngOnDestroyBase();
  }


  onSubmitClick(data: CurrentInvestmentModel) {
    this.dispatch(this.store, SubmitRequestAction({ accountId: this.accountId, obj: data }));
  }

  onEditItemClick(step: number, steps: number[], isDisabled: boolean) {
    if (isDisabled)
      return;
    this.steps = steps;
    this.dispatch(this.store, GoToStepAction({ step }));
    if (steps.length > 0)
      this.dispatch(this.store, SetStepsAction({ steps }));
  }
}
