<div id="dashboard-container">
  <div class="page-header">
    <app-toolbar-options icon="local_atm">
      <div class="d-flex flex-column" slot="start">
        <div class="page-title">Personal Contributions</div>

      </div>
    </app-toolbar-options>
  </div>
  <div class="page-content" appanimatecss>

    <app-component-wrapper fragmentid="insurance_questionnaire" icon="airplay" title="Personal Contribution Form">
      <div class="d-flex flex-column" slot="start">
        <app-personal-contribution-edit></app-personal-contribution-edit>
      </div>
    </app-component-wrapper>


  </div>
</div>
