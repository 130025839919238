import { Helper } from '@ifaa-components/ui-components';
import { distinctUntilChanged } from 'rxjs/operators';
import { AccountModel } from './../../../../model/account.model';
import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { ComponentBase } from '../../../../views/component-base';
import { MemberPortalSharedState } from '../../store/shared.states';
import { memberAccountDropdown_Accounts, memberAccountDropdown_SelectedAccount } from './selectors';
import { RequestAction, SelectAccountAction } from './actions';
import { commomState_LoggedinInfo } from 'src/app/store/common/common.selectors';
import { AppState } from 'src/app/store/app.states';

@Component({
  selector: 'app-member-account-dropdown',
  templateUrl: './member-account-dropdown.component.html',
  styleUrls: ['./member-account-dropdown.component.scss']
})
export class MemberAccountDropdownComponent extends ComponentBase implements OnInit, OnDestroy {

  list$ = this.store.pipe(select(memberAccountDropdown_Accounts));
  selected$ = this.store.pipe(select(memberAccountDropdown_SelectedAccount));
  loggedIn$ = this.appstore.pipe(select(commomState_LoggedinInfo));
  helper: Helper = new Helper();
  constructor(public store: Store<MemberPortalSharedState>,
    public appstore: Store<AppState>) {
    super();
  }

  async ngOnInit() {
    super.ngOnInitBase();

    if (! await this.hasDataLoaded()) {
      this.sub = this.loggedIn$
        .subscribe(x => {
          if (x)
            this.dispatch(this.store, RequestAction());
        })
    }
    this.screenWidthChange(this.appstore, (x) => { });
  }

  async hasDataLoaded() {
    var loggedIn = await this.helper.getValue(this.loggedIn$);
    return loggedIn.memberNumber;
  }
  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

  onSelecteAccountClick(item: AccountModel) {
    this.dispatch(this.store, SelectAccountAction({ payload: item }))
  }
}

