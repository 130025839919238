import { AppState } from 'src/app/store/app.states';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IMemberViewState } from './state';
import { MemberPortalSharedState } from '../../store/shared.states';
import { featureKey } from '../../store/shared.reducers';

export const memberPortalState = createFeatureSelector<MemberPortalSharedState>(featureKey);

export const getMemberView = createSelector(memberPortalState, (state) => state.memberViewState);

export const getMemberView_Model = createSelector(
  getMemberView,
  (state: IMemberViewState) => state.model
);
