<div id="personal-details-container">
  <div class="page-header">
    <app-toolbar-options icon="person">
      <div class="d-flex flex-column" slot="start">
        <div class="page-title">Update your details</div>

      </div>
    </app-toolbar-options>
  </div>
  <div class="page-content" appanimatecss>
    <div class="d-flex">
      <div class="w-50 pr-3">
        <app-component-wrapper icon="warning" title="Attention" headerclass="danger">
          <div class="description" slot="start">
            <div [innerHTML]="'profileView_Attention'| message | async"></div>
          </div>
        </app-component-wrapper>
        <app-component-wrapper icon="insert_drive_file" title="Name Change & Date of Birth">
          <div class="description" slot="start">
            <div [innerHTML]="'profileView_NameChangeAndDob'| message | async"></div>
          </div>
        </app-component-wrapper>
        <app-component-wrapper icon="groups" title="Beneficiaries" featuretoggle="displayBeneficiaryComponent">
          <div class="description" slot="start">
            <div [innerHTML]="'profileView_Beneficiaries'| message | async"></div>
          </div>
        </app-component-wrapper>
      </div>
      <div class="w-50">
        <app-component-wrapper icon="assignment_ind" title="Member Details">
          <div class="d-flex flex-column" slot="start">
            <app-member-edit></app-member-edit>
          </div>
          <!-- <div class="d-flex flex-row page-content-actions" slot="actions">
            <app-button class="w-100  mr-1 ml-1" title="Save" icon="save">
            </app-button>
          </div> -->
        </app-component-wrapper>
      </div>
    </div>

  </div>
</div>
