import { createReducer, on, Action } from '@ngrx/store';
import {
  createFormGroupState, onNgrxForms, updateGroup, validate, wrapReducerWithFormStateUpdate,
  onNgrxFormsAction, SetValueAction, setValue
} from 'ngrx-forms';
import { required, email, requiredTrue } from 'ngrx-forms/validation';
import { CurrentInvestmentModel, IInvestmentEditState, InvestmentModel } from './state';
import { RequestAction, ResetAction, ResponseAction, SubmitResponseAction, GoToStepAction, ResponseCurrentDataAction, ResponseOptionsDataAction, SetStepsAction } from './actions';
import { MemberModel } from 'src/app/model/member.model';
import { AddressModel, Helper } from '@ifaa-components/ui-components';

export const formName = 'investmentEditForm';
var helper = new Helper();

export const state: IInvestmentEditState = {
  form: createFormGroupState(formName, new CurrentInvestmentModel()),
  lastModifiedTime: null,
  currentStep: 0,
  steps: [],
  currentInfo: null,
  options: null
};

export const validateEditMemberForm = updateGroup<CurrentInvestmentModel>({
  //  firstName: validate(required),

});

const reducer = createReducer(state,
  onNgrxForms(),
  onNgrxFormsAction(SetValueAction, (state, action) => {
    return state;
  }),
  on(ResetAction, (state) => {
    return {
      ...state,
      lastModifiedTime: null,
      currentStep: 0
    };
  }),
  on(SetStepsAction, (state, { steps }) => {

    var form = state.form;
    if (JSON.stringify(state.steps) != JSON.stringify(steps)) {
      form = setValue(Object.assign(new CurrentInvestmentModel(), state.options))(state.form);
    }
    return {
      ...state,
      form: form,
      steps: steps
    };
  }),
  on(GoToStepAction, (state, { step }) => {
    return {
      ...state,
      currentStep: step
    };
  }),
  on(ResponseCurrentDataAction, (state, { payload }) => {
    return {
      ...state,
      currentInfo: payload
    };
  }),
  on(ResponseOptionsDataAction, (state, { payload }) => {
    return {
      ...state,
      form: setValue(Object.assign(new CurrentInvestmentModel(), payload))(state.form),
      options: payload
    };
  }),
  on(SubmitResponseAction, (state, { payload }) => {
    return {
      ...state,
      lastModifiedTime: new Date()
    };
  }),
);

const editInvestmentReducerFormState = wrapReducerWithFormStateUpdate(
  reducer,
  s => s.form,
  validateEditMemberForm,
);

export function investmentEditReducer(state: any | undefined, action: Action) {
  return editInvestmentReducerFormState(state, action);
}
