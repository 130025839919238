import { InjectionToken } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';
import { currentEmployerViewReducer } from '../components/current-employer/current-employer/reducer';
import { employerBalancesReducer } from '../components/employer-balances/reducer';

import { mainLayoutReducer } from '../layout/main/reducer'
import { employerHistoryViewReducer } from '../views/employment-history/reducer';
import { lodgeClaimReducer } from '../views/lodge-claim/reducer';
import { SMRTState } from './smrt.states';

export const featureKey = 'smrt';

export const reducers = new InjectionToken<ActionReducerMap<SMRTState>>(featureKey, {
  factory: () => ({
    mainLayout: mainLayoutReducer,
    currentEmployer: currentEmployerViewReducer,
    employerHistoryViewState: employerHistoryViewReducer,
    employerBalancesState: employerBalancesReducer,
    lodgeClaim: lodgeClaimReducer
  })
});
