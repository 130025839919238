import { createAction } from '@ngrx/store';
import { props } from '@ngrx/store';
import { LoggedInModel } from 'src/app/model/logged-in.model';
import { MenuModel } from 'src/app/model/menu.model';
import { SystemConfigModel } from 'src/app/model/system-config.model';
// import { ErrorModel } from '../../model/error.model';
// import { LoggedInModel } from 'src/app/model/loggedIn.model';

// export const ShowErrorAction = createAction('[Show error] error',
//   props<{ payload: ErrorModel }>());

export const ChangeScreenWidthAction = createAction('[Screen Width] set',
  props<{ payload: number}>());

  export const IsLoadingAction = createAction('[Is app loading] loading',
  props<{ payload: boolean }>());

export const SystemConfigurationRequestAction = createAction('[System Config] get data');

export const SystemConfigurationResponseAction = createAction('[System Config] set data',
  props<{ payload: SystemConfigModel }>());

export const IsLoggedInRequestAction = createAction('[User check] is logged in request');

export const IsLoggedInResponseAction = createAction('[User check] is logged in response',
  props<{ data: LoggedInModel }>());


export const LogoutRequestAction = createAction('[Logout request]');


export const SetMenuAction = createAction('[App] set menu',   props<{ data: MenuModel[] }>());
