import { getBPAYDetails_Item } from './selectors';
import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { RequestAction } from './actions';
import { ComponentBase } from '../../../../views/component-base';
import { MemberPortalSharedState } from '../../store/shared.states';
import { Router } from '@angular/router';
import { memberAccountDropdown_SelectedAccount } from '../member-account-dropdown/selectors';

@Component({
  selector: 'app-bpay-details',
  templateUrl: './bpay-details.component.html',
  styleUrls: ['./bpay-details.component.scss']
})
export class BPAYDetailsComponent extends ComponentBase implements OnInit, OnDestroy {

  selectedAccount$ = this.store.pipe(select(memberAccountDropdown_SelectedAccount));
  bpayDetail$ = this.store.pipe(select(getBPAYDetails_Item));
 
  constructor(public store: Store<MemberPortalSharedState>,
    private router: Router) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();
    this.sub = this.selectedAccount$.subscribe(x => {
      if (x) {
        this.dispatch(this.store, RequestAction({ accountId: x.accountId }));
      }
    })
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }


}
