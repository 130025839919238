import { OkModel } from './../../../../model/ok.model';
import { createAction } from '@ngrx/store';
import { props } from '@ngrx/store';
import { CurrentInvestmentModel, InvestmentModel } from './state';

export const RequestAction = createAction('[Edit investment View] request data by Id');

export const ResponseAction = createAction('[Edit investment View] response data by Id',
  props<{ payload: InvestmentModel }>());

export const SubmitRequestAction = createAction('[Edit investment View] save investment drtails',
  props<{ accountId: number, obj: CurrentInvestmentModel }>());

export const SubmitResponseAction = createAction('[Edit investment View]  investment update completed',
  props<{ payload: OkModel<string> }>());


export const ResetAction = createAction('[Edit investment View]  reset');


export const GoToStepAction = createAction('[Edit investment View] go to step',
  props<{ step: number }>());


export const RequestCurrentDataAction = createAction('[Edit investment View] request current data by Id', props<{ account: number }>());

export const ResponseCurrentDataAction = createAction('[Edit investment View] response current data by Id',
  props<{ payload: CurrentInvestmentModel }>());

  export const RequestOptionsDataAction = createAction('[Edit investment View] request options data ', props<{ account: number }>());

export const ResponseOptionsDataAction = createAction('[Edit investment View] response options data',
  props<{ payload: CurrentInvestmentModel }>());

  export const SetStepsAction = createAction('[Edit investment View] set steps',
  props<{ steps: number[] }>());
