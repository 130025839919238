<div id="transaction-listing-container">
  <div class="page-header">
    <app-toolbar-options icon="receipt_long">
      <div class="d-flex flex-column" slot="start">
        <div class="page-title">Transaction Listing</div>

      </div>
    </app-toolbar-options>
  </div>
  <div class="page-content" appanimatecss>

    <app-component-wrapper icon="supervisor_account" title="Account Transactions">
      <div class="d-flex flex-column" slot="start">
        <app-cash-transactions ></app-cash-transactions>
      </div>

    </app-component-wrapper>
  </div>

</div>
