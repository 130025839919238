import { ForgotPasswordModel } from './../../model/forgot-password.model';
import { SubmitRequestAction } from './actions';
import { getForgotPassword_Form } from './selectors';
import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../store/app.states';
import { ComponentBase } from '../../views/component-base';
import { OnDestroy } from '@angular/core';


@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent extends ComponentBase implements OnInit, OnDestroy {

  form$ = this.store.pipe(select(getForgotPassword_Form));

  constructor(public store: Store<AppState>) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

  submit(model: ForgotPasswordModel) {
     this.dispatch(this.store, SubmitRequestAction({ payload: model }));
  }
}
