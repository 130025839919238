export const environment = {
  production: false,
  apiUrl: 'https://localhost:8001/api',
  code: '',
  dashboard: '/dashboard',
  bugsnagApiKey: '691cd6149de59c8693b21e6e9826e173',
  bugsnagEnv: 'Development',
  recaptchaApi: '6LfeW9saAAAAAPWtOiD0ly77pxy3mxibLWJd2-yH',
  ga: '',
  greenIdEnv:'test',
  greenIdCode:'9nU-Se3-XMt-fbU'
};
