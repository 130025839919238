import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../store/app.states';
import { ComponentBase } from '../../views/component-base';
import { OnDestroy } from '@angular/core';


@Component({
  selector: 'app-elevated-access',
  templateUrl: './elevated-access.component.html',
  styleUrls: ['./elevated-access.component.scss']
})
export class ElevatedAccessComponent extends ComponentBase implements OnInit, OnDestroy {

  constructor(public store: Store<AppState>) {
    super();
    
    // check if we are in iframe - if we aren't, redirect to dashboard
    if (window.self === window.top)
      window.location.href = '/dashboard'
      
    window.addEventListener("message", (event) => {
      if (event.data && event.data.action == 'command') {
        localStorage.setItem('token_name', event.data.token);
        //  location.reload();
        window.location.href = '/dashboard'; //one level up
      }
    }, false);
  }

  ngOnInit() {
    super.ngOnInitBase();
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }


}
