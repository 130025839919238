import { Injectable } from '@angular/core';
import { Actions, createEffect, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { RollInService } from 'src/app/services/rollin.service';
import { RequestAction, ResponseAction } from './actions';
import { map, switchMap } from 'rxjs/operators';

@Injectable()
export class SuperannuationProviderSearchEffects {
  constructor(private store: Store<any>,
    private actions$: Actions,
    public toastr: ToastrService,
    private router: Router,
    private service: RollInService) {
  }

  providerSearch$ = createEffect(() => this.actions$.pipe(
    ofType(RequestAction),
    switchMap((action: any) => this.service.providerSearch(action.q)),
    map((data: any) => {
      return ResponseAction({ payload: data.data });
    })
  ));

  // submit$ = createEffect(() => this.actions$.pipe(
  //   ofType(ActionsList.SubmitRequestAction),
  //   switchMap((action: any) => this.service.updateMemberDetails(action.payload)),
  //   map((data: any) => {
  //     this.toastr.success(data.message);
  //     return ActionsList.SubmitResponseAction({ payload: data });
  //   })
  // ));
}
