
import { createReducer, on, Action } from '@ngrx/store';
import {
  createFormGroupState,
  onNgrxForms, onNgrxFormsAction, SetValueAction
} from 'ngrx-forms';
import { ResponseAction } from './actions';
import { AccessYourSuperFilterModel, IAccessYourSuperStartComponentState } from './state';

export const accessYourSuperStartFilter = 'accessYourSuperStartFilter';
export const state: IAccessYourSuperStartComponentState = {
  model: null,
  filter: createFormGroupState(accessYourSuperStartFilter, new AccessYourSuperFilterModel()),
};

const reducer = createReducer(state,
  onNgrxForms(),
  onNgrxFormsAction(SetValueAction, (state, action) => {
    return state;
  }),
  on(ResponseAction, (state, { payload }) => {
    return {
      ...state,
      model: payload
    };
  }),
);

export function accessYourSuperStartComponentReducer(state: any | undefined, action: Action) {
  return reducer(state, action);
}
