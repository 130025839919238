
import { IForgotPasswordState } from './state';
import { createReducer, on, State, Action } from '@ngrx/store';
import {
  createFormGroupState, onNgrxForms, updateGroup, validate, wrapReducerWithFormStateUpdate,
  onNgrxFormsAction, SetValueAction
} from 'ngrx-forms';
import { required, email } from 'ngrx-forms/validation';
import { ForgotPasswordModel } from '../../model/forgot-password.model';

export const formName = 'forgotPasswordForm';

export const state: IForgotPasswordState = {
  form: createFormGroupState(formName, new ForgotPasswordModel()),
};

export const validateLoginForm = updateGroup<ForgotPasswordModel>({
  username: validate(required),
});

const reducer = createReducer(state,
  onNgrxForms(),
  onNgrxFormsAction(SetValueAction, (state, action) => {
    return state;
  }),
  // on(LoginAction, (state) => {
  //   return {
  //     ...state,
  //     isLoading: true
  //   };
  // }),
  // on(LoginCompletedAction, (state) => {
  //   return {
  //     ...state,
  //     isLoading: false
  //   };
  // }),
);

const reducerFormState = wrapReducerWithFormStateUpdate(
  reducer,
  s => s.form,
  validateLoginForm,
);

export function forgotPasswordReducer(state: any | undefined, action: Action) {
  return reducerFormState(state, action);
}
