<div id="access-your-super-start" *ngIf="filter$|async; let filter">
  <div class="d-flex justify-content-end">  
    <app-button-toggle [control]="filter.controls.activeOptionButton" list="accessYourSuperApplicationStatuses"></app-button-toggle>
  </div>
  <div class="table-wrapper mt-3">
      <table class="table table-striped">
        <thead>
          <tr>
            <th width="33%" scope="col">Application Type</th>
            <th width="33%" scope="col">Started Date</th>
            <th *ngIf="filter.value.activeOptionButton === 'Submitted'" width="33%" scope="col">Completed Date</th>
            <th *ngIf="filter.value.activeOptionButton === 'Draft' && (screenWidth > 768)"  width="33%" scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
        <ng-container *ngFor="let item of currentList">
            <tr>
              <td>{{item.applicationType}}</td>
              <td>{{item.startedDate | date:'dd MMM yyyy'}}</td>
              <td *ngIf="filter.value.activeOptionButton === 'Submitted'" >{{item.lastModified | date:'dd MMM yyyy'}}</td>
              <td *ngIf="filter.value.activeOptionButton === 'Draft' && (screenWidth > 768)" >
                <div class="d-flex flex-row">
                  <app-button class="continue-btn" title="Continue" icon="{{'BTN.access-your-super.continue.icon' | translate}}" color="{{'BTN.access-your-super.continue.color' | translate}}" (onClick)="continueApplication(item.applicationId)"></app-button>
                  <app-button class="ml-2 cancel-btn" title="Cancel" icon="{{'BTN.access-your-super.cancel.icon' | translate}}" color="{{'BTN.access-your-super.cancel.color' | translate}}" (onClick)="cancelApplication(item.applicationId)"></app-button>
                </div>
              </td>
            </tr>
            <tr *ngIf="(screenWidth < 769)">
              <td colspan="2">
                <div class="d-flex flex-row flex-fill">
                  <app-button class="mr-1 flex-fill continue-btn" title="Continue" icon="{{'BTN.access-your-super.continue.icon' | translate}}" color="{{'BTN.access-your-super.continue.color' | translate}}" (onClick)="continueApplication(item.applicationId)"></app-button>
                  <app-button class="ml-1 flex-fill cancel-btn" title="Cancel" icon="{{'BTN.access-your-super.cancel.icon' | translate}}" color="{{'BTN.access-your-super.cancel.color' | translate}}" (onClick)="cancelApplication(item.applicationId)"></app-button>  
                </div>
              </td>
            </tr>

      </ng-container>
    </tbody>
      </table>
    </div>
  <div class="d-flex justify-content-end">
      <app-button class="mt-3 ml-1" title="Start new application" icon="{{'BTN.access-your-super.start.icon' | translate}}" color="{{'BTN.access-your-super.start.color' | translate}}" (onClick)="createNewApplication()">
  </app-button>
  </div>
</div>