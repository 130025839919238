import { OkModel } from './../../../../model/ok.model';
import { BeneficiaryModel } from './../../../../model/beneficiary.model';
import { createAction } from '@ngrx/store';
import { props } from '@ngrx/store';
import { InsuranceQuestionnaireModel, InsuranceQuestionnaireResultModel } from 'src/app/model/insurance-questionnaire.model';

export const ResetFormAction = createAction('[Insurance questionnaire start] reset');
export const ValidateFormAction = createAction('[Insurance questionnaire start] validate form');
export const NextStepAction = createAction('[Insurance questionnaire start] next');
export const PreviousStepAction = createAction('[Insurance questionnaire start] previous');

export const SubmitRequestAction = createAction('[Insurance questionnaire start] submit', props<{ accountId: number, data: InsuranceQuestionnaireModel }>());
export const SubmitResponseAction = createAction('[Insurance questionnaire start] submit response', props<{ data: InsuranceQuestionnaireResultModel }>());

export const ConfirmRequestAction = createAction('[Insurance questionnaire start] confirm', props<{ accountId: number, data: InsuranceQuestionnaireResultModel }>());
export const ConfirmResponseAction = createAction('[Insurance questionnaire start] confirm response', props<{ data: string }>());
export const ConfirmReadInsuranceRequestInsuranceGuideAction = createAction('[Insurance questionnaire start] ConfirmReadInsuranceRequestInsuranceGuideAction',  props<{ confirmReadInsuranceRequestInsuranceGuide: boolean }>());






