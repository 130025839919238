<div id="insurance-details-container" *ngIf="(model$ | async); let data">
  <div *ngIf="data.hasInsurance">
    <!-- <div class="alert alert-info" [innerHTML]="'yourInsuranceCover_Description'|message|async">

    </div> -->
    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">Cover Type</th>
          <th scope="col">Monthly Premium Amount</th>
          <th scope="col">Cover Amount</th>
          <th scope="col">Waiting Period</th>
          <th scope="col">Lapsed</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of data.insurances">
          <th>{{item.insuranceCoverTypeName}}</th>
          <th>{{item.premium | currency | dash}}</th>
          <th>{{item.amount | currency | dash}}</th>
          <th>{{item.waitingPeriod}}</th>
          <th>{{item.lapseDate | date:'dd/MM/yyyy'}}</th>
        </tr>
      </tbody>
    </table>

  </div>
  <div *ngIf="!data.hasInsurance">

    <p class="alert alert-danger">You currently do not hold insurance with {{(tenantConfig$ | async)?.name}}</p>
    <p class="" [innerHTML]="'yourInsuranceCover_Description'|message|async">
    </p>
    <p class="d-flex flex-column align-items-start">
      <a class="btn no-decoration w-100 d-flex align-items-center click no-text-decoration" target="_blank" href="{{item.url}}"
        *ngFor="let item of data.fundDocuments">
        <mat-icon *ngIf="item.icon" class="mr-2 ml-2">{{item.icon}}</mat-icon>
        <label>{{item.title}}</label>
      </a>
    </p>

    <p [innerHTML]="('yourInsuranceCover_YourInsuranceCover' | message | async)">
    </p>

  </div>

</div>
